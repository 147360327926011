<template>
  <div v-frag>
    <v-toolbar fixed flat color="white">
      <div>
        <v-img
          :src="require(`../../assets/logo/TMX_logo.png`)"
          height="48"
          width="101"
          @click="onLogoClick"
          style="cursor: pointer;"
        />
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <template v-for="(item, index) in $store.state.mainMenu.mainMenu">
          <template v-if="!item.children || item.children.length === 0">
            <v-btn text :key="index" @click="item.click()" color="primary" v-if="item.showOnDesktop && item.safetyMode === 0">
              {{ $t(`${item.text}`) }}
            </v-btn>
          </template>
          <template v-if="item.children && item.children.length > 0">
            <v-menu offset-y :key="index" transition="scroll-y-transition" open-on-hover v-if="item.safetyMode === 0">
              <template v-slot:activator="{ on }">
                <v-btn text :key="index" color="primary" v-on="on">
                  {{ $t(`${item.text}`) }}
                </v-btn>
              </template>
              <v-list>
                <template v-for="(child, index) in item.children">
                  <v-list-item
                    v-if="child.safetyMode === 0"
                    v-show="!(item.text === 'mainNavBar.jobOffers' && $i18n.locale !== 'hu-HU')"
                    :key="index"
                    @click="child.click()"
                  >
                    <v-list-item-title>{{ $t(`${child.text}`) }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </template>
        </template>
        <!-- USERNAME START -->
        <div class="body-2 primary--text text--darken-2 ml-4 mr-2"
             style="display: flex; align-items: center;"
             v-if="$store.state.authModule.isAuthenticated && $store.state.authModule.user">
          <div v-if="$store.state.authModule.user.profile.customerType === 'B2C' || $store.state.authModule.user.profile.customerType === 'EMPLOYEE'
              || $store.state.authModule.user.profile.customerType === 'MOST-B2C'">
            {{ $t('greetings') }} {{ $store.state.authModule.user.profile.lastName }} {{ $store.state.authModule.user.profile.firstName }}!
          </div>
          <div v-else>
            {{ $t('greetings') }} {{ $store.state.authModule.user.profile.companyName }} {{ $store.state.authModule.user.profile.businessForm.name }}!
          </div>
        </div>
        <!-- USERNAME END -->
        <template v-if="$store.state.authModule.isAuthenticated && $store.state.authModule.user">
          <v-menu offset-y transition="scroll-y-transition" left open-on-hover>
            <template v-slot:activator="{ on }">
              <v-icon v-if="$store.state.authModule.user.profile.customerType === 'B2C' || $store.state.authModule.user.profile.customerType === 'EMPLOYEE'
                      || $store.state.authModule.user.profile.customerType === 'MOST-B2C'"
                      v-on="on"
                      color="light-green darken-1"
                      :class="$vuetify.breakpoint.smAndDown ? 'ml-4': ''">
                mdi-account-circle
              </v-icon>
              <v-icon v-else v-on="on" color="orange darken-1" :class="$vuetify.breakpoint.smAndDown ? 'ml-4': ''">
                mdi-account-supervisor-circle
              </v-icon>
            </template>
            <v-list>
              <v-list-item @click="goToPersonalSite" v-if="$store.state.authModule.isAuthenticated && loginMethod === 'jz:00=p+'">
                <v-list-item-title>{{ $t('personalSite') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="onLogoutClick" v-if="$store.state.authModule.isAuthenticated">
                <v-list-item-title>{{ $t('mainNavBar.logout')}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-else>
          <v-menu offset-y transition="scroll-y-transition" left open-on-hover>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="primary darken-1">mdi-account-circle</v-icon>
            </template>
            <v-list>
              <v-list-item @click="$router.push({name: 'login'})" v-show="!$store.state.authModule.isAuthenticated">
                <v-list-item-title>{{ $t('login') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$router.push({name: 'login'})" v-show="!$store.state.authModule.isAuthenticated">
                <v-list-item-title>{{ $t('forgotPassword')}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <Notifications />
        <!-- ide jön a nyelvválasztó -->
        <template v-if="$showLanguageDropdown">
          <LanguageChangeDropdown></LanguageChangeDropdown>
        </template>
      </v-toolbar-items>
    </v-toolbar>
  </div>
</template>

<script>
import { TMX_URL } from 'src/utils/config';
import { getLoginMethod } from 'src/utils/jwtHelper.js';
import LanguageChangeDropdown from '../language/LanguageChangeDropdown.vue';
import Notifications from '@/components/shared/Notifications.vue';

export default {
  name: 'MainNavBar',
  components: {
    LanguageChangeDropdown, Notifications,
  },
  beforeMount() {
    this.lang = this.$i18n.locale;
  },
  mounted() {
    this.loginMethod = getLoginMethod();
  },
  data() {
    return {
      lang: '',
      dialogIsVisible: false,
      loginCardIsVisible: false,
      forgotPassCardIsVisible: false,
      loginMethod: '',
    };
  },
  methods: {
    changeDialogContent() {
      this.loginCardIsVisible = !this.loginCardIsVisible;
      this.forgotPassCardIsVisible = !this.forgotPassCardIsVisible;
    },
    showLoginDialog() {
      this.loginCardIsVisible = true;
      this.forgotPassCardIsVisible = false;
      this.dialogIsVisible = true;
    },
    showForgotPassDialog() {
      this.loginCardIsVisible = false;
      this.forgotPassCardIsVisible = true;
      this.dialogIsVisible = true;
    },
    onLogoClick() {
      window.open(`${TMX_URL}`, '_self');
    },
    goToPersonalSite() {
      this.$router.push({ name: 'iranyitopult' });
    },
    onLogoutClick() {
      this.$store.dispatch('Logout');
    },
  },
  computed: {
    //
  },
};
</script>

<style scoped>
</style>
