import Vue from 'vue';
import { APIGET } from 'src/utils/apiHelper';
import { version } from '../../package.json';

Vue.prototype.$VERSION_NO = version;

Vue.prototype.$CODE = 'MOBILFUTAR';

Vue.prototype.$HTTP_OK = 200;
Vue.prototype.$HTTP_CREATED = 201;
Vue.prototype.$HTTP_NOT_FOUND = 404;
Vue.prototype.$HTTP_UNPROC = 422;

export const SYSTEMPARAMS = [
  {
    customerType: 'B2C',
    registrationIsEnabled: true,
    excelUploadIsEnabled: false,
    gsmPreAlertIsEnabled: false,
  },
  {
    customerType: 'EMPLOYEE',
    registrationIsEnabled: false,
    excelUploadIsEnabled: false,
    gsmPreAlertIsEnabled: false,
  },
  {
    customerType: 'FLEET',
    registrationIsEnabled: false,
    excelUploadIsEnabled: true,
    gsmPreAlertIsEnabled: false,
  },
  {
    customerType: 'DISTRIBUTOR',
    registrationIsEnabled: false,
    excelUploadIsEnabled: true,
    gsmPreAlertIsEnabled: false,
  },
  {
    customerType: 'STORE_01',
    registrationIsEnabled: false,
    excelUploadIsEnabled: true,
    gsmPreAlertIsEnabled: false,
  },
  {
    customerType: 'RETAILER',
    registrationIsEnabled: false,
    excelUploadIsEnabled: true,
    gsmPreAlertIsEnabled: false,
  },
  {
    customerType: 'OTHER_01',
    registrationIsEnabled: false,
    excelUploadIsEnabled: true,
    gsmPreAlertIsEnabled: false,
  },
  {
    customerType: 'PREALERT',
    registrationIsEnabled: false,
    excelUploadIsEnabled: false,
    gsmPreAlertIsEnabled: true,
  },
  {
    customerType: 'MOST-B2C',
    registrationIsEnabled: false,
    excelUploadIsEnabled: false,
    gsmPreAlertIsEnabled: false,
  },
  {
    customerType: 'MOST-B2B',
    registrationIsEnabled: true,
    excelUploadIsEnabled: false,
    gsmPreAlertIsEnabled: false,
  },
];

export const EXCEL_UPLOAD_ENABLED = false;
export const BUYBACK_CARD_ENABLED = false;
export const ENABLED_LANGUAGES = {
  hun: true,
  eng: true,
  deu: true,
  sl: true,
};
export const TC_REGEXP = /^TC/g;
export const TNC_REGEXP = /^TNC/g;
export const MC_REGEXP = /^MC/g;
export const AC_REGEXP = /^AC/g;
export const H_REGEXP = /^H/g;
// export const START_2DIG = /^[1-9][0-9]/;
export const START_2DIG = /^\d{2}/;
// Amennyiben a kárszám 6 karakter és 5-össel kezdődik, 97-es AVS-re kell küldeni.
export const O2_INSURANCE_REGEXP = /^(5?)[0-9]{5}$/g;
export const showB2CCardsOnHomePage = true;
export const showLoggedInServiceCardsOnHomePage = false;
export const ONLINE_PAYMENT_ENABLED = 0;
export const PP_ENABLED = 0;
export const CREDIT_CARD_ENABLED = 0;
export const LOCATION = window.location.origin;
export const PAYMENT_API = '';
export const SHOW_DOC_TMX_STATUS = 0;
export const SHOW_DOC_TMX_USER = 0;

// EZEKNÉL A BRANDEKNÉL NEM KELL ÖSSZEFŰZNI A TÍPUS NEVET
Vue.prototype.$NoDisplayNameBrands = [2];
Vue.prototype.$showLanguageDropdown = true;

export async function getSystemParam(systemParam) {
  const resp = await APIGET(`system-param/${systemParam}`, {}, false);
  return resp.data;
}

Vue.prototype.$APPLE_STORE_ADDRESS = {
  accountNumber: null,
  billingName: '',
  city: 'Budapest',
  country: {
    countryCode: 'HUN',
    label: 'Magyarország',
  },
  id: null,
  isCompany: 0,
  label: '',
  other: '',
  postalCode: '1134',
  publicPlaceName: 'Váci',
  publicPlaceType: 'út',
  streetNumber: '43',
  taxNumber: null,
};

Vue.prototype.$ADDRESS_TEMPLATE = {
  id: null,
  isCompany: 0,
  country: {
    countryCode: 'HUN',
    label: 'Magyarország',
  },
  city: '',
  postalCode: '',
  publicPlaceName: '',
  publicPlaceType: '',
  streetNumber: '',
  other: null,
  label: '',
  billingName: '',
  taxNumber: null,
  accountNumber: null,
};

// FormType
Vue.prototype.$FORM_TELEKOM = {
  id: 7,
  code: 'MobilProviderGaranciaFormTelekom',
};
Vue.prototype.$FORM_DOLGOZOI = {
  id: 6,
  code: 'DolgozoiForm',
};
Vue.prototype.$FORM_APPLEMUNKALAP = {
  id: 17,
  code: 'AppleMunkalapForm',
};
