import { TMX_URL } from 'src/utils/config';
import router from '../router';

const state = {
  mainMenu: [
    {
      text: 'mainNavBar.aboutUs',
      path: '',
      safetyMode: 0,
      children: [
        {
          text: 'mainNavBar.introduction',
          click: () => {
            window.open(`${TMX_URL}about-us`, '_self');
          },
          roles: ['B2C', 'B2B', 'FLOTTA'],
          safetyMode: 0,
        },
        {
          text: 'mainNavBar.competitions',
          click: () => {
            window.open(`${TMX_URL}palyazatok`, '_self');
          },
          roles: ['B2C', 'B2B', 'FLOTTA'],
          safetyMode: 0,
        },
        {
          text: 'mainNavBar.stores',
          click: () => {
            window.open(`${TMX_URL}tmx-stores`, '_self');
          },
          roles: ['B2C', 'B2B', 'FLOTTA'],
          safetyMode: 0,
        },
      ],
    },
    {
      text: 'mainNavBar.services',
      click: () => {
        window.open(`${TMX_URL}services`, '_self');
      },
      roles: ['B2C', 'B2B', 'FLOTTA'],
      showOnDesktop: true,
      safetyMode: 0,
    },
    {
      text: 'mainNavBar.mobilfutar',
      path: '',
      safetyMode: 0,
      children: [
        {
          text: 'mainNavBar.request',
          click: () => {
            router.push({ name: 'igenyles' });
          },
          roles: ['B2C', 'FLOTTA'],
          safetyMode: 0,
        },
        {
          text: 'mainNavBar.tracking',
          click: () => {
            router.push({ name: 'bejelentkezes' });
          },
          roles: ['B2C', 'B2B', 'FLOTTA'],
          safetyMode: 0,
        },
      ],
    },
    /* {
      text: 'mainNavBar.mobilfutar',
      click: () => {
        router.push({name: 'igenyles'});
      },
      roles: ['B2C', 'FLOTTA'],
      showOnDesktop: true,
    },
    {
      text: 'mainNavBar.tracking',
      click: () => {
        router.push({name: 'bejelentkezes'});
      },
      roles: ['B2C', 'B2B', 'FLOTTA'],
      showOnDesktop: true,
    }, */
    /* {
      text: 'mainNavBar.buyback',
      click: () => {
        window.open(`${BUYBACK_URL}`, '_self');
      },
      showOnDesktop: true,
      roles: ['B2C', 'B2B', 'FLOTTA'],
    }, */
    {
      text: 'mainNavBar.worksheets',
      href: null,
      click: () => {
        window.open('https://telemaxgsm.com/', '_blank');
      },
      showOnDesktop: true,
      safetyMode: 0,
      roles: ['B2C', 'B2B', 'FLOTTA'],
    },
    {
      text: 'mainNavBar.career',
      path: '',
      safetyMode: 0,
      children: [
        {
          text: 'mainNavBar.generalInformations',
          click: () => {
            window.open(`${TMX_URL}career`, '_self');
          },
          roles: ['B2C', 'B2B', 'FLOTTA'],
          safetyMode: 0,
        },
        {
          text: 'mainNavBar.jobOffers',
          click: () => {
            window.open(`${TMX_URL}career/job-offers`, '_self');
          },
          roles: ['B2C', 'B2B', 'FLOTTA'],
          safetyMode: 0,
        },
      ],
    },
    {
      text: 'mainNavBar.partners',
      click: () => {
        window.open(`${TMX_URL}partners`, '_self');
      },
      showOnDesktop: true,
      safetyMode: 0,
      roles: ['B2C', 'B2B', 'FLOTTA'],
    },
    {
      text: 'mainNavBar.contact',
      click: () => {
        window.open(`${TMX_URL}contact`, '_self');
      },
      showOnDesktop: true,
      safetyMode: 0,
      roles: ['B2C', 'B2B', 'FLOTTA'],
    },
    /* {
      text: 'mainNavBar.login',
      click: () => {
        router.push({name: 'login'});
      },
      showOnDesktop: false,
    }, */
    /* {
      text: 'mainNavBar.sales',
      path: '',
      children: [
        {
          text: 'mainNavBar.introduction',
          path: '/about-us',
        },
        {
          text: 'mainNavBar.stores',
          path: '/tmx-stores',
        },
      ],
    },
    {
      text: 'mainNavBar.services',
      click: () => {
        router.push('/services');
      },
    }, */
  ],
};

const getters = {
  //
};

const actions = {
  // foo() {
  //  console.log(this._vm.$SAFETY_MODE);
  // },
};
const mutations = {
  activeSafetyMode(s, credentials) {
    state.mainMenu[3].safetyMode = credentials;
  },
};
export default {
  state,
  actions,
  mutations,
  getters,
};
