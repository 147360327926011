export default {
  mainNavBar: {
    home: 'Home',
    aboutUs: 'Über uns',
    introduction: 'Vorstellung',
    competitions: 'Wettbewerbe',
    stores: 'Geschäfte',
    services: 'Dienstleistungen',
    career: 'Karriere',
    generalInformations: 'Allgemeine Informationen',
    jobOffers: 'Stellenangebote',
    worksheets: 'Arbeitsblätter',
    partners: 'Partner',
    contact: 'Kontakt',
    mobilfutar: 'Mobilfunkanbieter',
    buyback: 'Rückkauf',
    tracking: 'Nachverfolgung',
    request: 'Anfrage',
    login: 'Einloggen',
    logout: 'Ausloggen',
    registration: 'Anmeldung',
    forgotPassword: 'Passwort vergessen',
  },
  innerSideNav: {
    home: 'Home',
    userInformation: 'Benutzerinformationen',
    allOrders: 'Alle Bestellungen',
    allClaims: 'Alle Schäden',
    billingInformation: 'Rechnungsinformationen',
    shippingInformation: 'Versandinformationen',
    addressInformation: 'Adressinformationen ',
    massUpload: 'Massenupload',
    messages: 'Nachrichten',
    userManual: 'Manuell',
    lgWorksheet: 'LG Arbeitsblätter',
    appleWorksheet: 'Apple Arbeitsblätter',
    exit: 'Verlassen',
  },
  excel: {
    downloadSamples: 'Beispiele herunterladen',
    uploadFiles: 'Dateien hochladen',
    downloadSampleCSV: 'Beispiel herunterladen: CSV',
    downloadSampleEXCEL: 'Beispiel herunterladen: EXCEL',
    downloadExcelHelper: 'Excel-Hilfe herunterladen',
    acceptFiles: 'Dateien akzeptieren: .csv, .xls, .xlsx',
    errorMessages: 'Es befinden sich Fehler in der hochgeladenen Datei',
  },
  serviceCalculator: 'Dienstleistungsrechner',
  mostServiceCalculator: 'MOST Servicerechner',
  orderForm: 'Megrendelő űrlap',
  imprint: 'Impressum',
  login: 'Einloggen',
  loginConfirm: 'Anmeldungsbestätigung',
  twoStepLogin: 'Zweistufige Anmeldung',
  registration: 'Anmeldung',
  email: 'E-Mail',
  password: 'Passwort',
  passwordConfirm: 'Passwortbestätigung',
  oldPassword: 'Altes Passwort',
  newPassword: 'Neues Passwort',
  newPasswordConfirm: 'Neues Passtwort bestätigen',
  forgotPassword: 'Passwort vergessen',
  addNewPassword: 'Neues Passwort erstellen',
  checkPassword: 'Passwort überprüfen',
  accountInformation: 'Kontoinformation',
  accountLanguage: 'Sprache des Kontos',
  accountCurrency: 'Kontowährung',
  accountPayment: 'Kontozahlung',
  primaryContactInformation: 'Kontaktdaten',
  firstName: 'Vorname',
  lastName: 'Nachname',
  phoneNumber: 'Telefonnummer',
  actions: 'Aktionen',
  search: 'Suchen',
  damageNumber: 'Schadennummer',
  invoice: 'Rechnung',
  warrantyTicket: 'Garantieschein',
  evidence: 'Nachweise',
  brand: 'Marke',
  productType: 'Typ',
  deviceType: 'Produkttyp',
  deviceIdentifier: 'Gerätekennung (IMEI, SN)',
  imeiSn: 'IMEI/SN',
  dateOfPurchase: 'Kaufdatum',
  troubleDescription: 'Problembeschreibung',
  otherDescription: 'Weitere Beschreibung',
  receptionAddress: 'Empfangsadresse',
  newReceptionAddress: 'Abweichende Empfangsadresse',
  transmissionAddress: 'Übermittlung der Adresse',
  newTransmissionAddress: 'Neue Sendungsadresse',
  invoicingAddress: 'Rechnungsadresse',
  billingAddresses: 'Rechnungsadresse',
  newInvoicingAddress: 'Neue Rechnungsadresse',
  shippingAddress: 'Lieferadresse',
  deliveryAndReceiptAddress: 'Liefer- und Empfangsadresse',
  costumerAddress: 'Ügyfél cím',
  shippingAddresses: 'Versandadressen',
  newShippingAddress: 'Neue Lieferadresse',
  shippingAndInvoicingAddress: 'Versand- und Rechnungsadresse',
  invoicingName: 'Rechnungsbezeichnung',
  billingName: 'Rechnungsstellung',
  dateOfPickUp: 'Abholdatum',
  dateOfTransmission: 'Datum der Übermittlung',
  country: 'Land',
  city: 'Stadt',
  postalCode: 'Postleitzahl',
  publicPlaceName: 'Öffentlicher Ort Name',
  publicPlaceType: 'Öffentlicher Ort Typ',
  streetNumber: 'Hausnummer',
  status: 'Status',
  contactIsTheSame: 'Der Kontakt ist derselbe',
  transmissionDataIsSameAsReceptionData: 'Die Absendeadresse stimmt mit der Empfangsadresse überein.',
  invoicingDataIsSameAsReceptionData: 'Die Rechnungsanschrift stimmt mit der Empfangsanschrift überein.',
  invoicingDataIsSameAsTransmissionData: 'Die Rechnungsanschrift stimmt mit der Absenderadresse überein.',
  invoicingAddressIsEqualShippingAddress: 'Die Rechnungsadresse stimmt mit der Lieferadresse überein.',
  invoicingDataIsOther: 'Abweichende Rechnungsadresse.',
  loadingText: 'Daten laden...Bitte warten Sie!',
  pickUpAndReturn: 'Abholung und Rückgabe',
  welcomeText: 'Willkommen auf der Abholungs- und Rückgabe Website von TMX Pick up!',
  findOutMoreAboutOurServices: 'Erfahren Sie mehr über unsere Dienstleistungen heraus.',
  warrantyRepairForm: 'Formular für Garantiereparaturen',
  oufOfWarrantyRepairForm: 'Formular für Garantiereparaturen',
  repairForm: 'Reparaturformular',
  tracking: 'Nachverfolgung',
  documents: 'Dokumente',
  documentUpload: 'Dokumente hochladen',
  contactName: 'Dokumente hochladen',
  serviceRequestId: 'Serviceanfrage ID',
  addressDetails: 'Angaben zur Adresse',
  vatNumber: 'Steuernummer',
  quoteDone: 'Kostenvoranschlag erledigt',
  exportReportDone: 'Bericht exportiert ',
  mostmobil: 'Mostmobil.hu Servicegarantie',
  de3000: 'DE-3000 Geschäft',
  requestIdOrWorksheetNumber: 'ID oder Arbeitsblattnummer anfordern',
  accountRegistration: 'Konto-Registrierung',
  accountRegistrationQuestionOnForm: 'Möchten Sie ein Benutzerkonto registrieren? Mit einem Benutzerkonto können Sie Ihre bestellten Dienstleistungen einfacher verfolgen.',
  acceptCheckbox: {
    generalInformation: 'Ich habe die obigen Informationen gelesen und akzeptiere sie.',
  },
  hasActiveQuoteBox: {
    title: 'Akzeptieren Sie das Angebot?',
    desc: 'Unser Angebot ist 5 Arbeitstage lang gültig. Bitte antworten Sie innerhalb der Frist, ansonsten werden wir den Auftrag löschen.',
    dowloadSentenceText: 'Hier können Sie das Angebot einsehen',
    dowloadSentenceLinkText: 'Sie können es hier sehen',
  },
  hasActiveExpertReportBox: {
    title: 'Akzeptieren Sie das Gutachten?',
    desc: 'Unser Gutachten ist 5 Arbeitstage gültig. Bitte antworten Sie innerhalb der Frist, ansonsten werden wir den Auftrag löschen.',
    dowloadSentenceText: ' Hier können Sie das Gutachten einsehen ',
    dowloadSentenceLinkText: 'Sie können es hier sehen',
  },
  forgotPasswordReminderText: 'Bitte geben Sie Ihre registrierte E-Mail-Adresse in das obere Feld ein.',
  passwordRuleGeneral: 'Min. 8 Zeichen. Es muss sowohl Groß- als auch Kleinbuchstaben enthalten.',
  registrationInfo: 'Bitte geben Sie im unteren Feld Ihr Passwort ein. Mit diesem Passwort können Sie sich in Ihr Benutzerkonto einloggen. Ihre Login-E-Mail-Adresse entspricht der E-Mail-Adresse, die Sie in Ihrem Kundenprofil angegeben haben.',
  greetings: 'Herzliche Grüße',
  unreadMessages: 'Ungelesene Nachrichten',
  readMessages: 'Gelesene Nachrichten ',
  personalSite: 'Persönliche Seite',
  flottakezeles: 'Für Geschäftspartner',
  dolgozoknak: 'Für Mitarbeiter',
  priceCalculation: 'Preiskalkulation',
  buyBack: 'Rückkauf',
  program: 'Programm',
  estimatedServicePrice: 'Geschätzter Service- und Lieferpreis',
  askForQuote: 'Fragen Sie nach einem Kostenvoranschlag!',
  arriveToService: 'Ankunft zum Service',
  referenceNumber: 'Referenznummer',
  quotePrice: 'Preis des Angebots',
  button: {
    send: 'Senden ',
    newOrder: 'Neuer Auftrag',
    newAddress: 'Neue Adresse',
    changePassword: 'Passwort ändern',
    deleteProfile: 'Profil löschen',
    cancel: 'Abbrechen',
    view: 'Ansicht',
    select: 'Wählen',
    next: 'Weiter',
    back: 'Zurück',
    imeiValidate: 'IMEI-Bestätigung',
    save: 'Speichern',
    edit: 'Bearbeiten',
    download: 'Herunterladen',
    upload: 'Hochladen',
    validate: 'Bestätigen',
    calculate: 'Berechnen',
    orderTheService: 'Ich bestelle den Service',
    interestedInOffer: 'Ich interessiere mich für das Angebot',
    askForQuote: 'Ich bitte um einen Kostenvoranschlag',
    acceptQuote: 'Angebot akzeptieren',
    acceptQuotes: 'Akzeptieren Sie die Angebote',
    rejectQuote: 'Angebot ablehnen',
    rejectQuotes: 'Angebote ablehnen',
    quoteAcceptText: 'Ich nehme das Angebot an',
    quoteRejectText: 'Ich lehne das Angebot ab',
    /*expertReportAcceptText: 'Ich akzeptiere das Gutachten',
    expertReportRejectText: 'Ich lehne das Gutachten ab',*/
    expertReportAcceptText: 'Ich fordere ein Angebot an',
    expertReportRejectText: 'Ich fordere kein Angebot an',
    goToLogin: 'Zur Anmeldung',
    goToDashboard: 'Zurück zu Meine Seite',
    backToMyAddresses: 'Zurück zu den registrierten Adressen',
    continueWithoutReg: 'Weiter ohne Registrierung',
    continueWithReg: 'Weiter mit Registrierung',
    downloadInvoice: 'Rechnung herunterladen',
    onlinePayment: 'Online-Bezahlung',
    generate: 'Generieren',
    refresh: 'DE - Frissítés',
    print: 'DE- Nyomtat',
  },
  inputMessages: {
    requiredField: 'Erforderliches Fel',
    maxCharacter: 'Max 3000 characters',
    optionalField: 'Optionales Feld',
    passwordRule: 'Mind. 8 Zeichen. Es muss sowohl Groß- als auch Kleinbuchstaben enthalten',
    passwordRuleMin8: 'Es muss mindestens 8 Zeichen haben',
    passwordNotMatch: 'Passwort stimmt nicht überein',
    searchHint: 'Durch Tippen können Sie beliebige Daten aus der Tabelle durchsuchen.',
    acceptInformation: 'Um Ihre Anfrage zu vervollständigen, müssen Sie die Informationen akzeptieren und die ...',
    maxFileSizeRule: 'Die hochgeladene Dateigröße darf maximal 5 MB betragen!',
    maxFileSizeRuleOptional: 'Optional! Die maximale Dateigröße beim Hochladen beträgt 5 MB!',
    emailMatch: 'Bitte geben Sie eine gültige E-Mail Adresse ein!',
    wrongCharacter: 'Falsches Zeichen in der E-Mail-Adresse',
    onlyNumbers: 'Bitte nur Ziffern eingeben!',
    contactIsTheSameHelp: 'Wenn Sie die Kontaktperson für diese Adresse sind, kreuzen Sie dieses Kästchen bitte an.',
    requiredPrivacy: 'Um das Formular absenden zu können, müssen Sie die Informationen zum Datenschutz akzeptieren!',
    requiredTermsAndCond: 'Zum Absenden des Formulars müssen Sie die Allgemeinen Geschäftsbedingungen akzeptieren!',
    toFewCharacters: 'Sie haben zu wenig Zeichen eingegeben!',
    damageNumberNotMatch: 'Auftragsnummern beginnen mit den Zeichen AS oder VF und enthalten 7 weitere Zeichen.',
    startTypingPostalCode: 'Durch Tippen können Sie nach Postleitzahlen suchen.',
    startTypingCity: 'Durch Tippen können Sie nach Städten suchen.',
    startTypingStreet: 'Durch Tippen können Sie nach Straßen suchen.',
    noDataText: 'Es wurde kein Ergebnis gefunden!',
    snRules: 'Englische Klein-/Großschreibung, Zahlen, "-" und "/" Zeichen',
    minLenght15: 'Erforderliche 15 Zeichen!',
    maxLenght15: 'Maximale Länge ist 25 Zeichen!',
    purchaseDateMax: 'Datum kann nicht später sein, als',
    purchaseDateReq: 'Muss ausgefüllt werden! (Beispiel 2020-03-07)',
    insuranceNumberRule: 'Darf keine Leer- und Sonderzeichen enthalten!',
    emailNoSpace: 'Darf keine Leerzeichen enthalten!',
    requiredFieldRequestId: 'Kötelező kitölteni! GSM munkalapszám vagy igényazonosító',
  },
  order: {
    orderId: 'Auftragsnummer',
    orderDate: 'Auftragsdatum',
    orderStatus: 'Auftragsstatus',
    orderPlaceNewOrder: 'Neue Bestellung aufgeben',
    orderDetails: 'Einzelheiten zur Bestellung',
  },
  request: {
    requestDetails: 'Einzelheiten der Anfrage',
    requestData: 'Daten anfordern',
  },
  serviceRequestHistory: {
    RECEIVED: 'Eingegangene Bestellung',
    DELIVERY_IN_ORDERED: 'Bestellung in Auslieferung',
    ARRIVED: 'Gerät ist im Service angekommen',
    QUOTE_DONE: 'Auftrag abgeschlossen',
    QUOTE_ACCEPTED: 'Angebot akzeptiert',
    QUOTE_REJECTED: 'Angebot abgelehnt',
    PRODUCT_LEFT_THE_SERVICE: 'Produkt hat den Service verlassen',
    INVOICE_DONE: 'Rechnung gestellt',
    DELIVERY_OUT_ORDERED: 'Die Auslieferung ist bestellt',
    SHIPPED: 'Versendet',
    SERVICE_DONE: 'Gerät hat den Service verlassen',
  },
  shipping: {
    pickupAddress: 'Abholungsadresse',
    shippingAddress: 'Lieferadresse',
  },
  services: {
    iw: 'Garantiereparatur',
    vodaiw: 'Garantiereparatur',
    oow: 'Reparatur außerhalb der Garantie',
    sw: 'Servicegarantie',
    asu: 'Versicherungs-Reparatur',
    asuw: 'Versicherungs-Servicegarantie',
    asuw2: 'Versicherungs',
    swPickup: 'Servicegarantie Abholung und Retour',
    garancia: 'Versicherungs-Servicegarantie Abholung und Retour',
    lenovo: 'Lenovo abholen und zurückbringen',
    asus: 'Asus abholen und zurückbringen',
    xiaomi: 'Xiaomi abholen und zurückbringen',
    gopro: 'GoPro abholen und zurückbringen',
    flottakezeles: 'Für Geschäftspartner',
    simpleRepair: 'Einfacher Reparaturauftrag',
    insuranceService: 'Versicherungs-Servicegarantie',
    mostmobil: 'Mostmobil.hu Servicegarantie',
    telekomService: 'Für Telekom-Kunden',
    telenorService: 'Für Yettel-Kunden',
    vodafoneService: 'Für Vodafone-Kunden',
  },
  stepper: {
    generalDetails: 'Allgemeine Angaben',
    deviceDetails: 'Einzelheiten zum Gerät',
    customerDetails: 'Einzelheiten zum Kunden',
    confirmation: 'Bestätigung',
    done: 'Erledigt',
  },
  ERROR_MESSAGES: {
    ERROR_IMEI: 'Die IMEI-Nummer, die Sie eingegeben haben, passt nicht zum Luhn-Algorithmus. Bitte überprüfen Sie die von Ihnen eingegebene IMEI-Nummer. Wenn Sie eine Seriennummer eingegeben haben, nehmen Sie diese Nachricht bitte objektfrei entgegen.',
    REQUEST_SEND_1: '<div class="display-1 mb-6"><b>Unerwarteter Fehler!</b></div><div class="headline mb-4">Wir arbeiten daran, das Problem zu beheben.</div>',
    REQUEST_SEND_2: '<div class="display-1 mb-6"><b>Verbindung unterbrochen!</b></div><div class="headline mb-4">Wir arbeiten daran, das Problem zu beheben.</div>',
    ONE_FILE_IS_REQUIRED: 'Für die Garantieabwicklung fügen Sie bitte eine Kopie der Rechnung und/oder der validierten Garantiekarte bei.',
    FILE_NOT_VALID: 'Die Datei, die Sie hochzuladen versuchen, hat einen Fehler! Die Datei sollte eine .pdf-Erweiterung '
      + 'haben und die Größe muss größer als 0 kB sein! Bitte laden Sie eine andere Datei hoch!',
    INVALID_DATE: 'Das Kaufdatum ist ungültig. Bitte geben Sie das gültige Kaufdatum ein!',
    INSURANCE_NUMBER_VALIDATION: '<div class="display-1 mb-6"><b>Érvénytelen kárszám!</b></div><div>Kérjük keresse bizosítóját a kárszámmal kapcsolatosan!</div>',
    USER_EXISTS: '<div class="display-1 mb-6"><b>Erfolglose Einsendung!</b></div><div class="headline mb-4">Es gibt bereits einen registrierten Anwender im System mit dieser E-Mail-Adresse! </div><div>Ihre Ansprüche können Sie nur nach <b>Anmeldung</b> abgeben!</div>',
  },
  SUCCESS_MESSAGES: {
    SUCCESS_IMEI: 'IMEI-Eingabe erfolgreich.',
    REQUEST_SEND: '<div class="display-1 mb-6"><b>Erfolgreiche Übermittlung!</b></div><div class="headline mb-4">Ihre Anfrage ist angekommen. '
      + 'Eine Bestätigungs-E-Mail wird in Kürze an die von Ihnen im Formular angegebene E-Mail-Adresse gesendet</div><div>Dankeschön!</div>',
    USER_EXISTS: '<div class="display-1 mb-6"><b>Absenden nicht erfolgreich !</b></div><div class="headline mb-4">Diese E-Mail-Adresse ist bereits '
      + 'registriert!</div><div>Ihre Anfrage kann bearbeitet werden, nachdem Sie sich mit Ihrer registrierten E-Mail-Adresse <b>angemeldet</b> haben.</div><div>Danke!</div>',
    INSURANCE_NUMBER_VALIDATION: '<div class="display-1 mb-6"><b>Eingabe erfolgreich!</b></div>',
  },
  warrantyRepair: 'Garantiereparatur',
  warrantyRepairs: 'Garantiereparatur',
  payedRepair: 'Reparatur außerhalb der Garantie',
  tmxGeneralInformation: '<p>Sehr geehrter Kunde! </p> '
    + '<p>Bitte lesen Sie unsere Richtlinie zur Datenverwaltung sorgfältig durch ((http://www.tmx.hu/adatvedelmi-szabalyzat)). Mit dem Ausfüllen der nachstehenden Informationen betrachten wir unsere Richtlinie als akzeptiert. </p> '
    + '<p>Wenn Sie Fragen zur Reparatur oder zum Austausch Ihres Gerätes haben, lesen Sie bitte unsere Informationen zu den Bedingungen der Garantiereparatur. </p>'
    + '<p>Wenn Sie eine Garantiereparatur anfordern und die Bedingungen der Garantiereparatur akzeptieren, geben Sie bitte die unten stehenden Informationen an. Anfragen, die vor 14:30 Uhr eingehen, können am nächsten Werktag erfüllt werden. </p>'
    + '<p>Erwartete Reparaturdauer: kann 7-10 Werktage nach Eingang dauern (je nach Gerätemodell) </p> + <p> <b>Garantiebedingungen zur Durchsetzung von Gewährleistungsrechten: </b> </p>'
    + '<ul class="mb-4"><li>Die Garantiezeit beträgt 6 (sechs) Monate.</li>'
    + '<li> Die Gewährleistungsfrist beginnt mit der Auslieferung des Gerätes an den Verbraucher oder dessen Beauftragten nach erfolgter Serviceleistung.</li>'
    + '<li> Reklamationen können nur schriftlich, per E-Mail, unter Angabe der Garantiekartennummer geltend gemacht werden.</li></ul>'
    + '<p><b>Weitere Bedingungen: </b></p>'
    + '<ul class="mb-4"><li>Im Falle einer berechtigten Anfrage holt der Service das zu reparierende Gerät ab. </li>'
    + '<li> Bitte senden Sie die SIM- und Speicherkarte nicht mit dem Gerät ein, da wir dafür keine Haftung übernehmen können. </li>'
    + '<li> <b>Bitte legen Sie die Garantiekarte bei, die Sie mit Ihrem Gerät erhalten haben. </b> </li>'
    + '<li> Bei der Reparatur des Gerätes können die auf dem Gerät gespeicherten Daten verloren gehen, wofür wir keine Haftung übernehmen. </li>'
    + '<li> Die Garantie beeinträchtigt nicht die gesetzlichen Rechte des Verbrauchers nach der geltenden Gesetzgebung, einschließlich der Produktgarantie und der Gewährleistungsrechte. </li>'
    + '<li> Weitere Bedingungen der Garantie sind in der aktuellen Regierungsverordnung mit dem Titel "Regierungsverordnung über die obligatorische Garantie für bestimmte Reparatur- und Wartungsleistungen" enthalten. </li> </ul>'
    + '<p> <b>Produktgarantie und Garantierechte</b> </p>'
    + '<p>Bezieht sich auf den von TMX Mobile Solution Szerviz Kft. erbrachten Reparatur- und Wartungsservice, der sich auf die Reparatur der in der Garantiekarte angegebenen "durchgeführten Arbeiten, hauptsächlich verwendete Teile" beschränkt. </p>'
    + '<p>Die Durchsetzbarkeit der auf das Gerät bezogenen Produktgarantie- und Gewährleistungsrechte wird durch die Tatsache, '
    + 'dass TMX Mobile Solution Szerviz Kft. Reparatur- und Wartungsleistungen am Gerät durchgeführt hat, nicht berührt, d.h. eine Gewährleistungsverantwortung des Händlers bzw. des Geräteherstellers bleibt unverändert gültig. </p>'
    + '<p>Aus dem oben Gesagten folgt, dass bei anderen Fehlern des Geräts, die nicht mit dem von TMX Mobile Solution Szerviz Kft. durchgeführten Reparatur- und Wartungsservice zusammenhängen, das Gerät gemäß den Garantie- und '
    + 'Gewährleistungsbedingungen des Herstellers oder des Händlers repariert werden kann. </p>'
    + '<p> <b>Gründe, die die Service-Garantie ausschließen: </b> </p>'
    + '<ul class="mb-4"><li> Auftreten eines neuen Schadensereignisses (z.B.: Bruch, Kontakt mit einer Flüssigkeit). '
    + 'Bitte kontaktieren Sie den Versicherer unter 06/80 30 0030. </li> </ul> + <p>Die Garantiereparatur ist kostenlos. </p>'
    + '<p>Im Falle, dass der Service die Aussage "Kein Fehler gefunden" ausstellt, oder der Fehler am Gerät nicht festgestellt wird, '
    + 'oder die Garantie abgelehnt wird (z.B. äußere mechanische Einwirkung auf das Gerät, (Bruch) oder nicht autorisierte Reparatur), '
    + 'sind die Versandkosten vom Kunden zu bezahlen, die 4.522 HUF betragen, die bei Erhalt des Gerätes zu begleichen sind. </p>'
    + '<p>Bei Apple-Geräten muss der Kunde die Funktion Find My Iphone (FMI) abschalten. Sollte sich diese Funktion nicht beheben lassen, '
    + 'hilft der Apple-Kundendienst unter 0680/983 824 weiter. </p>'
    + '<p>Bitte beachten Sie, dass die FMI-Abschaltzeit nicht in der Reparaturzeit enthalten ist. </p>',
  inputLabels: {
    companyName: 'Name des Unternehmens',
    firstName: 'Vorname',
    middleName: 'zweiter Vorname',
    lastName: 'Nachname',
    email: 'E-mail',
    phone: 'Telefonnummer',
    name: 'Name',
    topic: 'Themenbereich',
    manufacturer: 'Hersteller',
    imei: 'IMEI Nummer (IMEI/SN)',
    subject: 'Betreff',
    message: 'Mitteilung',
    field: 'Feld',
    job: 'Stelle',
    cv: 'CV',
    otherDoc: 'Anderes Dokument',
    productType: 'Produkttyp',
    warrantyType: 'Garantie-Typ',
    dateOfPurchase: 'Kaufdatum',
    troubleDescription: 'Fehlerbeschreibung',
    simLock: 'Sim Sperre',
    country: 'Land',
    postalCode: 'Postleitzahl',
    city: 'Stadt',
    publicPlaceName: 'Öffentlicher Name',
    publicPlaceType: 'Öffentlicher Typ',
    publicPlaceNameAndType: 'Öffentlicher Name und Typ',
    streetNumber: 'Hausnummer',
    other: 'Sonstiges',
    invoice: 'Rechnung',
    warrantyTicket: 'Garantieschein',
    damageNumber: 'Schadennummer',
    serviceRequest: 'Service-Anfrage',
    serviceRequests: 'Service-Anfragen',
    calculatedPrice: 'Kalkulierter Reparatur- und Lieferpreis',
    serviceRequestId: 'Dienstanfrage-ID',
    rma: 'RMA-Nummer',
    productGroup: 'Produktgruppe',
    uniqueIdentifierIn1: 'Eindeutige ID 1',
    uniqueIdentifierIn2: 'Eindeutige ID 2',
    uniqueIdentifierIn3: 'Eindeutige ID 3',
    uniqueIdentifierIn1new: 'Geräte-ID 1 (IMEI/SN)',
    uniqueIdentifierIn2new: 'Geräte-ID 2 (IMEI/SN)',
    uniqueIdentifierIn3new: 'Geräte-ID 3 (IMEI/SN)',
    accountRegistrationTrue: 'Ja, ich möchte ein Konto registrieren.',
    accountRegistrationFalse: 'Nein, ich möchte kein Konto registrieren.',
    password: 'Passwort',
    selectShippingAddress: 'Lieferanschrift wählen',
    selectBillingAddress: 'Rechnungsadresse wählen',
    serviceRequestIdentifier: 'Dienstleistungs-ID',
    origRequestSource: 'Webseite',
    selectFilesToUpload: 'Dateien zum Hochladen auswählen',
    deviceColor: 'Farbe des Gerätes',
    businessFormName: 'Unternehmensform',
    paymentMethods: 'Zahlungsmethode',
    accountNumber: 'Bankkontonummer',
    taxNumber: 'Steuernummer',
    imei1: 'IMEI 1',
    imei2: 'IMEI 2',
    serialNr: 'Seriennummer - SN',
    purchaseInvoice: 'Kaufnachweis Rechnung',
    purchaseWarrantyTicket: 'Garantieschein, den Sie beim Kauf erhalten haben ',
    serialNumber: 'DE - Sorozatszám',
  },
  insuranceRepairForm: 'Versicherungs-Reparaturauftrag',
  introduction: 'Einführung',
  ourServices: 'Unsere Serviceleistungen',
  whyChooseUs: 'Warum uns wählen?',
  ourPartners: 'Unsere Partner',
  generalRequirements: 'Allgemeine Anforderungen',
  seeJobOffers: 'Siehe unsere aktuellen Angebote',
  complexServiceForOurPartners: 'Komplexe Dienstleistungen für unsere Partner',
  centralEuropeSuperGrowingService: 'Kontinuierlich wachsender Dienst in Mitteleuropa.',
  whereCanYouFindUs: 'Wo können Sie uns finden?',
  sendUsAMessage: 'Senden Sie uns eine Nachricht',
  ourEmail: 'Unsere E-Mail Adresse',
  ourPhoneNumber: 'Unsere Telefonnummer',
  ourStores: 'Unsere Läden',
  serviceProducts: 'Service-Produkte',
  impress: 'Impressum',
  nameOfTheCompany: 'Name des Unternehmens',
  headquarter: 'Hauptquartier',
  headquarterOfTheCompany: 'Hauptsitz des Unternehmens',
  sitesOfTheCompany: 'Standorte des Unternehmens',
  postalAddressOfTheCompany: 'Postanschrift des Unternehmens',
  phoneNumberOfTheCompany: 'Telefonnummer der Firma',
  telefaxNumberOfTheCompany: 'Telefaxnummer des Unternehmens',
  emailOfTheCompany: 'E-Mail-Adresse der Firma',
  VATNumberOfTheCompany: 'Steuernummer des Unternehmens',
  webhostDetails: 'Webhost-Name und Datendetails',
  complaintPolicy: 'Beschwerde-Politik',
  privacyPolicy: 'Datenschutzerklärung',
  deliveryService: 'Futárszolgálat',
  deliveryServiceText: 'A futárszolgálat lehetőséget választva a javítási igény leadását követően a szerviz az 1. pontban megadott címre küldi a futárt. A készülék begyűjtéséről legkésőbb a begyűjtés napján SMS / email / telefonhívás formájában tájékoztatja a futár.',
  foxpost: 'Foxpost',
  foxpostText: 'A csomagot a kiválasztott Foxpost csomagautomatában tudja feladni részünkre, begyűjtési igényének feldolgozását követően az SMS-ben / emailben kapott nyitókód megadásával.',
  termsAndCond: 'Allgemeine Geschäftsbedingungen',
  usedEquipmentForSale: 'Verkauf gebrauchter Geräte - Allgemeine Geschäftsbedingungen',
  imeiHint: 'Geben Sie den Code *#06# in Ihr Telefon ein, um ihn zu überprüfen.',
  imeiHintOptional: 'Optional! Sie können es durch Wählen von *#06# überprüfen.',
  checkSpamFolder: 'Nachdem Sie erfolgreich bestellt haben, senden wir Ihnen eine E-Mail an Ihre E-Mail-Adresse. Bitte überprüfen Sie Ihren SPAM-Ordner, falls Sie die E-Mail nicht finden sollten',
  moreInfosAboutTheService: 'Informationen über die Dienstleistung',
  imeiError: 'Die IMEI-Nummer, die Sie eingegeben haben, passt nicht zum Luhn-Algorithmus. Bitte überprüfen Sie die von Ihnen eingegebene IMEI-Nummer. Wenn Sie eine Seriennummer eingegeben haben, nehmen Sie diese Nachricht bitte objektfrei entgegen.',
  attachDocs: 'Bitte fügen Sie die erforderlichen Dokumente ein, falls Sie sie nicht mit Ihrem Gerät senden können.',
  generalServiceInfoText: '<p>Sehr geehrte(r) Kunde/Kundin!</p>'
    + '<p>Bitte wählen Sie aus den folgenden zwei Optionen und geben Sie die angeforderten Daten ein, damit unser Kurierdienst das Gerät abholen kann.</p>'
    + '<p>Wenn Sie ein Apple-Gerät versenden möchten, deaktivieren Sie bitte die FMI-Funktion (Find My iPhone), bevor Sie das Produkt an den Kurier übergeben. Unser Service kann die Reparatur nicht starten, solange diese Funktion aktiv ist.</p>'
    + '<p>Bitte beachten Sie, laut der Instruktionen des Herstellers, wenn das Gerät ein mechanischer Schaden hat (z. B. in Wasser gefallen, heruntergefallen, das Display gebrochen, die Abdeckung beschädigt usw.), diese im Rahmen der Garantie nicht repariert werden kann, Wählen Sie bitte aus den zwei folgenden Optionen: kostenpflichtig reparieren.</p>'
    + '<p>Im Falle einer Reparatur im Rahmen der Garantie senden Sie bitte eine gültige Garantieticket und / oder eine Kopie der Rechnung des Gerätes.</p>'
    + '<p>Wenn Sie Fragen haben, senden Sie uns bitte eine E-Mail an info@tmx.hu.</p>'
    + '<p></p>'
    + '<p></p>'
    + '<p>TMX Mobile Solution Szerviz Kft.</p>',
  loremSentence: 'Aenean eget risus urna',
  loadingMessages: {
    INPROGRESS_CONTACT_FORM: 'Sendung in Bearbeitung. Bitte warten Sie.',
    INPROGRESS_RESETPASSWORD: 'Ihre Anfrage ist in Bearbeitung. Bitte warten Sie.',
    INPROGRESS_SETPASSWORD: 'Das Zurücksetzen des neuen Passworts ist in Bearbeitung. Bitte warten Sie.',
    INPROGRESS_CSVUPLOADING: 'Das Hochladen der Datei läuft. Bitte warten Sie.',
    INPROGRESS_DOCUPLOADING: 'Das Hochladen von Dokumenten läuft. Bitte warten Sie.',
    INPROGRESS_FORMLOADING: 'Das Formular wird geladen.',
  },
  documentTypes: {
    warrantyCardHash: 'Eingereichte Garantiekarte',
    invoiceHash: 'Eingeleitete Rechnung',
    faultReportHash: 'Fehlerbericht',
    handoverDocumentHash: 'Übergabedokument',
    gsmQuotationHash: 'Gsm-Kostenvoranschlag',
    dolphinInvoiceHash: 'Dolphin-Rechnung',
    gsmRepairWorksheetHash: 'GSM-Reparatur-Arbeitsblatt',
  },
  noDataText: {
    productGroups: 'Es gibt keine Produktgruppe für den ausgewählten Hersteller',
    general: 'Es gibt keine Daten zu zeigen.',
    noRequestFound: 'Anfrage nicht gefunden.',
    noSearchResult1: 'Es wurden keine Ergebnisse gefunden: ',
    noSearchResult2: '.',
  },
  szervizkalk: {
    buybackIntro: '<p>Veritatis quibusdam quisquam assumenda voluptatem voluptate et dolor. Voluptatibus laudantium eos odio ut impedit qui temporibus quia nulla. Nihil modi excepturi iste. Et quaerat id aut.</p>'
      + '<p>Veritatis quibusdam quisquam assumenda voluptatem voluptate et dolor.</p>',
    soldItToUs: 'Verkaufen Sie es zu diesem Preis an uns.',
  },
  needToLogin: 'Sie müssen sich anmelden, um diesen Service nutzen zu können!',
  customerTypeCantSeeTheContent: 'Sie haben keine Berechtigung, diesen Inhalt zu erreichen.',
  passwordCheck: {
    min1lowercase: 'Min. ein Kleinbuchstabe',
    min1uppercase: 'Min. ein Großbuchstabe',
    min8char: 'Min. 8 Zeichen',
    min1number: 'Min. 1 Zahl',
    min1special: 'Min. 1 Sonderzeichen: !@#$%*()_+-={}?',
    passMatch: 'Das Passwort stimmt überein.',
  },
  paymentAndAddressDatas: 'Adress- und Zahlungsdaten',
  financialDatas: 'Finanzielle Daten',
  nob2bRefNo: 'Es gibt keine Referenznummer',
  noDocumentToShow: 'Es gibt kein Dokument zum Vorzeigen.',
  reUseRequestDialog: {
    title: 'Antrag auf Servicegarantie',
    desc: 'Amennyiben a készülék javításával probléma merült fel és a korábbi javítás után nem telt el hat hónap, úgy a tovább gomb megnyomásával az űrlapon a hiányzó adatok kitöltésével igényelhető a Szerviz által biztosított garanciális ügyintézés.',
  },
  gsmWorksheetId: 'GSM-Arbeitsticket-Nummer',
  downloadFileErrorDialog: {
    title: 'Fehler',
    noFile: 'Das ausgewählte Dokument wurde nicht gefunden. Bitte versuchen Sie es später!',
  },
  loginOrReg: 'Einloggen',
  $vuetify: {
    badge: 'Abzeichen',
    close: 'Schließen',
    dataIterator: {
      noResultsText: 'Keine Elemente gefunden',
      loadingText: 'Lade Elemente...',
    },
    dataTable: {
      itemsPerPageText: 'Zeilen pro Seite:',
      ariaLabel: {
        sortDescending: 'Absteigend sortiert.',
        sortAscending: 'Aufsteigend sortiert.',
        sortNone: 'Nicht sortiert.',
        activateNone: 'Aktivieren um Sortierung zu entfernen.',
        activateDescending: 'Aktivieren um absteigend zu sortieren.',
        activateAscending: 'Aktivieren um aufsteigend zu sortieren.',
      },
      sortBy: 'Sortiere nach',
    },
    dataFooter: {
      itemsPerPageText: 'Elemente pro Seite:',
      itemsPerPageAll: 'Alle',
      nextPage: 'Nächste Seite',
      prevPage: 'Vorherige Seite',
      firstPage: 'Erste Seite',
      lastPage: 'Letzte Seite',
      pageText: '{0}-{1} von {2}',
    },
    datePicker: {
      itemsSelected: '{0} ausgewählt',
      nextMonthAriaLabel: 'Nächsten Monat',
      nextYearAriaLabel: 'Nächstes Jahr',
      prevMonthAriaLabel: 'Vorheriger Monat',
      prevYearAriaLabel: 'Vorheriges Jahr',
    },
    noDataText: 'Keine Daten vorhanden',
    carousel: {
      prev: 'Vorheriges Bild',
      next: 'Nächstes Bild',
      ariaLabel: {
        delimiter: 'Element {0} von {1}',
      },
    },
    calendar: {
      moreEvents: '{0} mehr',
    },
    fileInput: {
      counter: '{0} Dateien',
      counterSize: '{0} Dateien ({1} gesamt)',
    },
    timePicker: {
      am: 'AM',
      pm: 'PM',
    },
    pagination: {
      ariaLabel: {
        wrapper: 'Seitennavigation',
        next: 'Nächste Seite',
        previous: 'Vorherige Seite',
        page: 'Gehe zu Seite {0}',
        currentPage: 'Aktuelle Seite, Seite {0}',
      },
    },
    rating: {
      ariaLabel: {
        icon: 'Rating {0} of {1}',
      },
    },
  },
  homeWelcomeText: '<p class="display-1 text-center">Willkommen! Unsere neuen Dienste werden bald starten!</p>'
    + '<p class="text-center">Weitere Informationen unter info@tmx.hu.</p>',
  day: 'Tag',
  hour: 'Stunde',
  min: 'Minute',
  sec: 'Sekunde',
  kisokosTitle: 'Leitfaden für Mitarbeiteranfragen',
  kisokosSubTitle: 'Leitfaden',
  szervizgar: {
    vodafoneGeneralInformation: '<p>Sehr geehrter Kunde,</p>' +
      '<p>Wir können die bis 14:30 angekommene Anfragen frühestens am nächsten Arbeitstag erfüllen.</p>' +
      '<p>Bei Apple-Geräten soll der Kunden, die FMI Funktion (Find My Phone deaktivieren. Wenn Sie diese Funktion nicht deaktivieren können, wenden Sie sich an den Apple-Kundendienst..</p>' +
      '<p>Bitte beachten Sie, dass die Deaktivierung der FMI-Funktion nicht unter der Reparaturzeit fällt.</p>',
    generalInformation: '<p>Sehr geehrter Kunde,</p>'
      + '<p>Unsere Versicherungsleistung Garantie ist nach einem vorangegangenen Versicherungsfall verfügbar. </p>'
      + '<p>Anfragen, die vor 14.30 Uhr eingehen, können frühestens am nächsten Werktag erfüllt werden. </p>'
      + '<p>Der Grund für den Ausschluss der Servicegarantie ist das Auftreten eines neuen Schadensereignisses (z.B.: Bruch, Flüssigkeitsschaden, unbefugte Hardware-/Softwaremanipulation). In diesen Fällen wenden Sie sich bitte an Ihren Versicherer. </p>'
      + '<p> Bei Apple-Geräten liegt es in der Verantwortung des Kunden, die Funktion "Find My Iphone" zu deaktivieren. Wenn Sie diese Funktion nicht deaktivieren können, wenden Sie sich bitte an den Apple-Kundensupport, um Unterstützung zu erhalten. </p>'
      + '<p> Bitte beachten Sie, dass die FMI-Deaktivierungszeit nicht in der Reparaturzeit enthalten ist.</p>',
  },
  regIsBetterText: '<p class="text-center font-18">Sie haben sich für eine Bestellung ohne Registrierung eines Accounts entschieden.</p>'
    + '<p class="text-center font-18">Wir möchten Sie auf die Vorteile eines registrierten Kontos hinweisen: Schnellere und bequemere Verwaltung, '
    + 'detaillierte Auftragsliste und -historie, automatisches Formular für neue Aufträge</p>',
  loginWelcomeText: 'Um Ihre Reparatur zu verfolgen, geben Sie bitte die gewünschten Daten ein.',
  toRegUsers: 'Für Benutzer mit registriertem Konto:',
  toReguserText: 'Sie können Ihr persönliches Konto <a href="/bejelentkezes" style="color: #FFFFFF;"><b>hier</b></a>.',
  xiaomiRepairText: '<p class="title text-center">Weitere Informationen zu den kommerziellen Bedingungen der Covid 19 Garantie finden Sie auf der '
    + '<a href="https://www.mi.com/global/service/warranty" target="_blank">https://www.mi.com/global/service/warranty</a> Website.</p>',
  invoiceOnlinePayment: 'Online bezahlen',
  pending: 'Ausstehend',
  payed: 'Bezahlt',
  invoiceNumber: 'Rechnungsnummer',
  invoiceCreatedAt: 'Rechnungsdatum',
  invoiceGrossPrice: 'Rechnungsbetrag',
  paymentMethod: 'Zahlungsmethode',
  paymentLoading: 'Die Zahlungsbestätigung ist in Arbeit.',
  dontCloseTheBrowser: 'Bitte schließen Sie Ihren Browser nicht.',
  paymentSuccess: 'Zahlung erfolgreich!',
  paymentFailed: 'Zahlung fehlgeschlagen!',
  paymentCanceled: 'Die Zahlung wurde abgebrochen.',
  paymentSorry: 'Es tut uns leid, dass Sie die Online-Zahlung nicht durchführen können',
  paymentSorryAuth: 'Klicken Sie auf die Schaltfläche unten, um zu Ihren eingereichten Forderungen zurückzukehren.',
  paymentSorryNotAuth: 'Klicken Sie auf die Schaltfläche unten, um zur Homepage zurückzukehren.',
  generateRandomImei: 'Amennyiben nem tudja ezt az azonosítót kérjük kattintson a Generálás gombra.',
  vodafone: {
    homePageSubtitle: 'Javítások megrendelése',
    warrantyRepairForm: 'Jótállásos javítási igény leadás',
  },
  cookies: 'Cookie-Einstellungen',
  dearCustomes: 'Sehr geehrter Kunde,',
  docTmxTracking: 'documents.tmx.hu',
  documentViewer: {
    loginInProgress: '<div class="body-1">Anmeldung in Bearbeitung</div>',
    succAuth: '<div class="title mb-6 font-weight-bold">Erfolgreiche Identifikation!</div><div class="body-1">Eine Bestätigungs-E-Mail für Ihre Anmeldung wurde an die von Ihnen angegebene E-Mail-Adresse gesendet.</div>',
    failedAuth: '<div class="title mb-6 font-weight-bold">Identifikation fehlgeschlagen!</div><div class="body-1">Bitte überprüfen Sie die eingegebenen Angaben und versuchen Sie es erneut!</div>',
    inprogressAuth: '<div class="body-1">Identifikation in Bearbeitung</div>',
    noCloseBrowser: 'Bitte schließen Sie das Browserfenster nicht!',
    loadingData: 'Daten werden geladen. Bitte warten Sie!',
    loadingFailed: 'Die Daten konnten nicht geladen werden!<br>Bitte klicken Sie auf <b>Aktualisieren</b>!',
  },
};
