import { SYSTEMPARAMS } from 'src/utils/systemParameters';
import { APIPOST, APIGET, APIPATCH } from 'src/utils/apiHelper';
import {
  getToken,
  saveToken,
  destroyToken,
  getUid,
  saveUid,
  destroyUid,
  saveURA,
  getURA,
  destroyURA,
  saveLoginMethod, destroyLoginMethod, getLoginMethod,
} from 'src/utils/jwtHelper';
import i18n from 'src/i18n';
import router from 'src/router.js';

// initial state
const state = {
  user: null,
  isAuthenticated: !!getToken(),
};

// getters
const getters = {
  isAuthenticated() {
    return state.isAuthenticated;
  },
  getDeliveryOutAddresses(state) {
    let arrayToReturn = [];
    state.user.addresses.forEach((item) => {
      if (item.addressType.id === 3) {
        arrayToReturn.push(item);
      }
    });
    return arrayToReturn[0];
  },
};

// actions
const actions = {
  /***
   *
   * login
   *
   * @param context
   * @param credentials
   * @returns {Promise<number|*>}
   * @constructor
   */
  async Login(context, credentials) {
    const response = await APIPOST('login', credentials);
    // console.log('LOGIN response: ', response);
    if (response) {
      if (response.status === 200) {
        saveLoginMethod('jz:00=p+');
        if (response.data.type === 'EMPLOYEE') {
          await saveURA(response.data.userRoles);
        }
        await context.commit('setAuth', {uid: response.data.customerId, token: response.data.Token});
        await context.dispatch('GetCustomer');
        await context.dispatch('GetAddresses');
        await context.dispatch('GetFinancialData');
        if (response.data.type === 'PREALERT') {
          await context.dispatch('GetServiceRequestsPreAlert');
          // await context.dispatch('GetServiceRequests');
        } else {
          await context.dispatch('GetServiceRequests');
        }
        await context.dispatch('GetSystemParams');
      }
      return response;
    }
    return response;
  },
  /***
   *
   * logout
   *
   * @param context
   * @returns {Promise<void>}
   * @constructor
   */
  async Logout(context) {
    const response = await APIPATCH('logout', {}, true );
    // console.log('LOGOUT response: ', response);
    context.commit('purgeAuth');
  },
  /***
   *
   * loginValidator
   *
   * @param context
   * @returns {Promise<string>}
   * @constructor
   */
  async CheckAuthentication(context) {
    const token = await getToken();
    if (token) {
      const response = await APIGET(`login/validator`, {}, true );
      if (response && response.data.status === 200) {
        context.commit('updateAuth');
        if (context.state.user === null) {
          // console.log('if user 1: ', context.state.user);
          await context.dispatch('GetCustomer');
          await context.dispatch('GetAddresses');
          await context.dispatch('GetFinancialData');
          if (context.state.user.profile.customerType === 'PREALERT') {
            await context.dispatch('GetServiceRequestsPreAlert');
            // await context.dispatch('GetServiceRequests');
          } else {
            await context.dispatch('GetServiceRequests');
          }
          await context.dispatch('GetSystemParams');
        }
      } else {
        context.commit('purgeAuth');
      }
    }
    return 'done';
  },
  /***
   *
   * showCustomer
   *
   * @param context
   * @param credentials
   * @returns {Promise<void>}
   * @constructor
   */
  async GetCustomer(context, credentials) {
    const uid = await getUid();
    const response = await APIGET(`customers/${uid}`, {}, true );
    if (response && response.data.status === 200) {
      switch (response.data.data.defaultLanguage.code) {
        case 'hu':
          i18n.locale = 'hu-HU';
          break;
        case 'en':
          i18n.locale = 'en-EN';
          break;
        case 'de':
          i18n.locale = 'de-DE';
          break;
        default:
          i18n.locale = 'hu-HU';
      }
      // console.log('i18n.local = ', i18n.locale);
      context.commit('setUser', response.data.data );
    }
  },
  /***
   *
   * showCustomerAddress
   *
   * @param context
   * @param credentials
   * @returns {Promise<void>}
   * @constructor
   */
  async GetAddresses(context, credentials) {
    const uid = await getUid();
    const response = await APIGET(`customers/${uid}/addresses`, {}, true );
    if (response && response.data.status === 200) {
      context.commit('setUserAddresses', response.data.data );
    }
  },
  /***
   *
   * showCustomerFinancialData
   *
   * @param context
   * @param credentials
   * @returns {Promise<void>}
   * @constructor
   */
  async GetFinancialData(context, credentials) {
    const uid = await getUid();
    const response = await APIGET(`customers/${uid}/financial-data`, {}, true );
    if (response && response.data.status === 200) {
      context.commit('setUserFinancialData', response.data.data );
    }
  },
  /***
   *
   * showAllCustomerRequests
   *
   * @param context
   * @param credentials
   * @returns {Promise<void>}
   * @constructor
   */
  async GetServiceRequests(context, credentials) {
    const response = await APIGET(`customer-requests`, {}, true );
    if (response && response.data.status === 200) {
      context.commit('setUserServiceRequests', response.data.data );
    }
  },
  /***
   *
   * showAllCustomerRequestPrealert
   *
   * @param context
   * @param credentials
   * @returns {Promise<void>}
   * @constructor
   */
  async GetServiceRequestsPreAlert(context, credentials) {
    const response = await APIGET(`customer-requests/show-prealerts`, {}, true );
    if (response && response.data.status === 200) {
      context.commit('setUserServiceRequests', response.data.data );
    }
  },
  async GetSystemParams(context, credentials) {
    const params = SYSTEMPARAMS.find(item => {
      return item.customerType === context.state.user.profile.customerType;
    });
    // console.log('params: ', params);
    context.commit('setUserSystemParams', params);
  },
  async Registration(context, credentials) {
    // console.log('auth.module Registration: ', credentials);
  },
  async ResetPassword(context, email) {
    const response = await APIPOST(`reset-password/${email}`);
    // console.log('ResetPassword response: ', response);
    return response.status;
  },
  async SetPassword(context, credentials) {
    const response = await APIPOST(`set-password/${credentials.vs}`, credentials.form);
    // console.log('SetPassword response: ', response);
    return response.status;
  },
  async ChangePassword(context, credentials) {
    let token = getToken();
    const response = await APIPOST(`change-password/${token}`, credentials.form, true);
    // console.log('SetPassword response: ', response);
    return response.status;
  },
  async saveCustomerNewAddress(context, credentials) {
    const response = await APIPOST(`customers/${credentials.cid}/addresses`, credentials.form, true);
    // console.log('saveCustomerNewAddress response: ', response);
    return response.status;
  },
  async FindCustomerCorrespondingAddresses(context, credentials) {
    const base = `${credentials.address.postalCode}${credentials.address.city}${credentials.address.publicPlaceName}`
      +`${credentials.address.publicPlaceType}${credentials.address.streetNumber}`;
    const baseNoSpecialCharacters = base.replace(/[^a-zA-Z0-9]/g, '');
    console.log('base: ', baseNoSpecialCharacters);
    for (let i = 0; i < context.state.user.addresses.length; i++) {
      if (context.state.user.addresses[i].addressType.id === credentials.correspondingAddressTypeId) {
        let cBase = `${context.state.user.addresses[i].zipCode}${context.state.user.addresses[i].city}`
          + `${context.state.user.addresses[i].publicPlaceName}${context.state.user.addresses[i].publicPlaceType}`
          + `${context.state.user.addresses[i].houseNumber}`;
        let cBaseNoSpecialCharacters = cBase.replace(/[^a-zA-Z0-9]/g, '');
        console.log('cBase: ', cBaseNoSpecialCharacters);
        if (baseNoSpecialCharacters === cBaseNoSpecialCharacters) {
          return i;
        }
      }
    }
    return -1;
  },
  async GetDefaultAddress(context, credentials) {
    const firstAddressIndex = state.user.addresses.findIndex(item => item.addressType.id === credentials.addressTypeId);
    console.log('firstAddressIndex: ', firstAddressIndex);
    for (const address of state.user.addresses) {
      if (address.addressType.id === credentials.addressTypeId && address.isDefault) {
        return address;
      }
    }
    return firstAddressIndex > -1 ? state.user.addresses[firstAddressIndex] : 'MISSING_ADDRESS_DATA';
  },
};

// mutations
const mutations = {
  setUser(state, payload) {
    state.user = {};
    state.user.profile = payload;
  },
  setUserAddresses(state, payload) {
    state.user.addresses = payload;
  },
  setUserFinancialData(state, payload) {
    state.user.financialData = payload;
  },
  setUserServiceRequests(state, payload) {
    state.user.serviceRequests = payload;
  },
  setUserSystemParams(state, payload) {
    state.user.systemParams = payload;
  },
  updateAuth(state) {
    state.isAuthenticated = true;
  },
  async setAuth(state, payload) {
    state.isAuthenticated = true;
    await saveToken(payload.token);
    await saveUid(payload.uid);
    // window.location.reload();
  },
  async purgeAuth(state) {
    state.isAuthenticated = false;
    state.user = null;
    await destroyUid();
    await destroyToken();
    await destroyURA();
    if (getLoginMethod() === 'jz:00=p+') {
      window.location.reload();
    } else {
      await router.push({ name: 'bejelentkezes' });
    }
    await destroyLoginMethod();
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
