import Vue from 'vue';
import Vuetify from 'vuetify/lib';
// eslint-disable-next-line
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import colors from 'vuetify/lib/util/colors';
import i18n from 'src/i18n';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#4A89DC',
        secondary: '#ED5565',
        third: '#37474F',
        fourth: '#808285',
        fifth: '#e4e9ff',
        accent: '#8BC34A',
        error: '#eb4d4b',
        warning: '#f9ca24',
        info: '#7ed6df',
        success: '#6ab04c',
        telekomPrimary: '#e4007b',
        telenorPrimary: '#002340',
        vodafonePrimary: '#e50001',
        flipPrimary: '#ffc200',
        flipText: '#2d3340',
        whiteFA: '#FAFAFA',
        mostPrimary: '#7CBB48',
      },
    },
    options: {
      customProperties: true,
    },
  },
});
