/* eslint-disable no-shadow */
import { APIGET } from "../utils/apiHelper";
// initial state
const state = {
  brands: [],
  selectedBrand: null,
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async getMobilProviderById(context, brandId) {
    let brandConst = null;
    const response = await APIGET(`mobil-providers/${brandId}`);
    if (response.status === 200) {
      brandConst = response.data.data;
    } else if(response.status === 500) {
      brandConst = context.state.brands.find(item => {
        return parseInt(item.brandId) === parseInt(brandId);
      });
    }
    // console.log('getMobilProviderById - brandConst: ', brandConst);
    if (brandConst) {
      for (let i = 0; i < brandConst.availableService.length; i++) {
        // belső routing
        if (brandConst.availableService[i].url[0] === '/') {
          if (parseInt(brandConst.availableService[i].warrantyTypeId) === 1) {
            brandConst.availableService[i].path = { path: brandConst.availableService[i].url };
            brandConst.availableService[i].isExternal = false;
          } else if (parseInt(brandConst.availableService[i].warrantyTypeId) === 2) {
            brandConst.availableService[i].path = { path: brandConst.availableService[i].url };
            brandConst.availableService[i].isExternal = false;
          }
          // külső link
        } else {
          brandConst.availableService[i].path = { url: brandConst.availableService[i].url };
          brandConst.availableService[i].isExternal = true;
        }
        // megfelelő ikon beállítása
        if (parseInt(brandConst.availableService[i].warrantyTypeId) === 1) {
          brandConst.availableService[i].image = 'images/icons/garancialis-javitas.png';
        } else if (parseInt(brandConst.availableService[i].warrantyTypeId) === 2) {
          brandConst.availableService[i].image = 'images/icons/fizetos-javitas.png';
        }
      }
      context.commit('setSelectedBrand', brandConst);
    }
    return brandConst;
  },
  async validateInsuranceNumberAPI(context, credentials) {
    const response = await APIGET(`check-claim-number/${credentials}`, {}, false);
    return response.data;
  },
};

// mutations
const mutations = {
  setSelectedBrand(state, brand) {
    state.selectedBrand = null;
    state.selectedBrand = brand;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
