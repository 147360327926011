<template>
  <div v-frag>
    <inner-header></inner-header>
    <v-content class="pt-10 pb-0">
      <router-view></router-view>
      <futar-footer></futar-footer>
    </v-content>
    <v-fade-transition>
      <v-btn fab class="primary" dark fixed bottom right @click="scrollToTop" v-show="showToTopButton">
        <v-icon>
          mdi-chevron-up
        </v-icon>
      </v-btn>
    </v-fade-transition>
  </div>
</template>

<script>
import InnerHeader from '../components/Navigation/InnerHeader.vue';
import InnerSideNav from '../components/Navigation/InnerSideNav.vue';
import FutarFooter from '../components/footer/FutarFooter.vue';
import { SCROLL_TO_TOP_BUTTON_LIMIT } from '../constants/appConstants';
import * as langHelper from '../utils/langHelper';

export default {
  name: 'App',
  components: {
    InnerHeader, InnerSideNav, FutarFooter,
  },
  beforeMount() {
    this.detectLanguage();
    // console.log('INTERNAL LAYOUT');
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  data: () => ({
    navigationDrawer: true,
    miniVariant: false,
    showToTopButton: false,
  }),
  methods: {
    scrollToTop() {
      this.$vuetify.goTo(0, { duration: 800, offset: 0 });
    },
    handleScroll() {
      // console.log(window.scrollY);
      // this.scrollPos = window.scrollY;
      this.showToTopButton = window.scrollY > SCROLL_TO_TOP_BUTTON_LIMIT;
    },
    toggleNavigationDrawer() {
      this.navigationDrawer = !this.navigationDrawer;
      // this.miniVariant = !this.miniVariant;
    },
  },
};
</script>
