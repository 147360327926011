/* eslint-disable no-shadow */
import router from '../router';
import store from './index';
// import i18n from '../i18n';

const state = {
  sideNav: [
    {
      text: 'innerSideNav.home',
      icon: 'mdi-home',
      click: () => {
        router.push({ name: 'igenyles' });
      },
      roles: ['all'],
    },
    {
      text: 'innerSideNav.userInformation',
      icon: 'mdi-account-circle',
      click: () => {
        router.push({ name: 'alapadatok' });
      },
      roles: ['all'],
    },
    /* {
      text: 'innerSideNav.allOrders',
      icon: 'mdi-table',
      click: () => {
        router.push('/iranyitopult/osszes-megrendeles');
      },
    }, */
    {
      text: 'innerSideNav.allClaims',
      icon: 'mdi-tablet-cellphone',
      click: () => {
        router.push({ name: 'leadott-igenyek' });
      },
      roles: ['all'],
    },
    {
      text: 'innerSideNav.massUpload',
      icon: 'mdi-table-arrow-up',
      click: () => {
        router.push({ name: 'csv-feltoltes' });
      },
      roles: ['FLEET', 'DISTRIBUTOR', 'STORE_01', 'RETAILER', 'OTHER_01'],
    },
    {
      text: 'innerSideNav.addressInformation',
      icon: 'mdi-truck-fast-outline',
      click: () => {
        router.push({ name: 'cimek-kezelese' });
      },
      roles: ['all'],
    },
    {
      text: 'innerSideNav.userManual',
      icon: 'mdi-help-box',
      click: () => {
        router.push({ name: 'dolgozoi-kisokos' });
      },
      roles: ['EMPLOYEE'],
    },
    {
      text: 'innerSideNav.appleWorksheet',
      icon: 'mdi-apple',
      click: () => {
        router.push({ name: 'apple-munkalap' });
      },
      roles: ['EMPLOYEE'],
      ura: 'APPLE',
    },
    {
      text: 'innerSideNav.lgWorksheet',
      icon: 'mdi-account-hard-hat',
      click: () => {
        router.push({ name: 'lg-munkalap' });
      },
      roles: ['EMPLOYEE'],
      ura: 'LG',
    },
    {
      text: 'innerSideNav.mieleWorksheet',
      icon: 'mdi-fridge',
      click: () => {
        router.push({ name: 'miele-munkalap' });
      },
      roles: ['EMPLOYEE'],
      ura: 'MIELE',
    },
    /* {
      text: 'innerSideNav.billingInformation',
      icon: 'mdi-file-account-outline',
      click: () => {
        router.push({ name: 'szamlazasi-adatok' });
      },
    },
    {
      text: 'innerSideNav.shippingInformation',
      icon: 'mdi-truck-fast-outline',
      click: () => {
        router.push({ name: 'szallitasi-adatok' });
      },
    }, */
    /* {
      text: 'innerSideNav.messages',
      icon: 'mdi-email-outline',
      click: () => {
        router.push('/iranyitopult/uzenetek');
      },
    }, */
    {
      text: 'mainNavBar.logout',
      icon: 'mdi-logout-variant',
      click: () => {
        store.dispatch('Logout');
      },
      roles: ['all'],
    },
  ],
};

const getters = {
  //
};

const actions = {
  //
};
const mutations = {
  //
};
export default {
  state,
  actions,
  mutations,
  getters,
};
