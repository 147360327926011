<template>
  <v-app>
    <component :is="layout">
      <router-view/>
    </component>
  </v-app>
</template>

<script>
import { getSystemParam } from 'src/utils/systemParameters';
import Vue from 'vue';

const defaultLayout = 'outer';
export default {
  async beforeMount() {
    // console.log('app vue');
    // const resp = await getSystemParam('SAFETY_MODE');
    // console.log('resp:', resp);
    // if (resp.status === 200) {
    // Vue.prototype.$SAFETY_MODE = resp.data.value;
    // this.$store.commit('activeSafetyMode', resp.data.value);
    // }
  },
  updated() {
    this.setLayout();
  },
  async mounted() {
    await this.$store.dispatch('GetSystemInfo', { code: this.$CODE });
  },
  data() {
    return {
      layout: null,
    };
  },
  methods: {
    setLayout() {
      this.layout = `${this.$route.meta.layout || defaultLayout}-layout`;
    },
  },
  /* computed: {
    layout() {
      return `${this.$route.meta.layout || defaultLayout}-layout`;
    },
  }, */
};
</script>
<style>
  /*h1, h2, h3 ,h4, h5, h6 {
    color: var(--v-secondary-base);
  }*/
</style>
