<template>
  <div v-frag>
    <v-row justify="center" class="py-6">
      <v-img :src="`${publicPath}images/brand-logos/tmx-logo-h100.png`" max-width="160px" @click="onLogoClick"/>
    </v-row>
    <v-list dense>
      <v-list-item @click="onLogoClick">
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('mainNavBar.home') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <template v-for="(item, index) in $store.state.mainMenu.mainMenu">
        <template v-if="item.children">
          <v-list-group :key="index" v-if="item.safetyMode === 0">
            <template v-slot:activator>
              <v-list-item-title>
                {{ $t(`${item.text}`) }}
              </v-list-item-title>
            </template>
            <template v-for="(child, index) in item.children">
              <v-list-item :key="index"
                           @click="child.click()"
                           v-if="child.safetyMode === 0"
                           v-show="!(item.text === 'mainNavBar.jobOffers' && $i18n.locale !== 'hu-HU')">
                <v-list-item-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t(`${child.text}`) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
        <template v-else>
          <v-list-item :key="index" @click="item.click()" v-if="item.safetyMode === 0">
            <v-list-item-content>
              <v-list-item-title>
                {{ $t(`${item.text}`) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
      <v-list-item v-if="!$store.state.authModule.isAuthenticated" @click="$router.push({name: 'login'})">
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('mainNavBar.login') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="$store.state.authModule.isAuthenticated" @click="$router.push({name: 'iranyitopult'})">
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('personalSite') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="$store.state.authModule.isAuthenticated" @click="onLogoutClick">
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('mainNavBar.logout') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { TMX_URL } from 'src/utils/config';

export default {
  name: 'SideNavBar',
  methods: {
    onLogoClick() {
      window.open(`${TMX_URL}`, '_self');
    },
    onLogoutClick() {
      this.$store.dispatch('Logout');
    },
  },
};
</script>

<style scoped>

</style>
