export default {
  mainNavBar: {
    home: 'Home',
    aboutUs: 'About us',
    introduction: 'Introduction',
    competitions: 'Competitions',
    stores: 'Stores',
    services: 'Services',
    career: 'Career',
    generalInformations: 'General informations',
    jobOffers: 'Job offers',
    worksheets: 'Worksheets',
    partners: 'Partners',
    contact: 'Contact',
    mobilfutar: 'Mobilfutar',
    buyback: 'BuyBack',
    tracking: 'Tracking',
    request: 'Request',
    login: 'Login',
    logout: 'Logout',
    registration: 'Registration',
    forgotPassword: 'Forgot password',
  },
  innerSideNav: {
    home: 'Home',
    userInformation: 'User information',
    allOrders: 'All ordersation',
    allClaims: 'All claimsation',
    billingInformation: 'Billing information',
    shippingInformation: 'Shipping information',
    addressInformation: 'Address information',
    massUpload: 'Mass upload',
    messages: 'Messages',
    userManual: 'Manual',
    lgWorksheet: 'LG worksheets',
    appleWorksheet: 'Apple worksheets',
    exit: 'Exit',
  },
  excel: {
    downloadSamples: 'Download samples',
    uploadFiles: 'Upload files',
    downloadSampleCSV: 'Download sample',
    downloadSampleEXCEL: 'Download sample',
    downloadExcelHelper: 'Download excel helper',
    acceptFiles: 'Accept files: .csv, .xls, .xlsx',
    errorMessages: 'There are some errors in the uploaded file',
  },
  serviceCalculator: 'Service calculator',
  mostServiceCalculator: 'MOST Service calculator',
  orderForm: 'Order form',
  imprint: 'Imprint',
  login: 'Login',
  loginConfirm: 'Login confirmation',
  twoStepLogin: 'Two step login',
  registration: 'Registration',
  email: 'E-mail',
  password: 'Password',
  passwordConfirm: 'Password confirmation',
  oldPassword: 'Old password',
  newPassword: 'New password',
  newPasswordConfirm: 'New password confirmation',
  forgotPassword: 'Forgot password',
  addNewPassword: 'Add new password',
  checkPassword: 'Check password',
  accountInformation: 'Account information',
  accountLanguage: 'Account language',
  accountCurrency: 'Account currency',
  accountPayment: 'Account payment',
  primaryContactInformation: 'Primary contact information',
  firstName: 'First name',
  lastName: 'Last name',
  phoneNumber: 'Phone number',
  actions: 'Actions',
  search: 'Search',
  damageNumber: 'Claim number',
  invoice: 'Invioce',
  warrantyTicket: 'Warranty ticket',
  evidence: 'Evidence',
  brand: 'Brand',
  productType: 'Type',
  deviceType: 'Product type',
  deviceIdentifier: 'Device identifier (IMEI, SN)',
  imeiSn: 'IMEI/SN',
  dateOfPurchase: 'Date of Purchase',
  troubleDescription: 'Trouble Description',
  otherDescription: 'Other description',
  receptionAddress: 'Reception address',
  newReceptionAddress: 'New reception Address',
  transmissionAddress: 'Transmission address',
  newTransmissionAddress: 'New transmission Address',
  invoicingAddress: 'Invoicing address',
  billingAddresses: 'Billing adress',
  newInvoicingAddress: 'New invoicing Address',
  shippingAddress: 'Shipping address',
  deliveryAndReceiptAddress: 'Delivery and Receipt Address',
  costumerAddress: 'Ügyfél cím',
  shippingAddresses: 'Shipping adresses',
  newShippingAddress: 'New shipping Address',
  shippingAndInvoicingAddress: 'Shipping and invoicing Address',
  invoicingName: 'Invoicing nameation',
  billingName: 'Billing nameation',
  dateOfPickUp: 'Date of pick Up',
  dateOfTransmission: 'Date of Transmission',
  country: 'Country',
  city: 'VáCityros',
  postalCode: 'Postal Code',
  publicPlaceName: 'Public place Name',
  publicPlaceType: 'Public place Type',
  streetNumber: 'Street  Number',
  status: 'Status',
  contactIsTheSame: 'Contact is the Same',
  transmissionDataIsSameAsReceptionData: 'Transmission adress is same as reception adress.',
  invoicingDataIsSameAsReceptionData: 'Invoicing adress is same as reception adress.',
  invoicingDataIsSameAsTransmissionData: 'Invoicing adress is same as transmission adress.',
  invoicingAddressIsEqualShippingAddress: 'Invoicing adress is same as shipping adress.',
  invoicingDataIsOther: 'Invoicing data is Other.',
  loadingText: 'Loading data…Please wait!',
  pickUpAndReturn: 'Pick up and return',
  welcomeText: 'Welcome to the TMX Pick up and return website!',
  findOutMoreAboutOurServices: 'Find out more about our services',
  warrantyRepairForm: 'Warranty repair form',
  oufOfWarrantyRepairForm: 'Ouf of warranty repair form',
  repairForm: 'Repair form',
  tracking: 'Tracking',
  documents: 'Documents',
  documentUpload: 'Document upload',
  contactName: 'Contact name',
  serviceRequestId: 'Service request Id',
  addressDetails: 'Address details',
  vatNumber: 'Vat number',
  quoteDone: 'Quotation done',
  exportReportDone: 'Export report done',
  mostmobil: 'Mostmobil.hu service warranty',
  de3000: 'DE-3000 shop',
  requestIdOrWorksheetNumber: 'Request id or worksheet number',
  accountRegistration: 'Account registration',
  accountRegistrationQuestionOnForm: 'Would you like to register a user account? With user account, you can track easier your ordered services.',
  acceptCheckbox: {
    generalInformation: 'I read and accept the upper informations.',
  },
  hasActiveQuoteBox: {
    title: 'Do you accept the quote?',
    desc: 'Our quote is valid for 5 working days. Please answer within the deadline, otherwise we will delete your order!',
    dowloadSentenceText: 'Here you can see your quote.',
    dowloadSentenceLinkText: 'You can see it Here',
  },
  hasActiveExpertReportBox: {
    title: 'Do you accept the expert report?',
    desc: 'Our expert report is valid for 5 working days. Please answer within the deadline, otherwise we will delete your order!',
    dowloadSentenceText: 'Here you can see your expert report.',
    dowloadSentenceLinkText: 'You can see it Here',
  },
  forgotPasswordReminderText: 'Please enter your registered e-mail adress in the upper field.',
  passwordRuleGeneral: 'Min. 8 characters. It must be contains a letter, upper and lowercase.',
  registrationInfo: 'In the lower field, please enter your password. You can login your user account with this password. Your login e-mail adress will be what you entered in your costumer profile.',
  greetings: 'Greetings',
  unreadMessages: 'Unread messages',
  readMessages: 'Read messages',
  personalSite: 'Personal site',
  flottakezeles: 'For business partners',
  dolgozoknak: 'For employees',
  priceCalculation: 'Price calculation',
  buyBack: 'Buyback',
  program: 'Program',
  estimatedServicePrice: 'Estimated service and delivery price',
  askForQuote: 'Ask for quote!',
  arriveToService: 'Arrive to service',
  referenceNumber: 'Reference number',
  quotePrice: 'Price of the quote',
  button: {
    send: 'Send',
    newOrder: 'New order',
    newAddress: 'New address',
    changePassword: 'Change password',
    deleteProfile: 'Delete Profile',
    cancel: 'Cancel',
    view: 'View',
    select: 'Select',
    next: 'Next',
    back: 'Back',
    imeiValidate: 'Imei validation',
    save: 'Save',
    edit: 'Edit',
    download: 'Download',
    upload: 'Upload',
    validate: 'Validate',
    calculate: 'Calculate',
    orderTheService: 'I order the service',
    interestedInOffer: 'I\'m interested in the offer',
    askForQuote: 'I ask for a quote',
    acceptQuote: 'Accept the quote',
    acceptQuotes: 'Accept the quotes',
    rejectQuote: 'Reject the quote',
    rejectQuotes: 'Reject the quotes',
    quoteAcceptText: 'I accept the expert report',
    quoteRejectText: 'I reject the expert report',
    /*expertReportAcceptText: 'Elfogadom a szakvéleményt',
    expertReportRejectText: 'Elutasítom a szakvéleményt',*/
    expertReportAcceptText: 'I request a Quote',
    expertReportRejectText: 'I do not request a Quote',
    goToLogin: 'Go to login',
    goToDashboard: 'Back to My Page',
    backToMyAddresses: 'Back to the registered addresses',
    continueWithoutReg: 'Continue  without registration',
    continueWithReg: 'Continue with registration',
    downloadInvoice: 'Download invoice',
    onlinePayment: 'Online payment',
    generate: 'Generate',
    refresh: 'Refresh',
    print: 'Print',
  },
  inputMessages: {
    requiredField: 'Required field',
    maxCharacter: 'Max 3000 characters',
    optionalField: 'Optional field',
    passwordRule: 'Min. 8 characters. It must be contains a letter, upper and lowercase.',
    passwordRuleMin8: 'It must be minimum 8 characters!',
    passwordNotMatch: 'Password not match',
    searchHint: 'With typing, you can search any datas from the table.',
    acceptInformation: 'To complete your request, you must be accept the information and the ...',
    maxFileSizeRule: 'The uploaded file size might be max. 5 MB!',
    maxFileSizeRuleOptional: 'Optional! The maximum upload file size is 5 MB!',
    emailMatch: 'Please enter a valid e-mail adress!',
    wrongCharacter: 'Incorrect character in email address',
    onlyNumbers: 'Please enter only numbers!',
    contactIsTheSameHelp: 'If you are a contact person for this adress, please tick this checkbox.',
    requiredPrivacy: 'For send the form, you must accept the Privacy information!',
    requiredTermsAndCond: 'For send the form, you must accept the General terms and conditions!',
    toFewCharacters: 'You entered too few characters!',
    damageNumberNotMatch: 'Claim numbers starts with AS or VF characters and contains 7 more character.',
    startTypingPostalCode: 'With typing, you can search for postal codes.',
    startTypingCity: 'With typing, you can search for cities.',
    startTypingStreet: 'With typing, you can search for streets.',
    noDataText: 'There is no result found!',
    snRules: 'English lowercase/capital, numbers, "-" and "/" characters',
    minLenght15: 'Required 15 characters!',
    maxLenght15: 'Maximum length is 25 characters!',
    purchaseDateMax: 'Date cannot be later, than',
    purchaseDateReq: 'Mandatory to fill! (example 2020-03-07)',
    purchaseDateOptional: 'Optional field! Pl. 2020-03-07',
    insuranceNumberRule: 'It cannot contain space and special characters!',
    emailNoSpace: 'The e-mail address cannot contain space!',
    requiredFieldRequestId: 'Kötelező kitölteni! GSM munkalapszám vagy igényazonosító',
  },
  order: {
    orderId: 'Order Id',
    orderDate: 'Order date',
    orderStatus: 'Order status',
    orderPlaceNewOrder: 'Place new order',
    orderDetails: 'Order details',
  },
  request: {
    requestDetails: 'Request details',
    requestData: 'Request data',
  },
  serviceRequestHistory: {
    RECEIVED: 'Received order',
    DELIVERY_IN_ORDERED: 'Order in delivery',
    ARRIVED: 'Device arrived in the service',
    QUOTE_DONE: 'Quote done',
    QUOTE_ACCEPTED: 'Quote accepted',
    QUOTE_REJECTED: 'Quote rejected',
    PRODUCT_LEFT_THE_SERVICE: 'Product left the service',
    INVOICE_DONE: 'Invoice done',
    DELIVERY_OUT_ORDERED: 'Delivery out is ordered',
    SHIPPED: 'Shipped',
    SERVICE_DONE: 'Device left from the service',
  },
  shipping: {
    pickupAddress: 'Pick up adress',
    shippingAddress: 'Shipping address',
  },
  services: {
    iw: 'In-warranty repair',
    vodaiw: 'In-warranty repair',
    oow: 'Out of warranty repair',
    sw: 'Service warranty',
    asu: 'Insurance service warranty',
    asuw: 'Insurance service warranty',
    asuw2: 'Insurance service warranty',
    swPickup: 'Service warranty pick up and return',
    garancia: 'Insurance service warranty pick up and return',
    lenovo: 'Lenovo pick up and return',
    asus: 'Asus pick up and return',
    xiaomi: 'Xiaomi pick up and return',
    gopro: 'Gopro pick up and return',
    flottakezeles: 'For business partners',
    simpleRepair: 'Simple repair order',
    insuranceService: 'Insurance service warranty',
    mostmobil: 'Mostmobil.hu service warranty',
    telekomService: 'For Telekom Customers',
    telenorService: 'For Yettel Customers',
    vodafoneService: 'For Vodafone Customers',
  },
  stepper: {
    generalDetails: 'General details',
    deviceDetails: 'Device details',
    customerDetails: 'Customer details',
    confirmation: 'Confirmation',
    done: 'Done',
  },
  ERROR_MESSAGES: {
    ERROR_IMEI: 'The IMEI number that you have entered doesn\'t fit with the Luhn-algorithm. Please check the IMEI number you have entered. If you have entered serial number, please take this message objectless.',
    REQUEST_SEND_1: '<div class="display-1 mb-6"><b>Unexpected error!</b></div><div class="headline mb-4">We are working on solving the problems.</div>',
    REQUEST_SEND_2: '<div class="display-1 mb-6"><b>Connection lost!</b></div><div class="headline mb-4">We are working on solving the problems.</div>',
    ONE_FILE_IS_REQUIRED: 'For In Warranty handling, please attach a copy of the invoice and/or validated Warranty card.',
    FILE_NOT_VALID: 'The file you trying to upload has an error! The file should be a .pdf extension and the size must be larger than 0 kB! Please upload another file!',
    INVALID_DATE: 'The purchase date is invalid. Please input the valid purchase date!',
    INSURANCE_NUMBER_VALIDATION: '<div class="display-1 mb-6"><b>Invalid claim number!</b></div><div>Kérjük keresse bizosítóját a kárszámmal kapcsolatosan!</div>',
    USER_EXISTS: '<div class="display-1 mb-6"><b>Submission unsuccessful!</b></div><div class="headline mb-4">You have already registered a user account with the email address you provided.</div><div>Please log in to your account and click on the new order button to submit your repair request for faster, more convenient and last but not least, safer administration.</div>',
  },
  SUCCESS_MESSAGES: {
    SUCCESS_IMEI: 'IMEI validation succes.',
    REQUEST_SEND: '<div class="display-1 mb-6"><b>Submission success!</b></div><div class="headline mb-4">Kérése megérkezett hozzánk! Az űrlapon megadott e-mail címére hamarosan visszaigazoló e-mailt küldünk!</div><div>Köszönjük megkeresését!</div>',
    USER_EXISTS: '<div class="display-1 mb-6"><b>Submission unsuccessful!</b></div><div class="headline mb-4">This e-mail address is already registered!</div><div>Your request can be handle after you <b>Login</b> with your registrated e-mail.</div><div>Thank You!</div>',
    INSURANCE_NUMBER_VALIDATION: '<div class="display-1 mb-6"><b>Insurance number validation succes!</b></div>',
  },
  warrantyRepair: 'Warranty repair',
  warrantyRepairs: 'Warranty repair',
  payedRepair: 'Out of warranty repair',
  tmxGeneralInformation: '<p>Tisztelt Ügyfelünk!</p>'
    + '<p>Please read our data management policy carefully ((http://www.tmx.hu/adatvedelmi-szabalyzat)). By filling the information below, we consider our privacy policy to be accepted.</p>'
    + '<p>If you have any questions about repairing or replacing your device, please read our warranty repair information carefully.</p>'
    + '<p>If you request a warranty repair and accept the terms of the warranty repair, please provide the information below. Requests received before 2:30 p.m. can be fulfilled the next business day.</p>'
    + '<p>Expected repair time: may take 7-10 business days upon receipt (depending on device type)</p>'
    + '<p><b> Warranty Terms and Conditions for Enforcing Warranty Rights:</b></p>'
    + '<ul class="mb-4"><li>The warranty period is 6 (six) months.</li>'
    + '<li>The warranty period begins with the delivery of the device to the consumer or his/her agent after the service has been performed.</li>'
    + '<li>Claims can only be made in writing, by e-mail, indicating the warranty card number.</li></ul>'
    + '<p><b>Other Terms:</b></p>'
    + '<ul class="mb-4"><li>In case of a legitimate request, the service will collect the device to be repaired.</li>'
    + '<li> Please do not send the SIM and Memory card with the device as we cannot be held responsible for it. </li>'
    + '<li> <b> Please include the Warranty Card you received with your device. </b> </li>'
    + '<li> During the repair of the device, the data stored on the device may be lost, that we accept no responsibility. </li>'
    + '<li> The warranty does not affect the consumer\'s statutory rights under applicable legislation in force, including the product warranty and guarantee rights. </li>'
    + '<li> Other terms and conditions of the warranty are contained in the current government decree entitled "Government Decree on Compulsory Warranty for Certain Repair and Maintenance Services". </li></ul>'
    + '<p> <b> Product Warranty and Warranty Rights </b> </p>'
    + '<p> Refers to the repair and maintenance service provided by TMX Mobile Solution Szerviz Kft., which is limited to the repair of the "Work performed, main used parts" indicated in the warranty card. </p>'
    + '<p> The enforceability of the product warranty and guarantee rights related to the device is not affected by the fact that TMX Mobile Solution Szerviz Kft. has performed repair and maintenance services on the device, which means that a warranty responsibility of the distributor or the device manufacturer remains unchanged and valid. </p>'
    + '<p> It follows from the above that in case of other failures of the device not related to the repair and maintenance service performed by TMX Mobile Solution Szerviz Kft., the device can be repaired according to the warranty and guarantee conditions provided by the manufacturer or distributor under the terms of the warranty. </p>'
    + '<p> <b> Reasons which excludes Service Warranty: </b> </p>'
    + '<ul class="mb-4"> <li> occurrence of a new damage event (eg.: fracture, contact with any liquid). Please contact the Insurer on 06/80 30 0030. </li> </ul>'
    + '<p> Service warranty repair is free of charge. </p>'
    + '<p> In the case that the service issues a "No fault found" statement, or the fault is not detected on the device, or the warranty is rejected (eg external mechanical impact on the device, (breakage) or unauthorized repair), the delivery cost must be paid by the Customer, which is HUF 4,522, which must be settled upon receipt of the device.  </p>'
    + '<p> For Apple devices, the customer needs to turn off the Find My Iphone (FMI) feature. If you are unable to resolve this feature, Apple Customer Service can help you at 0680/983 824. . </p>'
    + '<p> Please note that the FMI shutdown time is not included in the repair time. </p>',
  inputLabels: {
    companyName: 'Company name',
    firstName: 'First name',
    middleName: 'Middle name',
    lastName: 'Last name',
    email: 'E-mail',
    phone: 'Phione numbert',
    name: 'Name',
    topic: 'Topic',
    manufacturer: 'Manufacturer ',
    imei: 'Device identifier (IMEI/SN)',
    subject: 'Subject',
    message: 'Message',
    field: 'Field',
    job: 'Job',
    cv: 'CV',
    otherDoc: 'Other doc',
    productType: 'Product type',
    warrantyType: 'Warranty type',
    dateOfPurchase: 'Date of purchase',
    troubleDescription: 'Trouble description',
    simLock: 'Sim lock',
    country: 'Country',
    postalCode: 'Postal code',
    city: 'City',
    publicPlaceName: 'Public name',
    publicPlaceType: 'Public type',
    publicPlaceNameAndType: 'Public name and type',
    streetNumber: 'Street number',
    other: 'Other',
    invoice: 'Invoice',
    warrantyTicket: 'Warranty ticket',
    damageNumber: 'Claim number',
    serviceRequest: 'Service request',
    serviceRequests: 'Service requests',
    calculatedPrice: 'Calculated repair and delivery price',
    serviceRequestId: 'Service request id',
    rma: 'RMA number',
    productGroup: 'Product group',
    uniqueIdentifierIn1: 'Unique id 1',
    uniqueIdentifierIn2: 'Unique id 2',
    uniqueIdentifierIn3: 'Unique id 3',
    uniqueIdentifierIn1new: 'Device ID 1 (IMEI/SN)',
    uniqueIdentifierIn2new: 'Device ID 2 (IMEI/SN)',
    uniqueIdentifierIn3new: 'Device ID 3 (IMEI/SN)',
    accountRegistrationTrue: 'Yes, i would like to register an account.',
    accountRegistrationFalse: 'No, i don\'t like to register an account.',
    password: 'Password',
    selectShippingAddress: 'Select delivery address',
    selectBillingAddress: 'Select invoice address',
    serviceRequestIdentifier: 'Service id',
    origRequestSource: 'Website',
    selectFilesToUpload: 'Select files to upload',
    deviceColor: 'Device color',
    businessFormName: 'Business form',
    paymentMethods: 'Payment method',
    accountNumber: 'Bank Account Number',
    taxNumber: 'Tax number',
    imei1: 'IMEI1',
    imei2: 'IMEI2',
    serialNr: 'Serial number - SN',
    purchaseInvoice: 'Proof of purchase invoice',
    purchaseWarrantyTicket: 'Warranty ticket received on purchase',
    serialNumber: 'Serial number',
  },
  insuranceRepairForm: 'Insurance repair order',
  introduction: 'Introduction',
  ourServices: 'Our services',
  whyChooseUs: 'Why choose us?',
  ourPartners: 'Our partners',
  generalRequirements: 'General requirements',
  seeJobOffers: 'See our actual offers',
  complexServiceForOurPartners: 'Complex services for our Partners',
  centralEuropeSuperGrowingService: 'Continously growing service in Central Europe.',
  whereCanYouFindUs: 'Where can you find us?',
  sendUsAMessage: 'Send us a message',
  ourEmail: 'Our e-mail adress',
  ourPhoneNumber: 'Our phone number',
  ourStores: 'Our stores',
  serviceProducts: 'Service products',
  impress: 'Imprint',
  nameOfTheCompany: 'Company\'s name',
  headquarter: 'Headquarter',
  headquarterOfTheCompany: 'Company\'s headquarter',
  sitesOfTheCompany: 'Company\'s sites',
  postalAddressOfTheCompany: 'Company\'s postal adress',
  phoneNumberOfTheCompany: 'Company\'s phone number',
  telefaxNumberOfTheCompany: 'Company\'s telefax number',
  emailOfTheCompany: 'Company\'s e-mail adress',
  VATNumberOfTheCompany: 'Company\'s vat number',
  webhostDetails: 'Webhost name and data details',
  complaintPolicy: 'Complaint policy',
  privacyPolicy: 'Privacy policy',
  deliveryService: 'Futárszolgálat',
  deliveryServiceText: 'A futárszolgálat lehetőséget választva a javítási igény leadását követően a szerviz az 1. pontban megadott címre küldi a futárt. A készülék begyűjtéséről legkésőbb a begyűjtés napján SMS / email / telefonhívás formájában tájékoztatja a futár.',
  foxpost: 'Foxpost',
  foxpostText: 'A csomagot a kiválasztott Foxpost csomagautomatában tudja feladni részünkre, begyűjtési igényének feldolgozását követően az SMS-ben / emailben kapott nyitókód megadásával.',
  termsAndCond: 'General terms and conditions',
  usedEquipmentForSale: 'Used devices sale - General terms and conditions',
  imeiHint: 'Type *#06# code in your phone to check it.',
  imeiHintOptional: 'Optional! You can check it by dialing *#06#',
  checkSpamFolder: 'After you succesfully place order, we will send an e-mail to your e-mail adress. Please check you SPAM folder if you don\'t find it.',
  moreInfosAboutTheService: 'Information about service',
  imeiError: 'The IMEI number that you have entered doesn\'t fit with the Luhn-algorithm. Please check the IMEI number you have entered. If you have entered serial number, please take this message objectless.',
  attachDocs: 'Please attach the require documents if you can\'t send it with your device.',
  generalServiceInfoText: '<p>Tisztelt Ügyfelünk!</p>'
    + '<p>Kérjük válasszon az alábbi két lehetőség közül, majd adja meg a kért adatokat, hogy futárszolgálatunk be tudja gyűjteni a szervizelendő készülékét.</p>'
    + '<p>Amennyiben Apple készüléket szeretne beküldeni kérjük az FMI (Find My iPhone) funkciót kapcsolja ki mielőtt átadja a futárnak a terméket. Szervizünk addig nem tudja a javítást megkezdni, amíg ez a funkció aktív.</p>'
    + '<p>Tájékoztatjuk, hogy gyártói előírások értelmében, amennyiben mechanikai sérülés okozta a készülék hibáját (pl: vízbe esett, leesett, betört a kijelző, sérült a burkolat, stb), ezt jótállási keret között nem tudjuk javítani, kérjük ilyen esetben lenti két opcióból válassza a Fizetős javítást.</p>'
    + '<p>A jótállási keretein belül történő javítás esetén, kérjük küldje be készüléke mellett az érvényes jótállási jegyet és/vagy a számla másolatát.</p>'
    + '<p>Amennyiben bármi kérdése lenne, kérjük írjon nekünk az info@tmx.hu e-mail címre.</p>'
    + '<p></p>'
    + '<p></p>'
    + '<p>TMX Mobile Solution Szerviz Kft.</p>',
  loremSentence: 'Aenean eget risus urna',
  loadingMessages: {
    INPROGRESS_CONTACT_FORM: 'Sending in progress. Please wait.',
    INPROGRESS_RESETPASSWORD: 'Your request is in progress. Please wait.',
    INPROGRESS_SETPASSWORD: 'Setting the new password is in progress. Please wait.',
    INPROGRESS_CSVUPLOADING: 'File uploading in progress. Please wait.',
    INPROGRESS_DOCUPLOADING: 'Document uploading in progress. Please wait.',
    INPROGRESS_FORMLOADING: 'The form is loading.',
  },
  documentTypes: {
    warrantyCardHash: 'Submitted warranty card',
    invoiceHash: 'Submitted invoice',
    faultReportHash: 'Fault Report',
    handoverDocumentHash: 'Handover Document',
    gsmQuotationHash: 'GSM Quotation',
    dolphinInvoiceHash: 'Dolphin Invoice',
    gsmRepairWorksheetHash: 'GSM Repair Worksheet',
  },
  noDataText: {
    productGroups: 'There is no product group for selected manufacturer!',
    general: 'There is no data to show.',
    noRequestFound: 'Request not found.',
    noSearchResult1: 'No results found for',
    noSearchResult2: '.',
  },
  szervizkalk: {
    buybackIntro: '<p>Veritatis quibusdam quisquam assumenda voluptatem voluptate et dolor. Voluptatibus laudantium eos odio ut impedit qui temporibus quia nulla. Nihil modi excepturi iste. Et quaerat id aut.</p>'
      + '<p>Veritatis quibusdam quisquam assumenda voluptatem voluptate et dolor.</p>',
    soldItToUs: 'Sell it to us for this price.',
  },
  needToLogin: 'You need to login to use this service!',
  customerTypeCantSeeTheContent: 'You have no permission to reach this content.',
  passwordCheck: {
    min1lowercase: 'Min. one lowercase',
    min1uppercase: 'Min. one uppercase',
    min8char: 'Min. 8 characters',
    min1number: 'Min. one number',
    min1special: 'Min. one special character: !@#$%*()_+-={}?',
    passMatch: 'Password match.',
  },
  paymentAndAddressDatas: 'Adress and payment datas',
  financialDatas: 'Financial datas',
  nob2bRefNo: 'There is no reference number',
  noDocumentToShow: 'There is no document to show.',
  reUseRequestDialog: {
    title: 'Service warranty application',
    desc: 'EN - Amennyiben a készülék javításával probléma merült fel és a korábbi javítás után nem telt el hat hónap, úgy a tovább gomb megnyomásával az űrlapon a hiányzó adatok kitöltésével igényelhető a Szerviz által biztosított garanciális ügyintézés.',
  },
  gsmWorksheetId: 'GSM work ticket number',
  downloadFileErrorDialog: {
    title: 'Error',
    noFile: 'The selected document is not found. Please try later!',
  },
  loginOrReg: 'Login',
  $vuetify: {
    badge: 'Badge',
    close: 'Close',
    dataIterator: {
      noResultsText: 'No matching records found',
      loadingText: 'Loading items...',
    },
    dataTable: {
      itemsPerPageText: 'Rows per page:',
      ariaLabel: {
        sortDescending: 'Sorted descending.',
        sortAscending: 'Sorted ascending.',
        sortNone: 'Not sorted.',
        activateNone: 'Activate to remove sorting.',
        activateDescending: 'Activate to sort descending.',
        activateAscending: 'Activate to sort ascending.',
      },
      sortBy: 'Sort by',
    },
    dataFooter: {
      itemsPerPageText: 'Items per page:',
      itemsPerPageAll: 'All',
      nextPage: 'Next page',
      prevPage: 'Previous page',
      firstPage: 'First page',
      lastPage: 'Last page',
      pageText: '{0}-{1} of {2}',
    },
    datePicker: {
      itemsSelected: '{0} selected',
      nextMonthAriaLabel: 'Next month',
      nextYearAriaLabel: 'Next year',
      prevMonthAriaLabel: 'Previous month',
      prevYearAriaLabel: 'Previous year',
    },
    noDataText: 'No data available',
    carousel: {
      prev: 'Previous visual',
      next: 'Next visual',
      ariaLabel: {
        delimiter: 'Carousel slide {0} of {1}',
      },
    },
    calendar: {
      moreEvents: '{0} more',
    },
    fileInput: {
      counter: '{0} files',
      counterSize: '{0} files ({1} in total)',
    },
    timePicker: {
      am: 'AM',
      pm: 'PM',
    },
    pagination: {
      ariaLabel: {
        wrapper: 'Pagination Navigation',
        next: 'Next page',
        previous: 'Previous page',
        page: 'Goto Page {0}',
        currentPage: 'Current Page, Page {0}',
      },
    },
    rating: {
      ariaLabel: {
        icon: 'Rating {0} of {1}',
      },
    },
  },
  homeWelcomeText: '<p class="display-1 text-center">Welcome! Our new services are going to start soon!</p>'
    + '<p class="text-center">Further information at info@tmx.hu.</p>',
  day: 'day',
  hour: 'hour',
  min: 'min',
  sec: 'sec',
  kisokosTitle: 'Employee request guide',
  kisokosSubTitle: 'Guide',
  szervizgar: {
    vodafoneGeneralInformation: '<p>Dear Customer,</p>' +
      '<p>The Requests that received before 2.30 pm, can be fulfilled the soonest on the next working day.</p>' +
      '<p>For Apple devices, it is up to the customer to turn off the Find My Iphone feature. If you are unable to resolve this feature, please contact Apple Customer Support for assistance.</p> ' +
      '<p>Please note that the FMI deactivation time is not included in the repair time.</p>',
    generalInformation: '<p>Dear Customer,</p>' +
      '<p>Our insurance service warranty  is available after a previous insurance event.</p>' +
      '<p>The Requests that received before 2.30 pm, can be fulfilled the soonest on the next working day.</p>' +
      '<p>The reason for excluding the service guarantee is the occurrence of a new damage event (eg.: breakage, liquid damage, unauthorized hardware / software tampering). In these cases, please contact your insurer.</p> '+
      '<p>For Apple devices, it is up to the customer to turn off the Find My Iphone feature. If you are unable to resolve this feature, please contact Apple Customer Support for assistance.</p> ' +
      '<p>Please note that the FMI deactivation time is not included in the repair time.</p>',
  },
  regIsBetterText: '<p class="text-center font-18">You have chosen to make an order without registeration of an account.</p><p class="text-center font-18"> We would like to draw your attention to the benefits of a registered account: Faster and more convenient administration , detailed order list and history, automatic form for new orders</p>',
  loginWelcomeText: 'To track your repar, please enter the requested data.',
  toRegUsers: 'For users with registered account:',
  toReguserText: 'You can view your personal account <a href="/bejelentkezes" style="color: #FFFFFF;"><b>here.</b></a>',
  xiaomiRepairText: '<p class = "title text-center">For further information about the commercial terms of the Covid 19 warranty, please visit the <a href="https://www.mi.com/global/service/warranty" target="_blank">https://www.mi.com/global/service/warranty</a> website.</p>',
  invoiceOnlinePayment: 'Pay online',
  pending: 'Pending',
  payed: 'Paid',
  invoiceNumber: 'Invoice number',
  invoiceCreatedAt: 'Invoice date',
  invoiceGrossPrice: 'Invoice amount',
  paymentMethod: 'Payment method',
  paymentLoading: 'The payment confirmation in progress.',
  dontCloseTheBrowser: 'Please do not close your Browser.',
  paymentSuccess: 'Payment Successful!',
  paymentFailed: 'Payment failed!',
  paymentCanceled: 'Payment aborted.',
  paymentSorry: 'We are sorry that you can not accomplish the online payment',
  paymentSorryAuth: 'Click the button below to return to your submitted claims.',
  paymentSorryNotAuth: 'Click the button below to return to the Homepage.',
  generateRandomImei: 'If you don\'t know this identifier please click on the Generate button.',
  vodafone: {
    homePageSubtitle: 'Order repairs',
    warrantyRepairForm: 'Warranty repair form',
  },
  cookies: 'Cookie settings',
  dearCustomes: 'Dear Customer!',
  docTmxTracking: 'documents.tmx.hu',
  documentViewer: {
    loginInProgress: '<div class="body-1">Login in progress</div>',
    succAuth: '<div class="title mb-6 font-weight-bold">Successful authentication!</div><div class="body-1">Confirmation e-mail has been sent to your e-mail address.</div>',
    failedAuth: '<div class="title mb-6 font-weight-bold">Failed authentication!</div><div class="body-1">Please check the information you entered!</div>',
    inprogressAuth: '<div class="body-1">Authetication in progress</div>',
    noCloseBrowser: 'Please do not close your browser.',
    loadingData: 'Loading the data in progress. Please wait!',
    loadingFailed: 'An error has occured, loading the data has failed.<br>Please click on the refresh button.',
  },
};
