import Vue from 'vue';

export default Vue.mixin({
  data() {
    return {
      //
    };
  },
  methods: {
    async randomImei(type) {
      const randomIdentifier = await this.$store.dispatch('randomImeiGenerator');
      // console.log(`%crandomIdentifier: ${randomIdentifier} - hossz: ${randomIdentifier.toString().length}`, 'background-color: #00cec9; color: #ffffff; font-size: 12px;');
      if (type === 1) {
        this.serviceRequestForm.serviceRequest.uniqueIdentifierIn1 = randomIdentifier;
      } else if (type === 2) {
        this.serviceRequestForm.serviceRequest.uniqueIdentifierIn2 = randomIdentifier;
      } else if (type === 3) {
        this.serviceRequestForm.serviceRequest.uniqueIdentifierIn3 = randomIdentifier;
      }
    },
  },
})
