import Vue from 'vue';
import Vuex from 'vuex';

import createLogger from 'vuex/dist/logger';

// API hívásokhoz
import deviceManagement from "./deviceManagement";
import generalManagement from "./generalManagement";
import storeManagement from "./storeManagement";
import loginManagement from "./loginManagement";
import mobilProviderManagement from "./mobilProviderManagement";

// Navigációhoz
import innerSideNav from "./innerSideNav.module";
import mainMenu from "./mainMenu.module";

// App adatbázis
import brandManagement from "./brandManagement";
import selectfields from "./selectfield.module";
import services from "./service.module";

// Authentication
import authModule from './auth.module';

// Kalkulátor modul
import calculator from './calculator.module';

import validator from './validators.module';
import payment from './payment.module';

// LG modul
import lgmodul from './lg.module';

// APPLE modul
import apple from './apple.module';

// MIELE modul
import miele from './miele.module.js';

// OWL
import owlStore from 'src/store/owlStore';

// Cím
import addressModule from 'src/store/address.module.js';

import customerModule from 'src/store/customer.module.js';

import systemParams from 'src/store/systemParams.js';

import gsmApi from '@/store/gsmApi.module';

import downloadManager from '@/store/downloadManager.module';

Vue.use(Vuex);

export default new Vuex.Store({
  // plugins: [createLogger()],
  modules: {
    deviceManagement,
    generalManagement,
    storeManagement,
    innerSideNav,
    mainMenu,
    brandManagement,
    selectfields,
    services,
    authModule,
    loginManagement,
    mobilProviderManagement,
    calculator,
    validator,
    payment,
    lgmodul,
    apple,
    owlStore,
    miele,
    addressModule,
    customerModule,
    systemParams,
    gsmApi,
    downloadManager,
  },
});
