import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      meta: { layout: 'outer' },
      redirect: { name: 'igenyles' },
      // redirect: { name: 'coming-soon' },
    },
    /* {
      path: '/coming-soon',
      name: 'coming-soon',
      meta: { layout: 'outer' },
      component: () => import('./views/HomeComingSoon'),
    }, */
    {
      path: '/igenyles',
      name: 'igenyles',
      meta: { layout: 'outer' },
      component: () => import('./views/Home'),
      // redirect: { name: 'coming-soon' },
    },
    {
      path: '/bejelentkezes',
      name: 'login',
      meta: { layout: 'outer' },
      component: () => import('./views/LoginPage'),
    },
    {
      path: '/elfelejtett-jelszo/:vs',
      name: 'elfelejtett-jelszo',
      meta: { layout: 'outer' },
      component: () => import('./views/External/Auth/ForgotPasswordPage'),
    },
    {
      path: '/impresszum',
      name: 'impresszum',
      meta: { layout: 'outer' },
      component: () => import('./views/Impresszum'),
    },
    {
      path: '/generalCosts',
      name: 'generalCosts',
      meta: { layout: 'outer' },
      component: () => import('./views/generalCosts.vue'),
    },
    {
      path: '/nyomonkovetes',
      name: 'bejelentkezes',
      meta: { layout: 'outer' },
      component: () => import('./views/External/Auth/ExternalLoginPage'),
    },
    {
      path: '/bejelentkezes-megerositese',
      name: 'bejelentkezes-megerositese',
      meta: { layout: 'outer' },
      component: () => import('./views/External/Auth/ExternalLoginConfirmPage'),
    },
    {
      path: '/igeny-reszletei/:vs',
      name: 'kulso-igeny-reszletei',
      meta: { layout: 'outer' },
      component: () => import('./views/External/Request/ExternalRequestDetailsPage'),
    },
    {
      path: '/igenyles/gyartok',
      name: 'mobilfutar-gyartok',
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandsListPage'),
    },
    /* // MINTA GYÁRTÓI HOME PAGE
    {
      path: '/igenyles/gyartok/mintaOldal',
      name: 'mintaHome',
      props: { brandId: 99, brandName: 'minta' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandHomePageNew'),
    }, */
    // ALCATEL
    {
      path: '/igenyles/gyartok/alcatel',
      name: 'alcatelHome',
      props: { brandId: 1, brandName: 'alcatel' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandHomePageNew'),
    },
    // APPLE
    {
      path: '/igenyles/gyartok/apple',
      name: 'appleHome',
      props: { brandId: 2, brandName: 'apple' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandHomePageNew'),
    },
    // ASUS
    {
      path: '/igenyles/gyartok/asus',
      name: 'asusHome',
      props: { brandId: 3, brandName: 'asus' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandHomePageNew'),
    },
    {
      path: '/igenyles/gyartok/asus/garancialis-javitasok',
      name: 'garancialis-javitasok-asus',
      props: { brandId: 3, brandName: 'asus' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandWarrantyFormPage'),
    },
    {
      path: '/igenyles/gyartok/asus/fizetos-javitasok',
      name: 'fizetos-javitasok-asus',
      props: { brandId: 3, brandName: 'asus' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage'),
    },
    // CAT
    {
      path: '/igenyles/gyartok/cat',
      name: 'catHome',
      props: { brandId: 4, brandName: 'cat' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandHomePageNew'),
    },
    {
      path: '/igenyles/gyartok/cat/garancialis-javitasok',
      name: 'garancialis-javitasok-cat',
      props: { brandId: 4, brandName: 'cat' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandWarrantyFormPage'),
    },
    {
      path: '/igenyles/gyartok/cat/fizetos-javitasok',
      name: 'fizetos-javitasok-cat',
      props: { brandId: 4, brandName: 'cat' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage'),
    },
    // GOPRO
    {
      path: '/igenyles/gyartok/gopro',
      name: 'goproHome',
      props: { brandId: 5, brandName: 'gopro' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandHomePageNew'),
    },
    {
      path: '/igenyles/gyartok/gopro/garancialis-javitasok',
      name: 'garancialis-javitasok-gopro',
      props: { brandId: 5, brandName: 'gopro' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandWarrantyFormPage'),
    },
    {
      path: '/igenyles/gyartok/gopro/fizetos-javitasok',
      name: 'fizetos-javitasok-gopro',
      props: { brandId: 5, brandName: 'gopro' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage'),
    },
    // IROBOT
    {
      path: '/igenyles/gyartok/irobot',
      name: 'irobotHome',
      props: { brandId: 102, brandName: 'irobot' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandHomePageNew'),
    },
    {
      path: '/igenyles/gyartok/irobot/garancialis-javitasok',
      name: 'garancialis-javitasok-irobot',
      props: { brandId: 102, brandName: 'irobot' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandWarrantyFormPage'),
    },
    {
      path: '/igenyles/gyartok/irobot/fizetos-javitasok',
      name: 'fizetos-javitasok-irobot',
      props: { brandId: 102, brandName: 'irobot' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage'),
    },
    // HONOR
    {
      path: '/igenyles/gyartok/honor',
      name: 'honorHome',
      props: { brandId: 6, brandName: 'honor' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandHomePageNew'),
    },
    {
      path: '/igenyles/gyartok/honor/garancialis-javitasok',
      name: 'garancialis-javitasok-honor',
      props: { brandId: 6, brandName: 'honor' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandWarrantyFormPage'),
    },
    {
      path: '/igenyles/gyartok/honor/fizetos-javitasok',
      name: 'fizetos-javitasok-honor',
      props: { brandId: 6, brandName: 'honor' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage.vue'),
    },
    // HUAWEI
    {
      path: '/igenyles/gyartok/huawei',
      name: 'huaweiHome',
      props: { brandId: 7, brandName: 'huawei' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandHomePageNew'),
    },
    {
      path: '/igenyles/gyartok/huawei/garancialis-javitasok',
      name: 'garancialis-javitasok-huawei',
      props: { brandId: 7, brandName: 'huawei' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandWarrantyFormPage'),
    },
    {
      path: '/igenyles/gyartok/huawei/fizetos-javitasok',
      name: 'fizetos-javitasok-huawei',
      props: { brandId: 7, brandName: 'huawei' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage.vue'),
    },
    {
      path: '/igenyles/gyartok/honor/fizetos-javitasok',
      name: 'fizetos-javitasok-honor',
      props: { brandId: 6, brandName: 'honor' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage.vue'),
    },
    {
      path: '/igenyles/gyartok/lg/fizetos-javitasok',
      name: 'fizetos-javitasok-lg',
      props: { brandId: 11, brandName: 'lg' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage.vue'),
    },
    {
      path: '/igenyles/gyartok/alcatel/fizetos-javitasok',
      name: 'fizetos-javitasok-alcatel',
      props: { brandId: 1, brandName: 'alcatel' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage.vue'),
    },
    {
      path: '/igenyles/gyartok/realme/fizetos-javitasok',
      name: 'fizetos-javitasok-realme',
      props: { brandId: 94, brandName: 'realme' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage.vue'),
    },
    {
      path: '/igenyles/gyartok/samsung/fizetos-javitasok',
      name: 'fizetos-javitasok-samsung',
      props: { brandId: 12, brandName: 'samsung' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage.vue'),
    },
    {
      path: '/igenyles/gyartok/xiaomi/fizetos-javitasok',
      name: 'fizetos-javitasok-xiaomi',
      props: { brandId: 13, brandName: 'xiaomi' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage.vue'),
    },
    // LENOVO
    {
      path: '/igenyles/gyartok/lenovo',
      name: 'lenovoHome',
      props: { brandId: 8, brandName: 'lenovo' },
      meta: { layout: 'outer' },
      component: () => import('./views/NoPage'),
    },
    {
      path: '/igenyles/gyartok/lenovo/garancialis-javitasok',
      name: 'garancialis-javitasok-lenovo',
      props: { brandId: 8, brandName: 'lenovo' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandWarrantyFormPage'),
    },
    {
      path: '/igenyles/gyartok/lenovo/fizetos-javitasok',
      name: 'fizetos-javitasok-lenovo',
      props: { brandId: 8, brandName: 'lenovo' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage'),
    },
    // LENOVO NPC
    {
      path: '/igenyles/gyartok/lenovo-npc',
      name: 'lenovonpcHome',
      props: { brandId: 9, brandName: 'lenovo npc' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandHomePageNew'),
    },
    {
      path: '/igenyles/gyartok/lenovo-npc/garancialis-javitasok',
      name: 'garancialis-javitasok-lenovonpc',
      props: { brandId: 9, brandName: 'lenovo npc' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandWarrantyFormPage'),
    },
    {
      path: '/igenyles/gyartok/lenovo-npc/fizetos-javitasok',
      name: 'fizetos-javitasok-lenovonpc',
      props: { brandId: 9, brandName: 'lenovo npc' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage'),
    },
    // LENOVO THINK
    {
      path: '/igenyles/gyartok/lenovo-think',
      name: 'lenovothinkHome',
      props: { brandId: 10, brandName: 'lenovo think' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandHomePageNew'),
    },
    {
      path: '/igenyles/gyartok/lenovo-think/garancialis-javitasok',
      name: 'garancialis-javitasok-lenovothink',
      props: { brandId: 10, brandName: 'lenovo think' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandWarrantyFormPage'),
    },
    {
      path: '/igenyles/gyartok/lenovo-think/fizetos-javitasok',
      name: 'fizetos-javitasok-lenovothink',
      props: { brandId: 10, brandName: 'lenovo think' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage'),
    },
    // LG
    {
      path: '/igenyles/gyartok/lg',
      name: 'lgHome',
      props: { brandId: 11, brandName: 'lg' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandHomePageNew'),
    },
    // OKAI
    {
      path: '/igenyles/gyartok/okai',
      name: 'okaiHome',
      props: { brandId: 101, brandName: 'okai' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandHomePageNew'),
    },
    {
      path: '/igenyles/gyartok/okai/garancialis-javitasok',
      name: 'garancialis-javitasok-okai',
      props: { brandId: 101, brandName: 'okai' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandWarrantyFormPage'),
    },
    {
      path: '/igenyles/gyartok/okai/fizetos-javitasok',
      name: 'fizetos-javitasok-okai',
      props: { brandId: 101, brandName: 'okai' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage'),
    },
    // OPPO
    {
      path: '/igenyles/gyartok/oppo',
      name: 'oppoHome',
      props: { brandId: 90, brandName: 'oppo' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandHomePageNew'),
    },
    {
      path: '/igenyles/gyartok/oppo/garancialis-javitasok',
      name: 'garancialis-javitasok-oppo',
      props: { brandId: 90, brandName: 'oppo' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandWarrantyFormPage'),
    },
    {
      path: '/igenyles/gyartok/oppo/fizetos-javitasok',
      name: 'fizetos-javitasok-oppo',
      props: { brandId: 90, brandName: 'oppo' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage'),
    },
    // REALME
    {
      path: '/igenyles/gyartok/realme',
      name: 'realmeHome',
      props: { brandId: 94, brandName: 'realme' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandHomePageNew'),
    },
    {
      path: '/igenyles/gyartok/realme/garancialis-javitasok',
      name: 'garancialis-javitasok-realme',
      props: { brandId: 94, brandName: 'realme' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandWarrantyFormPage'),
    },
    {
      path: '/igenyles/gyartok/realme/fizetos-javitasok',
      name: 'fizetos-javitasok-realme',
      props: { brandId: 94, brandName: 'realme' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage'),
    },
    // ROBOROCK
    {
      path: '/igenyles/gyartok/roborock',
      name: 'roborockHome',
      props: { brandId: 97, brandName: 'roborock' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandHomePageNew'),
    },
    {
      path: '/igenyles/gyartok/roborock/garancialis-javitasok',
      name: 'garancialis-javitasok-roborock',
      props: { brandId: 97, brandName: 'roborock' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandWarrantyFormPage'),
    },
    {
      path: '/igenyles/gyartok/roborock/fizetos-javitasok',
      name: 'fizetos-javitasok-roborock',
      props: { brandId: 97, brandName: 'roborock' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage'),
    },
    // SAMSUNG
    {
      path: '/igenyles/gyartok/samsung',
      name: 'samsungHome',
      props: { brandId: 12, brandName: 'samsung' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandHomePageNew'),
    },
    {
      path: '/igenyles/gyartok/samsung/garancialis-javitasok',
      name: 'garancialis-javitasok-samsung',
      props: { brandId: 12, brandName: 'samsung' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandWarrantyFormPage'),
    },
    // TESLA
    {
      path: '/igenyles/gyartok/tesla',
      name: 'teslaHome',
      props: { brandId: 100, brandName: 'tesla' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandHomePageNew'),
    },
    {
      path: '/igenyles/gyartok/tesla/garancialis-javitasok',
      name: 'garancialis-javitasok-tesla',
      props: { brandId: 100, brandName: 'tesla' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandWarrantyFormPage'),
    },
    {
      path: '/igenyles/gyartok/tesla/fizetos-javitasok',
      name: 'fizetos-javitasok-tesla',
      props: { brandId: 100, brandName: 'tesla' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage'),
    },
    // TECNO
    {
      path: '/igenyles/gyartok/tecno',
      name: 'tecnoHome',
      props: { brandId: 110, brandName: 'tecno' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandHomePageNew'),
    },
    {
      path: '/igenyles/gyartok/tecno/garancialis-javitasok',
      name: 'garancialis-javitasok-tecno',
      props: { brandId: 110, brandName: 'tecno' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandWarrantyFormPage'),
    },
    {
      path: '/igenyles/gyartok/tecno/fizetos-javitasok',
      name: 'fizetos-javitasok-tecno',
      props: { brandId: 110, brandName: 'tecno' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage'),
    },
    // TCL
    {
      path: '/igenyles/gyartok/tcl',
      name: 'tclHome',
      props: { brandId: 91, brandName: 'tcl' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandHomePageNew'),
    },
    {
      path: '/igenyles/gyartok/tcl/fizetos-javitasok',
      name: 'fizetos-javitasok-tcl',
      props: { brandId: 91, brandName: 'tcl' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandPayedFormPage'),
    },
    // XIAOMI
    {
      path: '/igenyles/gyartok/xiaomi',
      name: 'xiaomiHome',
      props: { brandId: 13, brandName: 'xiaomi' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandHomePageNew'),
    },
    {
      path: '/igenyles/gyartok/xiaomi/garancialis-javitasok',
      name: 'garancialis-javitasok-xiaomi',
      props: { brandId: 13, brandName: 'xiaomi' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/Brands/ExternalBrandWarrantyFormPage'),
    },
    // FLIP
    /*{
      path: '/igenyles/flip',
      name: 'flipHome',
      props: { mobilProviderId: 1, mobilProviderName: 'flip' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/MobilProviders/MobilProvidersHomePage'),
    },
    {
      path: '/igenyles/flip/garancialis-javitas',
      name: 'flip-garancialis-javitas',
      props: { mobilProviderId: 1, mobilProviderName: 'flip' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/MobilProviders/MobilProvidersGaranciaPage'),
    },
    {
      path: '/igenyles/flip/fizetos-javitas',
      name: 'flip-fizetos-javitas',
      props: { mobilProviderId: 1, mobilProviderName: 'flip' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/MobilProviders/MobilProvidersFizetosPage.vue'),
    },*/
    // TELEKOM
    {
      path: '/igenyles/telekom',
      name: 'telekomHome',
      props: { mobilProviderId: 1, mobilProviderName: 'telekom' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/MobilProviders/MobilProvidersHomePage'),
    },
    {
      path: '/igenyles/telekom/garancialis-javitas',
      name: 'telekom-garancialis-javitas',
      props: { mobilProviderId: 1, mobilProviderName: 'telekom' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/MobilProviders/MobilProvidersGaranciaPage'),
    },
    {
      path: '/igenyles/telekom/fizetos-javitas',
      name: 'telekom-fizetos-javitas',
      props: { mobilProviderId: 1, mobilProviderName: 'telekom' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/MobilProviders/MobilProvidersFizetosPage.vue'),
    },
    // TELENOR
    /*{
      path: '/igenyles/yettel',
      name: 'telenorHome',
      props: { mobilProviderId: 2, mobilProviderName: 'telenor' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/MobilProviders/MobilProvidersHomePage'),
    },
    {
      path: '/igenyles/yettel/garancialis-javitas',
      name: 'telenor-garancialis-javitas',
      props: { mobilProviderId: 2, mobilProviderName: 'telenor', warrantyType: 1 },
      meta: { layout: 'outer' },
      component: () => import('./views/External/MobilProviders/MobilProvidersGaranciaPage'),
    },*/
    // VODAFONE
    {
      path: '/igenyles/vodafone',
      name: 'vodafoneHome',
      props: { mobilProviderId: 3, mobilProviderName: 'vodafone' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/MobilProviders/MobilProvidersHomePage'),
    },
    {
      path: '/igenyles/vodafone/garancialis-javitas',
      name: 'vodafone-garancialis-javitas',
      props: { mobilProviderId: 3, mobilProviderName: 'vodafone' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/MobilProviders/MobilProvidersGaranciaPage'),
    },
    {
      path: '/igenyles/vodafone/fizetos-javitasok',
      name: 'vodafone-fizetos-javitas',
      props: { mobilProviderId: 3, mobilProviderName: 'vodafone' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/MobilProviders/MobilProvidersFizetosPage'),
    },
    {
      path: '/igenyles/vodafone/karrendezes',
      name: 'vodafone-karrendezes',
      props: { mobilProviderId: 3, mobilProviderName: 'vodafone' },
      meta: { layout: 'outer' },
      component: () => import('./views/External/MobilProviders/MobilProvidersBiztositoPage'),
    },
    /*
    {
      path: '/igenyles/biztositas',
      name: 'mobilfutar-biztositas',
      meta: { layout: 'outer' },
      component: () => import('./views/External/BiztositasiPageExternal'),
    }, */
    {
      path: '/igenyles/biztositasi-szervizgarancia',
      name: 'biztositasi-szerviz',
      meta: { layout: 'outer' },
      component: () => import('./views/External/BiztositasiSzervizPageExternal'),
    },
    /* {
      path: '/igenyles/biztositas/mostmobil-szervizgarancia',
      name: 'mostmobil-szerviz',
      meta: { layout: 'outer' },
      component: () => import('./views/External/MostMobilSzervizgarPage'),
    },
    // FLOTTAKEZELÉS
    {
      path: '/igenyles/flottakezeles',
      name: 'flottakezeles',
      meta: { layout: 'outer' },
      props: { pageTitle: 'flottakezeles', serviceId: 100 },
      component: () => import('./views/External/FlottaPage'),
    }, */
    // SZERVIZGARANCIA FUTÁRSZOLGÁLTATÁS
    {
      path: '/igenyles/szervizgarancia-futarszolgaltatas/:identicalHash',
      name: 'szervizgarancia-futarszolgaltatas',
      meta: { layout: 'outer' },
      props: { pageTitle: 'services.swPickup', serviceId: 200 },
      component: () => import('./views/External/SzervizgarFutarPage'),
    },
    // DOLGOZÓI
    {
      path: '/igenyles/dolgozoknak',
      name: 'dolgozoknak',
      meta: { layout: 'outer' },
      props: { pageTitle: 'dolgozoknak', serviceId: 300 },
      component: () => import('./views/External/DolgozoiPage'),
    },
    // SZERVIZKALKULATOR
    /* {
      path: '/igenyles/szerviz-kalkulator',
      name: 'szervizkalkulator',
      meta: { layout: 'outer' },
      component: () => import('./views/Forms/SzervizKalkulator'),
    },
    // SZERVIZKALKULATOR ÜGYFÉL ADATOK
    {
      path: '/igenyles/szerviz-kalkulator/ugyfel-adatok',
      name: 'szervizkalkulatorUgyfeladatok',
      meta: { layout: 'outer' },
      component: () => import('./views/Forms/SzervizKalkulatorMegrendeloForm'),
    },
    // SZERVIZKALKULATOR - MOST
    {
      path: '/igenyles/most-szerviz-kalkulator',
      name: 'most-szervizkalkulator',
      meta: { layout: 'outer' },
      component: () => import('./views/Forms/mostSzervizKalkulator'),
    },
    // SZERVIZKALKULATOR ÜGYFÉL ADATOK - MOST
    {
      path: '/igenyles/most-szerviz-kalkulator/ugyfel-adatok',
      name: 'most-szervizkalkulatorUgyfeladatok',
      meta: { layout: 'outer' },
      component: () => import('./views/Forms/mostSzervizKalkulatorMegrendeloForm'),
    }, */
    // SIKERES FIZETÉS
    {
      path: '/sikeres-fizetes',
      name: 'sikeres-fizetes',
      meta: { layout: 'outer' },
      component: () => import('./views/Payment/SuccessPage'),
    },
    // MÉGSE FIZETÉS
    {
      path: '/visszavont-fizetes',
      name: 'visszavont-fizetes',
      meta: { layout: 'outer' },
      component: () => import('./views/Payment/CanceledPage'),
    },
    // SIKERTELEN FIZETÉS
    {
      path: '/sikertelen-fizetes',
      name: 'sikertelen-fizetes',
      meta: { layout: 'outer' },
      component: () => import('./views/Payment/FailedPage'),
    },
    // BELÉPETT USER
    // IRÁNYÍTÓPULT
    {
      path: '/iranyitopult',
      name: 'iranyitopult',
      meta: { layout: 'internal' },
      redirect: { name: 'leadott-igenyek' },
      component: () => import('./views/Inner/InnerDashboard'),
    },
    // ALAPADATOK
    {
      path: '/iranyitopult/alapadatok',
      name: 'alapadatok',
      meta: { layout: 'internal' },
      component: () => import('./views/Inner/InnerUserInformation'),
    },
    // ÖSSZES MEGRENDELÉS
    {
      path: '/iranyitopult/osszes-megrendeles',
      name: 'megrendelesek',
      meta: { layout: 'internal' },
      component: () => import('./views/Inner/InnerOrders'),
    },
    // MEGRENDELÉS RÉSZLETEI
    {
      path: '/iranyitopult/megrendeles-reszletei',
      name: 'megrendeles-reszletei',
      meta: { layout: 'internal' },
      component: () => import('./views/Inner/InnerOrderDetails'),
    },
    {
      path: '/iranyitopult/uj-megrendeles',
      name: 'uj-megrendeles',
      meta: { layout: 'internal' },
      component: () => import('./views/Inner/InnerNewOrderServiceSelect'),
    },
    {
      path: '/iranyitopult/uj-megrendeles/:szolgaltatas',
      name: 'uj-megrendeles-urlap',
      meta: { layout: 'internal' },
      component: () => import('./views/Inner/InnerNewOrderForm'),
    },
    // ÖSSZES IGÉNY
    {
      path: '/iranyitopult/leadott-igenyek',
      name: 'leadott-igenyek',
      meta: { layout: 'internal' },
      component: () => import('./views/Inner/InnerRequests'),
    },
    // IGÉNY RÉSZLETEI
    {
      path: '/iranyitopult/igeny-reszletei/:crmRequestId',
      name: 'igeny-reszletei',
      meta: { layout: 'internal' },
      component: () => import('./views/Inner/InnerRequestDetailsPage'),
    },
    // SZERVIZGARANCIA MEGRENDELÉS
    {
      path: '/iranyitopult/szervizgarancia/uj-megrendeles/:crmRequestId',
      name: 'szervizgarancia-belepett',
      meta: { layout: 'internal' },
      component: () => import('./views/Inner/InnerSzervizgarPage.vue'),
    },
    {
      path: '/iranyitopult/cimek-kezelese',
      name: 'cimek-kezelese',
      meta: { layout: 'internal' },
      component: () => import('./views/Inner/InnerAddressPage'),
    },
    {
      path: '/iranyitopult/szamlazasi-adatok',
      name: 'szamlazasi-adatok',
      meta: { layout: 'internal' },
      component: () => import('./views/Inner/InnerInvoiceInformation'),
    },
    {
      path: '/iranyitopult/szallitasi-adatok',
      name: 'szallitasi-adatok',
      meta: { layout: 'internal' },
      component: () => import('./views/Inner/InnerShippingInformation'),
    },
    /* {
      path: '/iranyitopult/uzenetek',
      name: 'beerkezo-uzenetek',
      meta: { layout: 'internal' },
      component: () => import('./views/Inner/InnerInbox'),
    }, */
    {
      path: '/iranyitopult/csv-feltoltes',
      name: 'csv-feltoltes',
      meta: { layout: 'internal' },
      component: () => import('./views/Inner/InnerExcelUploadPage'),
    },
    {
      path: '/iranyitopult/dolgozoi-kisokos',
      name: 'dolgozoi-kisokos',
      meta: { layout: 'internal' },
      component: () => import('./views/Kisokos'),
    },
    {
      path: '/iranyitopult/lg-munkalap',
      name: 'lg-munkalap',
      meta: { layout: 'internal' },
      component: () => import('./views/LGmodule/LGListPage'),
    },
    {
      path: '/iranyitopult/lg-munkalap-reszletek/:ws',
      name: 'lg-munkalap-reszletek',
      meta: { layout: 'internal' },
      component: () => import('./views/LGmodule/LGFormPage'),
    },
    {
      path: '/iranyitopult/apple-munkalap',
      name: 'apple-munkalap',
      meta: { layout: 'internal' },
      component: () => import('./views/AppleModule/AppleListPage'),
    },
    {
      path: '/iranyitopult/uj-apple-munkalap/:ws',
      name: 'apple-munkalap-uj',
      meta: { layout: 'internal' },
      component: () => import('./views/AppleModule/AppleFormPage'),
    },
    {
      path: '/iranyitopult/apple-munkalap-reszletek/:ws',
      name: 'apple-munkalap-reszletek',
      meta: { layout: 'internal' },
      component: () => import('./views/AppleModule/AppleRequestDetailsPage.vue'),
    },
    {
      path: '/iranyitopult/miele-munkalap',
      name: 'miele-munkalap',
      meta: { layout: 'internal' },
      component: () => import('./views/MieleModule/MieleListPage.vue'),
    },
    {
      path: '/iranyitopult/uj-miele-munkalap/:ws',
      name: 'miele-munkalap-uj',
      meta: { layout: 'internal' },
      component: () => import('./views/MieleModule/MieleFormPage.vue'),
    },
    {
      path: '/iranyitopult/miele-munkalap-reszletek/:ws',
      name: 'miele-munkalap-reszletek',
      meta: { layout: 'internal' },
      component: () => import('./views/MieleModule/MieleRequestDetailsPage.vue'),
    },
    /* {
      path: '/minta',
      name: 'teszt',
      meta: { layout: 'outer' },
      component: () => import('./views/Minta'),
    },*/
    {
      path: '/javitasi-dokumentumok/nyomonkovetes',
      name: 'documentsTmxNyomonkovetes',
      meta: { layout: 'outer' },
      component: () => import('./views/DocumentsTmx/TrackingLoginPage'),
    },
    {
      path: '/javitasi-dokumentumok/igeny-reszletek/gsm-worksheets/:id/documents/:token',
      name: 'documentsTmxIgenyReszletei',
      meta: { layout: 'outer' },
      component: () => import('./views/DocumentsTmx/RequestDetailsPageViewer'),
    },
    {
      path: '*',
      meta: { layout: 'outer' },
      component: () => import('./views/NoPage'),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash, offset: { x: 0, y: 64 } };
    }
    return { x: 0, y: 0 };
  },
});
