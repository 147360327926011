/* eslint-disable no-shadow */

// initial state
import { APIGET } from 'src/utils/apiHelper.js';

const state = {
  customer: {},
  customerAddresses: [],
};

// getters
const getters = {
  getCustomerAddressFromStoreByAddressTypeId: (state) => (addressTypeId) => {
    console.log(addressTypeId);
    return state.customerAddresses.find((address) => address.addressType.id === addressTypeId);
  },
  getCustomerGeneralDataToPickupReturnRequest(state) {
    return {
      crmNamePrefixId: null,
      customerType: state.customer.customerType,
      email: state.customer.email,
      id: state.customer.customerId,
      firstName: state.customer.firstName,
      lastName: state.customer.lastName,
      middleName: state.customer.middleName,
      password: null,
      phoneNumber: state.customer.phone,
    };
  },
  getCustomerGeneralData(state) {
    return {
      id: state.customer.customerId,
      companyName: state.customer.companyName,
      email: state.customer.email,
      firstName: state.customer.firstName,
      lastName: state.customer.lastName,
      phone: state.customer.phone,
    };
  },
};

// actions
const actions = {
  async GetCustomerDataByCustomerId(context, credentials) {
    const response = await APIGET(`customers/${credentials}`, {}, true, 'CRM');
    if (response.status === 200) {
      context.commit('setCustomerData', { customer: response.data.data, id: credentials });
    }
    return response.status;
  },
  async GetCustomerAddressByAddressTypeId(context, credentials) {
    return context.getters.getCustomerAddressFromStoreByAddressTypeId(credentials);
  },
  async GetCustomerAddresses(context, credentials) {
    const response = await APIGET(`customers/${credentials}/addresses`, {}, true, 'CRM');
    if (response.status === 200) {
      console.log('response - ', response);
      // const transformedList = await this.TransformCustomerAddresses(response.data.data);
      const transformedList = await context.dispatch('TransformCustomerAddresses', response.data.data);
      context.commit('setCustomerAddresses', transformedList);
    }
    return response.status;
  },
  async GetPaymentMethodsByCustomerTypeId(context, credentials) {
    const response = await APIGET(`customer-types/${credentials}/payments`, {}, true, 'CRM');
    return response.data;
  },
  async TransformCustomerAddresses(context, credentials) {
    console.log('addressList - ', credentials);
    let tempAddressList = [];
    credentials.forEach((item) => {
      let postalCodeWithoutZIP = item.zipCode.replace('ZIP:', '');
      let temp = {
        accountNumber: null,
        addressType: item.addressType,
        billingName: item.billingName,
        city: item.city,
        country: { label: item.countryLabel, countryCode: item.countryCode },
        id: item.id,
        isCompany: item.isCompany ? 1 : 0,
        label: '',
        other: item.otherAddressInfo,
        postalCode: postalCodeWithoutZIP,
        publicPlaceName: item.publicPlaceName,
        publicPlaceType: item.publicPlaceType,
        streetNumber: item.houseNumber,
        taxNumber: null,
      };
      tempAddressList.push(temp);
    });
    return tempAddressList;
  }
};

// mutations
const mutations = {
  setCustomerData(state, payload) {
    state.customer = payload.customer;
    state.customer.customerId = payload.id
  },
  setCustomerAddresses(state, payload) {
    state.customerAddresses = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
