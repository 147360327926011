// eslint-disable-next-line import/extensions
import en from './en.js';
// eslint-disable-next-line import/extensions
import hu from './hu.js';
// eslint-disable-next-line import/extensions
import de from './de.js';
import sl from './sl.js';

export default {
  'en-En': en,
  'hu-HU': hu,
  'de-DE': de,
  'sl-SL': sl,
  hu,
  en,
  de,
  sl,
};
