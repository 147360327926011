export default {
  mainNavBar: {
    home:'domača stran',
    aboutUs:'O nas',
    introduction:'Uvod',
    competitions:'Tekmovanja',
    stores:'Trgovine',
    services:'Storitve',
    career:'Kariera',
    generalInformations:'Splošne informacije',
    jobOffers:'Ponudbe za delo',
    worksheets:'Delovni listi',
    partners:'Partnerji',
    contact:'Kontakt',
    mobilfutar:'Mobilfutar',
    buyback:'Odkupi',
    tracking:'Sledenje',
    request:'Prošnja',
    login:'Vpiši se',
    logout:'Odjava',
    registration:'Registracija',
    forgotPassword:'Ste pozabili geslo'
  },
  innerSideNav: {
    home:'domača stran',
    userInformation:'Informacije o uporabniku',
    allOrders:'Vsa naročila',
    allClaims:'Vsi zahtevki',
    billingInformation:'Podatki za obračun',
    shippingInformation:'Informacije o pošiljanju',
    addressInformation:'Podatki o naslovu',
    massUpload:'Množično nalaganje',
    messages:'Sporočila',
    exit:'Izhod'
  },
  excel: {
    downloadSamples:'Prenesite vzorce',
    uploadFiles:'Upload files',
    downloadSampleCSV:'Prenesite vzorčno datoteko CSV',
    downloadSampleEXCEL:'Prenesite vzorčno datoteko excel',
    acceptFiles:'Sprejmi datoteke: .csv, .xls, .xlsx',
    downloadExcelHelper:'Prenesite pomočnika excel',
    errorMessages:'V naloženi datoteki je nekaj napak'
  },
  serviceCalculator:'Servisni kalkulator',
  login:'Vpiši se',
  loginConfirm:'Potrditev prijave',
  twoStepLogin:'Prijava v dveh korakih',
  registration:'Registracija',
  email:'E-mail',
  password:'Geslo',
  passwordConfirm:'potrditev gesla',
  oldPassword:'Staro geslo',
  newPassword:'Novo geslo',
  newPasswordConfirm:'Potrditev novega gesla',
  forgotPassword:'Ste pozabili geslo',
  addNewPassword:'Dodaj novo geslo',
  checkPassword:'Preverite geslo',
  accountInformation:'Podatki o računu',
  accountLanguage:'Jezik računa',
  accountCurrency:'Valuta računa',
  accountPayment:'Plačilo po računu',
  primaryContactInformation:'Primarni kontaktni podatki',
  firstName:'Ime',
  lastName:'Priimek',
  phoneNumber:'Telefonska številka',
  actions:'Dejanja',
  search:'Iskanje',
  damageNumber:'Številka zahtevka',
  invoice:'Račun',
  warrantyTicket:'Garancijska vstopnica',
  evidence:'Dokazi',
  brand:'Blagovna znamka',
  productType:'Tip',
  deviceType:'Tip izdelka',
  deviceIdentifier:'Identifikator naprave',
  imeiSn:'IMEI/SN',
  dateOfPurchase:'Datum nakupa',
  troubleDescription:'Opis težave',
  otherDescription:'Drugi opis',
  receptionAddress:'Naslov recepcije',
  newReceptionAddress:'Nov naslov za sprejem',
  transmissionAddress:'Naslov prenosa',
  newTransmissionAddress:'Nov naslov za prenos',
  invoicingAddress:'Naslov za izdajo računov',
  billingAddresses:'Naslov za pošiljanje računa',
  newInvoicingAddress:'Nov naslov za izdajo računov',
  shippingAddress:'Naslov za pošiljanje',
  shippingAddresses:'Naslovi za dostavo',
  newShippingAddress:'Nov naslov za dostavo',
  shippingAndInvoicingAddress:'Naslov za pošiljanje in izdajo računov',
  invoicingName:'Ime računa',
  dateOfPickUp:'Datum prevzema',
  dateOfTransmission:'Datum oddaje',
  country:'Država ',
  city:'Mesto ',
  postalCode:'Poštna številka',
  publicPlaceName:'Ime javnega kraja',
  publicPlaceType:'Javno mesto Tip',
  streetNumber:'Številka ulice',
  status:'Stanje ',
  contactIsTheSame:'Stik je enak',
  transmissionDataIsSameAsReceptionData:'Naslov za prenos je enak naslovu za sprejem',
  invoicingDataIsSameAsReceptionData:'Naslov za izdajanje računov je enak naslovu za sprejem',
  invoicingDataIsSameAsTransmissionData:'Naslov za izdajanje računov je enak naslovu za oddajo',
  invoicingAddressIsEqualShippingAddress:'Naslov za izdajanje računov je enak naslovu za pošiljanje',
  invoicingDataIsOther:'Podatki o izdajanju računov so drugo',
  loadingText:'Nalaganje podatkov ... Počakajte!',
  pickUpAndReturn:'Poberi in se vrni',
  welcomeText:'Dobrodošli na spletnem mestu TMX prevzem in vrnitev!',
  findOutMoreAboutOurServices:'Izvedite več o naših storitvah',
  warrantyRepairForm:'Obrazec za garancijsko popravilo',
  oufOfWarrantyRepairForm:'Število obrazcev za popravilo garancije',
  repairForm:'Obrazec za popravilo',
  tracking:'Sledenje',
  documents:'Dokumenti',
  documentUpload:'Nalaganje dokumentov',
  contactName:'Kontaktno ime',
  serviceRequestId:'ID storitvene zahteve',
  addressDetails:'Podrobnosti o naslovu',
  vatNumber:'Davčna številka',
  quoteDone:'Navedba narejena',
  exportReportDone:'Poročilo o izvozu končano',
  mostmobil:'Mostmobil servisna garancija',
  de3000:'DE-3000 trgovina',
  requestIdOrWorksheetNumber:'ID zahteve ali številka delovnega lista',
  accountRegistration:'Registracija računa',
  accountRegistrationQuestionOnForm:'Bi radi registrirali uporabniški račun? Z uporabniškim računom lahko lažje sledite naročenim storitvam.',
  acceptCheckbox: {
    generalInformation:'Berem in sprejemam zgornje informacije.'
  },
  hasActiveQuoteBox:{
    title:'Ali sprejemate ponudbo?',
      desc:'Naša ponudba velja 5 delovnih dni. Prosimo, odgovorite v roku, sicer bomo vaše naročilo izbrisali!',
      dowloadSentenceText:'Tu lahko vidite svojo ponudbo.',
      dowloadSentenceLinkText:''
  },
  hasActiveExpertReportBox:{
    title:'Ali sprejemate strokovno poročilo?',
    desc:'Naše strokovno poročilo velja 5 delovnih dni. Prosimo, odgovorite v roku, sicer bomo vaše naročilo izbrisali!',
    dowloadSentenceText: 'Tu si lahko ogledate svoje strokovno poročilo.',
    dowloadSentenceLinkText:''
  },
  forgotPasswordReminderText:'V zgornje polje vnesite svoj registrirani e-poštni naslov.',
  passwordRuleGeneral:'Min. 8 znakov. Vsebovati mora črko, veliko in malo',
  registrationInfo:'V spodnje polje vnesite geslo. S tem geslom se lahko prijavite v svoj uporabniški račun. Vaš e-poštni naslov za prijavo bo tisto, kar ste vnesli v svoj profil kupca.',
  greetings:'Pozdravi',
  unreadMessages:'Neprebrana sporočila',
  readMessages:'Preberite sporočila',
  personalSite:'Osebna stran',
  flottakezeles:'Za poslovne partnerje',
  dolgozoknak:'Za zaposlene',
  priceCalculation:'Izračun cene',
  buyBack:'Odkupi',
  program:'Program',
  estimatedServicePrice:'Predvidena cena storitve in dostave gram',
  askForQuote:'Vprašajte za ponudbo!',
  arriveToService:'Prihod na servis',
  referenceNumber:'Reference number',
  quotePrice:'Referenčna številka',
  button: {
    send:'Pošlji',
    newOrder:'Novo naročilo',
    newAddress:'Nov naslov',
    changePassword:'Spremeni geslo',
    deleteProfile:'Izbriši profil',
    cancel:'Prekliči ',
    view:'Pogled ',
    select:'Izberite  ',
    next:'Naslednji ',
    back:'Nazaj ',
    imeiValidate:'Imei validacija',
    save:'Shrani ',
    edit:'Uredi ',
    download:'Prenesi',
    upload:'Naloži ',
    validate:'Potrdite',
    calculate:'Izračunaj',
    orderTheService:'Naročim storitev',
    interestedInOffer:'Zanima me ponudba',
    askForQuote:'Prosim za ponudbo',
    acceptQuote:'Sprejmite ponudbo',
    acceptQuotes:'Sprejmite ponudbe',
    rejectQuote:'Zavrni ponudbo',
    rejectQuotes:'Zavrnite ponudbe',
    quoteAcceptText:'Sprejemam ponudbo',
    quoteRejectText:'Zavračam ponudbo',
    expertReportAcceptText:'Sprejemam strokovno poročilo',
    expertReportRejectText:'Zavrnem strokovno poročilo',
    goToLogin:'Pojdi na prijavo',
  },
  inputMessages: {
    requiredField:'Zahtevano polje',
    maxCharacter: 'Max 3000 characters',
    optionalField:'Neobvezno polje',
    passwordRule:'Min. 8 znakov. Vsebovati mora črko, veliko in malo',
    passwordRuleMin8:'Vsebovati mora najmanj 8 znakov!',
    passwordNotMatch:'Geslo se ne ujema',
    searchHint:'S tipkanjem lahko iščete vse podatke iz tabele.',
    acceptInformation:'Če želite izpolniti svojo zahtevo, morate sprejeti informacije in…',
    maxFileSizeRule:'Velikost naložene datoteke je lahko največ 5 MB!',
    emailMatch:'Vnesite veljaven e-poštni naslov!',
    onlyNumbers:'Vnesite samo številke!',
    contactIsTheSameHelp:'Če ste kontaktna oseba za ta naslov, označite to potrditveno polje.',
    requiredPrivacy:'Za pošiljanje obrazca morate sprejeti podatke o zasebnosti!',
    requiredTermsAndCond:'Za pošiljanje obrazca morate sprejeti Splošne pogoje poslovanja!',
    toFewCharacters:'Vnesli ste premalo znakov!',
    damageNumberNotMatch:'Številke zahtevkov se začnejo z znaki AS ali VF in vsebujejo še 7 znakov.',
    startTypingPostalCode:'S tipkanjem lahko iščete poštne številke.',
    startTypingCity:'S tipkanjem lahko iščete mesta.',
    startTypingStreet:'S tipkanjem lahko iščete ulice.',
    noDataText:'Ni najdenega rezultata!',
    insuranceNumberRule: 'Nem tartalmazhat szóközt és speciális karaktereket!',
    requiredFieldRequestId: 'Kötelező kitölteni! GSM munkalapszám vagy igényazonosító',
  },
  order: {
    orderId:'Številka naročila',
    orderDate:'Datum naročila',
    orderStatus:'Status naročila',
    orderPlaceNewOrder:'Oddaj novo naročilo',
    orderDetails:'Podrobnosti naročila',
  },
  request: {
    requestDetails:'Zahtevajte podrobnosti',
    requestData:'Zahtevajte podatke',
  },
  serviceRequestHistory: {
    RECEIVED:'Prejeto naročilo',
    DELIVERY_IN_ORDERED:'Naročilo v dostavi',
    ARRIVED:'Naprava je prispela v storitev',
    QUOTE_DONE:'Citat opravljen',
    QUOTE_ACCEPTED:'Ponudba sprejeta',
    QUOTE_REJECTED:'Navedba zavrnjena',
    PRODUCT_LEFT_THE_SERVICE:'Izdelek je zapustil storitev',
    INVOICE_DONE:'Račun narejen',
    DELIVERY_OUT_ORDERED:'Dostava je naročena',
    SHIPPED:'Odposlano',
    SERVICE_DONE:'Naprava, ki je ostala od storitve',
  },
  shipping: {
    pickupAddress:'Prevzemite naslov',
    shippingAddress:'Naslov za pošiljanje',
  },
  services: {
    iw:'Popravilo v garanciji',
    oow:'Popravilo brez garancije',
    sw:'Servisna garancija',
    asu:'Popravilo zavarovanja',
    asuw:'Garancija zavarovalne storitve',
    swPickup:'Prevzem in vračilo servisne garancije',
    garancia:'Garancija zavarovanja prevzame in vrne',
    lenovo:'Lenovo prevzame in vrne',
    asus:'Asus prevzame in vrne',
    xiaomi:'Xiaomi prevzame in vrne',
    gopro:'Gopro prevzame in vrne',
    flottakezeles:'Za poslovne partnerje',
    simpleRepair:'Enostavno naročilo za popravilo',
    insuranceService:'Garancija zavarovalne storitve',
    mostmobil:'Garancija za servis Mostmobil.hu',
  },
  stepper: {
    generalDetails:'Splošne podrobnosti',
    deviceDetails:'Podrobnosti o napravi',
    customerDetails:'Podrobnosti o stranki',
    confirmation:'Potrditev',
    done:'Končano ',
  },
  ERROR_MESSAGES: {
    ERROR_IMEI:'Številka IMEI, ki ste jo vnesli, ne ustreza Luhnovemu algoritmu. Preverite številko IMEI, ki ste jo vnesli. Če ste vnesli serijsko številko, vzemite to sporočilo brezpredmetno.',
    REQUEST_SEND_1:'Nepričakovana napaka! Delamo na reševanju problemov.',
    REQUEST_SEND_2:'Povezava izgubljena! Delamo na reševanju problemov.',
  },
  SUCCESS_MESSAGES: {
    SUCCESS_IMEI:'Imei je potrditev uspela',
    REQUEST_SEND:'Uspehi',
  },
  warrantyRepair:'Garancijsko popravilo',
    warrantyRepairs:'Garancijsko popravilo',
    payedRepair:'Popravilo brez garancije',
    tmxGeneralInformation:'Slo General information',
  inputLabels: {
    companyName:'Ime podjetja',
    firstName:'Ime',
    middleName:'Srednje ime',
    lastName:'Priimek',
    email:'E-mail ',
    phone:'Telefonska številka',
    name:'Ime',
    topic:'Tema ',
    manufacturer:'Proizvajalec ',
    imei:'Številka IMEI',
    subject:'Predmet',
    message:'Sporočilo',
    field:'Polje',
    job:'Služba',
    cv:'Življenjepis',
    otherDoc:'Drugi doc',
    productType:'Tip izdelka',
    warrantyType:'Vrsta garancije',
    dateOfPurchase:'Datum nakupa',
    troubleDescription:'Opis težave',
    simLock:'Sim ključavnica',
    country:'Država',
    postalCode:'Poštna številka',
    city:'Mesto',
    publicPlaceName:'Javno ime',
    publicPlaceType:'Javni tip',
    streetNumber:'številka ulice',
    other:'Drugo',
    invoice:'Račun',
    warrantyTicket:'Garancijska vstopnica',
    damageNumber:'Številka zahtevka',
    serviceRequest:'Zahteva za storitev',
    serviceRequests:'Zahteve za storitve',
    calculatedPrice:'Izračunana cena popravila in dostave',
    serviceRequestId:'ID zahteve za storitev',
    rma:'Številka RMA',
    productGroup:'Skupina izdelkov',
    uniqueIdentifierIn1:'Enolični ID 1',
    uniqueIdentifierIn2:'Enolični ID 2',
    uniqueIdentifierIn3:'Enolični ID 3',
    uniqueIdentifierIn1new: 'Enolični ID 1 (IMEI/SN)',
    uniqueIdentifierIn2new: 'Enolični ID 2 (IMEI/SN)',
    uniqueIdentifierIn3new: 'Enolični ID 3 (IMEI/SN)',
    accountRegistrationTrue:'Da, rad bi registriral račun.',
    accountRegistrationFalse:'Ne, ne maram registrirati računa.',
    password:'Geslo',
    selectShippingAddress:'Izberite naslov za dostavo',
    selectBillingAddress:'Izberite naslov računa',
    serviceRequestIdentifier:'ID storitve.',
    origRequestSource:'Spletna stran',
    selectFilesToUpload:'Izberite datoteke za nalaganje',
    deviceColor:'Barva naprave',
    businessFormName:'Poslovna oblika',
    paymentMethods:'',
    accountNumber:'',
    taxNumber:'',
  },
  insuranceRepairForm:'Nalog za popravilo zavarovanja',
  introduction:'Uvod',
  ourServices:'Naše storitve',
  whyChooseUs:'Zakaj izbrati nas?',
  ourPartners:'Naši partnerji',
  generalRequirements:'Splošni pogoji',
  seeJobOffers:'Oglejte si naše dejanske ponudbe',
  complexServiceForOurPartners:'Kompleksne storitve za naše partnerje',
  centralEuropeSuperGrowingService:'Neprestano rastoče storitve v Srednji Evropi.',
  whereCanYouFindUs:'Kje nas lahko najdete?',
  sendUsAMessage:'Pošljite nam sporočilo',
  ourEmail:'Naš e-poštni naslov',
  ourPhoneNumber:'Naša telefonska številka',
  ourStores:'Naše trgovine',
  serviceProducts:'Izdelki za storitve',
  impress:'Impress',
  nameOfTheCompany:'Ime podjetja',
  headquarter:'Glavni štab',
  headquarterOfTheCompany:'Sedež podjetja',
  sitesOfTheCompany:'Spletna mesta podjetja',
  postalAddressOfTheCompany:'Poštni naslov podjetja',
  phoneNumberOfTheCompany:'Telefonska številka podjetja',
  telefaxNumberOfTheCompany:'Telefaks številka podjetja',
  emailOfTheCompany:'Elektronski naslov podjetja',
  VATNumberOfTheCompany:'Številka DDV podjetja',
  webhostDetails:'Podrobnosti o imenu spletnega gosta in podatkih',
  complaintPolicy:'Politika pritožb',
  privacyPolicy:'Politika zasebnosti',
  deliveryService: 'Futárszolgálat',
  deliveryServiceText: 'A futárszolgálat lehetőséget választva a javítási igény leadását követően a szerviz az 1. pontban megadott címre küldi a futárt. A készülék begyűjtéséről legkésőbb a begyűjtés napján SMS / email / telefonhívás formájában tájékoztatja a futár.',
  foxpost: 'Foxpost',
  foxpostText: 'A csomagot a kiválasztott Foxpost csomagautomatában tudja feladni részünkre, begyűjtési igényének feldolgozását követően az SMS-ben / emailben kapott nyitókód megadásával.',
  termsAndCond:'Splošni pogoji',
  usedEquipmentForSale:'Prodaja rabljenih naprav - Splošni pogoji',
  imeiHint:'V telefon vtipkajte * # 06 # kodo, da jo preverite.',
  checkSpamFolder:'Ko boste uspešno oddali naročilo, vam bomo poslali e-sporočilo na vaš e-poštni naslov. Prosimo, preverite svojo mapo SPAM, če je ne najdete.',
  moreInfosAboutTheService:'Informacije o storitvi',
  imeiError:'Številka IMEI, ki ste jo vnesli, ne ustreza Luhnovemu algoritmu. Preverite številko IMEI, ki ste jo vnesli. Če ste vnesli serijsko številko, vzemite to sporočilo brezpredmetno.',
  attachDocs:'Če jih ne morete poslati z napravo, priložite zahtevane dokumente.',
  generalServiceInfoText:'Besedilo z informacijami o splošnih storitvah! Még nincs kész',
  loadingMessages: {
    INPROGRESS_CONTACT_FORM:'Pošiljanje v teku. Prosim počakaj.',
    INPROGRESS_RESETPASSWORD:'Vaša zahteva je v teku. Prosim počakaj.',
    INPROGRESS_SETPASSWORD:'Nastavitev novega gesla je v teku. Prosim počakaj.'
  },
  documentTypes: {
    warrantyCardHash:'Predani garancijski list',
    invoiceHash:'Predani račun',
    faultReportHash:'Poročilo o napaki',
    handoverDocumentHash:'Primopredajni dokument',
    gsmQuotationHash:'Gsm ponudba',
    dolphinInvoiceHash:'Račun za delfine',
    gsmRepairWorksheetHash:'Delovni list za popravilo GSM',
  },
  noDataText: {
    productGroups:'Za izbranega proizvajalca ni skupine izdelkov',
    general:'Ni podatkov za prikaz.',
    noRequestFound:'Zahteve ni mogoče najti.',
    noSearchResult1:'Ni zadetkov.',
    noSearchResult2:'',
  },
  szervizkalk:{
    buybackIntro:'Buybackintro szöveg szlovénul (Még nincs kész)',
    soldItToUs:'Za to ceno nam jo prodajte.',
  },
  needToLogin:'Za uporabo te storitve se morate prijaviti!',
    passwordCheck:{
    min1lowercase:'Min. eno malo črko',
    min1uppercase:'Min. en velik črk',
    min8char:'Min. 8 znakov',
    min1number:'Min. 1 številka',
    min1special:'Min. 1 poseben znak',
    passMatch:'Ujemanje gesla.',
  },
  paymentAndAddressDatas:'Podatki o naslovu in plačilih',
    financialDatas:'Finančni podatki',
    nob2bRefNo:'Referenčne številke ni',
    noDocumentToShow:'Ni dokumenta za prikaz.',
  reUseRequestDialog:{
    title:'Vloga za garancijo storitve',
    desc:'Szlovén szöveg: Lorem ipsum',
  },
  gsmWorksheetId: 'slo-GSM munkalapszám',
  downloadFileErrorDialog: {
    title: 'slo-Hiba',
    noFile: 'slo-A kiválasztott dokumentum nem található. Kérjük próbálja meg később!',
  },
  loginOrReg: 'slo-Bejelentkezés',
  $vuetify: {
    badge: 'Značka',
    close: 'Zapri',
    dataIterator: {
      noResultsText: 'Ni iskanega zapisa',
      loadingText: 'Nalaganje...',
    },
    dataTable: {
      itemsPerPageText: 'Vrstic na stran:',
      ariaLabel: {
        sortDescending: ': Razvrščeno padajoče. Aktivirajte za odstranitev razvrščanja.',
        sortAscending: ': Razvrščeno naraščajoče. Aktivirajte za padajoče razvrščanje.',
        sortNone: ': Ni razvrščeno. Aktivirajte za naraščajoče razvrščanje.',
      },
      sortBy: 'Razvrsti po',
    },
    dataFooter: {
      itemsPerPageText: 'Predmetov na stran:',
      itemsPerPageAll: 'Vse',
      nextPage: 'Naslednja stran',
      prevPage: 'Prejšnja stran',
      firstPage: 'Prva stran',
      lastPage: 'Zadnja stran',
      pageText: '{0}-{1} od {2}',
    },
    datePicker: {
      itemsSelected: '{0} izbrano/-ih',
    },
    noDataText: 'Ni podatkov',
    carousel: {
      prev: 'Prejšnji prikaz',
      next: 'Naslednji prikaz',
      ariaLabel: {
        delimiter: 'Carousel slide {0} of {1}',
      },
    },
    calendar: {
      moreEvents: 'Še {0}',
    },
    fileInput: {
      counter: '{0} datotek',
      counterSize: '{0} datotek ({1} skupno)',
    },
    timePicker: {
      am: 'AM',
      pm: 'PM',
    },
  },
  homeWelcomeText: '<p class="display-1 text-center">slo-Üdvözöljük a megújult szolgáltatásunk hamarosan indul!</p>'
    + '<p class="text-center">slo-További információk az info@tmx.hu email címünkön.</p>',
  day: 'slo-nap',
  hour: 'slo-óra',
  min: 'slo-perc',
  sec: 'slo-mp',
  kisokosTitle: 'slo-Dolgozói igényleadás útmutató',
  kisokosSubTitle: 'slo-Útmutató',
  szervizgar: {
    generalInformation: '<p>slo-Tisztelt Ügyfelünk!</p>' +
      '<p>Biztosítós szervizgarancia szolgáltatásunk egy korábbi biztosítási eseményt követően vehető igénybe.</p>' +
      '<p>Az adott napon 14:30-ig beérkezett igényeket, a következő munkanapon tudjuk leghamarabb teljesíteni.</p>' +
      '<p>Szervizgaranciát kizáró ok egy új káresemény bekövetkezése (pl: törés, folyadék okozta kár, illetéktelen hardveres/szoftveres beavatkozás). Ebben az esetben kérjük, keresse fel biztosítóját.</p>' +
      '<p>Apple készülékek esetén a Find My Iphone (FMI, vagy iPhone keresése) funkció kikapcsolása az ügyfél feladata. Ha ezt a funkciót nem tudja feloldani, keresse fel az Apple ügyfélszolgálatát, ahol segítséget nyújtanak Önnek.</p>' +
      '<p>Felhívjuk figyelmét, hogy a FMI funkció kikapcsolási ideje nem tartozik bele a javításra vállalt időbe.</p>',
  },
  cookies: 'Süti beállítások',
  docTmxTracking: 'documents.tmx.hu'
};
