/* eslint-disable no-shadow */
import {APIGET, APIPOST, APIPUT, APIFILEDOWNLOADPDFGET, APIFILEDOWNLOADPOST, APIFILEDOWNLOAD} from 'src/utils/apiHelper';

// initial state
const state = {
  //
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async GetLGWorksheetList(context, credentials) {
    const response = await APIGET('lg-worksheets', {}, true);
    return response.data;
  },
  async GetLGWorksheetById(context, credentials) {
    const response = await APIGET(`lg-worksheets/${credentials}`, {}, true);
    return response.data;
  },
  async GetLGWorksheetByIdDocuments(context, credentials) {
    const response = await APIGET(`lg-worksheets/${credentials}/documents`, {}, true);
    return response.data;
  },
  async CreateNewLGWorksheet(context, credentials) {
    const response = await APIPOST('lg-worksheets', credentials, true);
    // console.log('response: ', response);
    return response;
  },
  async UpdateLGWorksheet(context, credentials) {
    const response = await APIPUT(`lg-worksheets`, credentials.worksheet, true);
    // console.log('response: ', response);
    return response;
  },
  async UploadFilesToLGWorksheet(context, credentials) {
    const formData = new FormData();
    for (let i = 0; i < credentials.files.length; i++) {
      formData.append('lg_documents[]', credentials.files[i]);
    }
    // console.log(formData.getAll('lg_documents[]'));
    const response = await APIPOST(`lg-worksheets/${credentials.id}/documents`, formData, true);
    // console.log('response: ', response);
    return response;
  },
  async GetLGWorksheetPDF(context, credentials) {
    const response = await APIFILEDOWNLOADPDFGET(`lg-worksheets/${credentials}/pdf`, {}, true);
    if (response.status === 200) {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([response.data], { type: 'application/pdf' });
      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      // MENTÉSES verzió
      /* const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf');
      document.body.appendChild(link);
      link.click(); */
      // MEGNYITÁSOS verzió
      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = data;
      // console.log('link: ', link);
      link.target = '_blank';
      link.click();
      // setTimeout(() => window.URL.revokeObjectURL(data), 2000);
    }
  },
  async GetLGWorksheetsCSV(context, credentials) {
    const response = await APIFILEDOWNLOADPOST('lg-worksheets/csv', { worksheets: credentials.worksheets }, true);
    if (response.status === 200) {
      const now = new Date().toISOString().substr(0, 10);
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([response.data], { type: 'application/pdf' });
      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      // MENTÉSES verzió
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${now}-lg-munkalapok.csv`);
      document.body.appendChild(link);
      link.click();
    }
  },
  async DownloadLGWorksheetDocument(context, credentials) {
    const formData = new FormData();
    const tempForm = credentials.doc;
    const formJSON = JSON.stringify(tempForm);
    formData.append('data', formJSON);
    const response = await APIFILEDOWNLOAD('documents', formData);
    if (response.status === 200) {
      // console.log(response);
      const responseHeader = new Headers(response.headers);
      const contentType = responseHeader.get('Content-Type');
      const i = contentType.lastIndexOf('/');
      const fileType = contentType.substr(i + 1);
      // console.log(fileType);
      const now = new Date().toISOString().substr(0, 10);
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([response.data], { type: `${contentType}` });
      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      // MENTÉSES verzió
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `LGdocument@${now}.${fileType}`);
      document.body.appendChild(link);
      link.click();
    }
  },
  async SendLGWorksheetViaEmail(context, credentials) {
    const response = await APIGET(`lg-worksheets/${credentials}/email`, {}, true);
    return response.data;
  },
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
