/* eslint-disable no-shadow */

// initial state
import { APIFILEDOWNLOADPDFGET, APIGET } from 'src/utils/apiHelper';

const state = {
  //
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async GetAppleWorksheetList(context, credentials) {
    const response = await APIGET('customer-requests/apple', {}, true);
    return response.data;
  },
  async GetAppleWorksheetById(context, credentials) {
    const response = await APIGET(`customer-requests/${credentials.id}/show`, {}, true);
    return response.data;
  },
  async GetAppleDocuments(context, credentials) {
    const response = await APIGET(`customer-requests/${credentials}/apple-documents`, {}, true);
    return response.data;
  },
  async DownloadAppleShopDocuments(context, credentials) {
    const response = await APIFILEDOWNLOADPDFGET(`customer-requests/${credentials.id}/apple-pdf/${credentials.docType}`, {}, true);
    if (response.status === 200) {
      // console.log(response);
      const responseHeader = new Headers(response.headers);
      const contentType = responseHeader.get('Content-Type');
      const i = contentType.lastIndexOf('/');
      const fileType = contentType.substr(i + 1);
      // console.log(fileType);
      const now = new Date().toISOString().substr(0, 10);
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      // 'application/pdf' `${contentType}`
      const newBlob = new Blob([response.data], { type: `${contentType}` });
      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      // MENTÉSES verzió
      /* const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('_blank', `LGdocument@${now}.${fileType}`);
      document.body.appendChild(link);
      link.click(); */
      // megnyitásos verzió
      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = data;
      // console.log('link: ', link);
      link.target = '_blank';
      // link.download = 'file.pdf';
      link.click();
      // setTimeout(() => window.URL.revokeObjectURL(data), 2000);
      return true;
    }
    return false;
  },
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
