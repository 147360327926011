import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"frag",rawName:"v-frag"}]},[_c(VAppBar,{attrs:{"app":"","fixed":"","color":"white","height":"32"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c(VIcon,{attrs:{"color":"primary"},on:{"click":_vm.toggleMiniVariant}},[_vm._v(" "+_vm._s(_vm.miniVariant ? 'mdi-menu' : 'mdi-backburger')+" ")]):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c(VIcon,{attrs:{"color":"primary"},on:{"click":_vm.toggleNavigationDrawer}},[_vm._v(" "+_vm._s(_vm.navigationDrawer ? 'mdi-backburger' : 'mdi-menu')+" ")]):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"body-2 text-uppercase font-weight-bold primary--text mx-4"},[_vm._v(" Mobilfutár ")]):_vm._e(),_c(VSpacer),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"body-2 primary--text ml-4 mr-2"},[(_vm.$store.state.authModule.user.profile.customerType === 'B2C' || _vm.$store.state.authModule.user.profile.customerType === 'EMPLOYEE'
            || _vm.$store.state.authModule.user.profile.customerType === 'MOST-B2C')?_c('div',[_vm._v(" "+_vm._s(_vm.$t('greetings'))+" "+_vm._s(_vm.$store.state.authModule.user.profile.lastName)+" "+_vm._s(_vm.$store.state.authModule.user.profile.firstName)+"! ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t('greetings'))+" "+_vm._s(_vm.$store.state.authModule.user.profile.companyName)+" "+_vm._s(_vm.$store.state.authModule.user.profile.businessForm.name)+"! ")])]):_vm._e(),_c('div',{directives:[{name:"frag",rawName:"v-frag"}]},[(_vm.$store.state.authModule.user.profile.customerType === 'B2C' || _vm.$store.state.authModule.user.profile.customerType === 'EMPLOYEE'
               || _vm.$store.state.authModule.user.profile.customerType === 'MOST-B2C')?_c(VIcon,{class:_vm.$vuetify.breakpoint.smAndDown ? 'ml-4': '',attrs:{"color":"light-green darken-1"}},[_vm._v(" mdi-account-circle ")]):_c(VIcon,{class:_vm.$vuetify.breakpoint.smAndDown ? 'ml-4': '',attrs:{"color":"orange darken-1"}},[_vm._v(" mdi-account-supervisor-circle ")])],1),(_vm.$showLanguageDropdown)?[_c('LanguageChangeDropdown')]:_vm._e()],2),_c(VNavigationDrawer,{staticClass:"pa-4",attrs:{"mini-variant":_vm.$vuetify.breakpoint.mdAndUp ? _vm.miniVariant : false,"mini-variant-width":"72","app":"","width":"280","permanent":_vm.$vuetify.breakpoint.mdAndUp,"color":"#37474F"},model:{value:(_vm.navigationDrawer),callback:function ($$v) {_vm.navigationDrawer=$$v},expression:"navigationDrawer"}},[_c('div',{staticClass:"py-4"},[(!_vm.miniVariant)?_c(VImg,{staticClass:"mx-auto",attrs:{"src":require("../../assets/logo/mobilfutarLogoNew@211x60.png"),"height":"60","width":"212"}}):_c(VImg,{staticClass:"mx-auto",attrs:{"src":require("../../assets/logo/mobilfutarLogoNew@40x40.png"),"height":"40","width":"40"}})],1),_c(VDivider,{staticClass:"white"}),(_vm.$store.state.authModule.user.profile.customerType !== 'PREALERT')?_c(VTooltip,{attrs:{"right":_vm.miniVariant,"top":!_vm.miniVariant},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
               var on = ref.on;
return [_c(VBtn,_vm._g({directives:[{name:"show",rawName:"v-show",value:(!_vm.miniVariant),expression:"!miniVariant"}],staticClass:"my-6",attrs:{"color":"accent","block":"","x-large":""},on:{"click":_vm.newOrderPage}},on),[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('button.newOrder'))+" ")])],1),_c(VBtn,_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.miniVariant),expression:"miniVariant"}],staticClass:"my-6 accent",attrs:{"icon":"","dark":""},on:{"click":_vm.newOrderPage}},on),[_c(VIcon,[_vm._v(" mdi-plus ")])],1)]}}],null,false,40354640)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('button.newOrder'))+" ")])]):_vm._e(),_c(VList,[_vm._l((_vm.$store.state.innerSideNav.sideNav),function(item,index){return [(!item.children || item.children.length === 0)?_c('div',{key:index},[(_vm.$store.state.authModule.user.profile.customerType === 'EMPLOYEE')?[(!item.ura || _vm.uras.includes(item.ura))?_c(VListItem,{key:index,staticClass:"px-0",on:{"click":function($event){return item.click()}}},[_c(VListItemIcon,[_c(VTooltip,{attrs:{"right":_vm.miniVariant,"top":!_vm.miniVariant},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
               var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"primary"},domProps:{"textContent":_vm._s(item.icon)}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(("" + (item.text)))))])])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"white--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t(("" + (item.text))))+" ")])],1)],1):_vm._e()]:[(item.roles[0] === 'all' ? true : item.roles.includes(_vm.$store.state.authModule.user.profile.customerType))?_c(VListItem,{key:index,staticClass:"px-0",on:{"click":function($event){return item.click()}}},[_c(VListItemIcon,[_c(VTooltip,{attrs:{"right":_vm.miniVariant,"top":!_vm.miniVariant},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
               var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"primary"},domProps:{"textContent":_vm._s(item.icon)}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(("" + (item.text)))))])])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"white--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t(("" + (item.text))))+" ")])],1)],1):_vm._e()],_c(VDivider,{staticClass:"ml-12"})],2):(item.children && item.children.length > 0)?_c('div',{key:index},[(item.roles[0] === 'all' ? true : item.roles.includes(_vm.$store.state.authModule.user.profile.customerType))?_c(VListGroup,{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$t(("" + (item.text))))+" ")])]},proxy:true}],null,true)},_vm._l((item.children),function(item,index){return _c(VListItem,{directives:[{name:"show",rawName:"v-show",value:(!(item.text === 'mainNavBar.jobOffers' && _vm.$i18n.locale !== 'hu-HU')),expression:"!(item.text === 'mainNavBar.jobOffers' && $i18n.locale !== 'hu-HU')"}],key:index,on:{"click":function($event){return _vm.$router.push(item.path)}}},[_c(VListItemIcon),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$t(("" + (item.text))))+" ")])],1)],1)}),1):_vm._e()],1):_vm._e()]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }