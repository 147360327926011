import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"frag",rawName:"v-frag"}]},[_c(VToolbar,{attrs:{"fixed":"","flat":"","color":"white"}},[_c('div',[_c(VImg,{staticStyle:{"cursor":"pointer"},attrs:{"src":require("../../assets/logo/TMX_logo.png"),"height":"48","width":"101"},on:{"click":_vm.onLogoClick}})],1),_c(VSpacer),_c(VToolbarItems,[_vm._l((_vm.$store.state.mainMenu.mainMenu),function(item,index){return [(!item.children || item.children.length === 0)?[(item.showOnDesktop && item.safetyMode === 0)?_c(VBtn,{key:index,attrs:{"text":"","color":"primary"},on:{"click":function($event){return item.click()}}},[_vm._v(" "+_vm._s(_vm.$t(("" + (item.text))))+" ")]):_vm._e()]:_vm._e(),(item.children && item.children.length > 0)?[(item.safetyMode === 0)?_c(VMenu,{key:index,attrs:{"offset-y":"","transition":"scroll-y-transition","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({key:index,attrs:{"text":"","color":"primary"}},on),[_vm._v(" "+_vm._s(_vm.$t(("" + (item.text))))+" ")])]}}],null,true)},[_c(VList,[_vm._l((item.children),function(child,index){return [(child.safetyMode === 0)?_c(VListItem,{directives:[{name:"show",rawName:"v-show",value:(!(item.text === 'mainNavBar.jobOffers' && _vm.$i18n.locale !== 'hu-HU')),expression:"!(item.text === 'mainNavBar.jobOffers' && $i18n.locale !== 'hu-HU')"}],key:index,on:{"click":function($event){return child.click()}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t(("" + (child.text)))))])],1):_vm._e()]})],2)],1):_vm._e()]:_vm._e()]}),(_vm.$store.state.authModule.isAuthenticated && _vm.$store.state.authModule.user)?_c('div',{staticClass:"body-2 primary--text text--darken-2 ml-4 mr-2",staticStyle:{"display":"flex","align-items":"center"}},[(_vm.$store.state.authModule.user.profile.customerType === 'B2C' || _vm.$store.state.authModule.user.profile.customerType === 'EMPLOYEE'
            || _vm.$store.state.authModule.user.profile.customerType === 'MOST-B2C')?_c('div',[_vm._v(" "+_vm._s(_vm.$t('greetings'))+" "+_vm._s(_vm.$store.state.authModule.user.profile.lastName)+" "+_vm._s(_vm.$store.state.authModule.user.profile.firstName)+"! ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t('greetings'))+" "+_vm._s(_vm.$store.state.authModule.user.profile.companyName)+" "+_vm._s(_vm.$store.state.authModule.user.profile.businessForm.name)+"! ")])]):_vm._e(),(_vm.$store.state.authModule.isAuthenticated && _vm.$store.state.authModule.user)?[_c(VMenu,{attrs:{"offset-y":"","transition":"scroll-y-transition","left":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [(_vm.$store.state.authModule.user.profile.customerType === 'B2C' || _vm.$store.state.authModule.user.profile.customerType === 'EMPLOYEE'
                    || _vm.$store.state.authModule.user.profile.customerType === 'MOST-B2C')?_c(VIcon,_vm._g({class:_vm.$vuetify.breakpoint.smAndDown ? 'ml-4': '',attrs:{"color":"light-green darken-1"}},on),[_vm._v(" mdi-account-circle ")]):_c(VIcon,_vm._g({class:_vm.$vuetify.breakpoint.smAndDown ? 'ml-4': '',attrs:{"color":"orange darken-1"}},on),[_vm._v(" mdi-account-supervisor-circle ")])]}}],null,false,1332977109)},[_c(VList,[(_vm.$store.state.authModule.isAuthenticated && _vm.loginMethod === 'jz:00=p+')?_c(VListItem,{on:{"click":_vm.goToPersonalSite}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('personalSite')))])],1):_vm._e(),(_vm.$store.state.authModule.isAuthenticated)?_c(VListItem,{on:{"click":_vm.onLogoutClick}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('mainNavBar.logout')))])],1):_vm._e()],1)],1)]:[_c(VMenu,{attrs:{"offset-y":"","transition":"scroll-y-transition","left":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"primary darken-1"}},on),[_vm._v("mdi-account-circle")])]}}])},[_c(VList,[_c(VListItem,{directives:[{name:"show",rawName:"v-show",value:(!_vm.$store.state.authModule.isAuthenticated),expression:"!$store.state.authModule.isAuthenticated"}],on:{"click":function($event){return _vm.$router.push({name: 'login'})}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('login')))])],1),_c(VListItem,{directives:[{name:"show",rawName:"v-show",value:(!_vm.$store.state.authModule.isAuthenticated),expression:"!$store.state.authModule.isAuthenticated"}],on:{"click":function($event){return _vm.$router.push({name: 'login'})}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('forgotPassword')))])],1)],1)],1)],_c('Notifications'),(_vm.$showLanguageDropdown)?[_c('LanguageChangeDropdown')]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }