/* eslint-disable no-shadow */
import { APIGET } from 'src/utils/apiHelper.js';
import { showB2CCardsOnHomePage, showLoggedInServiceCardsOnHomePage} from 'src/utils/systemParameters';
// initial state
const state = {
  baseServices: [
    {
      title: 'services.simpleRepair',
      image: 'images/brand-logos/tmx-negyzet.png',
      path: {
        name: 'mobilfutar-gyartok',
      },
      roles: ['B2C'],
      needLogin: false,
      showInfoIcon: false,
    },
    {
      title: 'services.asuw',
      image: 'images/brand-logos/tmx-negyzet.png',
      path: {
        name: 'biztositasi-szerviz',
      },
      roles: ['B2C'],
      needLogin: false,
      showInfoIcon: false,
    },
    {
      title: 'services.swPickup',
      image: 'images/brand-logos/tmx-negyzet.png',
      path: {
        name: 'szervizgarancia-futarszolgaltatas',
        params: { identicalHash: 'uj-megrendeles' },
      },
      roles: ['B2C'],
      showInfoIcon: true,
    },
    /*{
      title: 'services.flottakezeles',
      image: 'images/brand-logos/tmx-negyzet.png',
      path: {
        name: 'flottakezeles',
      },
      roles: ['FLEET', 'DISTRIBUTOR', 'STORE_01', 'RETAILER', 'OTHER_01'],
      showCard: showLoggedInServiceCardsOnHomePage,
      showInfoIcon: false,
    },*/
    {
      title: 'dolgozoknak',
      image: 'images/brand-logos/tmx-negyzet.png',
      path: {
        name: 'dolgozoknak',
      },
      roles: ['EMPLOYEE'],
      needLogin: true,
      showInfoIcon: false,
    },
    {
      title: 'services.telekomService',
      image: 'images/brand-logos/telekom.png',
      path: {
        name: 'telekomHome',
        url: '',
      },
      isExternalLink: false,
      roles: ['B2C'],
      needLogin: false,
      showInfoIcon: false,
    },
    /*{
      title: 'services.telenorService',
      image: 'images/brand-logos/yettelKEK640.png',
      path: {
        name: 'telenorHome',
        url: '',
      },
      isExternalLink: false,
      roles: ['B2C'],
      needLogin: false,
      showInfoIcon: false,
    },*/
    /*{
      title: 'services.vodafoneService',
      image: 'images/brand-logos/vodafone.png',
      path: {
        name: 'vodafoneHome',
        url: '',
      },
      isExternalLink: false,
      roles: ['B2C'],
      needLogin: false,
      showInfoIcon: false,
    },*/
  ],
  insuranceServices: [
    {
      title: 'services.insuranceService',
      image: 'images/brand-logos/tmx-negyzet.png',
      path: {
        name: 'biztositasi-szerviz',
      },
      showCard: true,
      showInfoIcon: false,
    },
    {
      title: 'services.mostmobil',
      image: 'images/brand-logos/tmx-negyzet.png',
      path: {
        name: 'mostmobil-szerviz',
      },
      showCard: true,
      showInfoIcon: false,
    },
  ],
  services: [
    //
  ],
};

// getters
const getters = {
  //
};

// actions
const actions = {
  /**
   *
   * /service-warranty-rules-fits/{crmRequestId}
   *
   * @param context
   * @param credentials
   * @returns {Promise<{data: [], message: string, status: number}|{data: [], message: string, status: number}|*>}
   * @constructor
   */
  async ServiceWarrantyRulesFits(context, credentials) {
    const response = await APIGET(`service-warranty-rules-fits/${credentials}`, {}, false);
    return response.data;
  }
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
