import Vue from 'vue';

export default Vue.mixin({
  data() {
    return {
      darkMode: false,
      languages: ['hu-HU', 'en-EN', 'de-DE', 'sl-SL'],
      publicPath: process.env.BASE_URL,
      onlyHunEnabled: true,
    };
  },
  methods: {
    detectLanguage() {
      if (this.$store.state.authModule.isAuthenticated) {
        // console.log('detectLanguage() van belépett user...');
        // console.log('this.$i18n.locale: ', this.$i18n.locale);
      } else {
        // console.log('detectLanguage() nincs belépett user...');
        if (!localStorage.getItem('language')) {
          // console.log('nincs nyelvi token');
          localStorage.setItem('language', navigator.language);
          this.$i18n.locale = navigator.language;
          const lang = localStorage.getItem('language');
          if (this.onlyHunEnabled) {
            this.$i18n.locale = 'hu-HU';
            this.$vuetify.lang.current = 'hu';
          } else {
            if (lang === 'hu' || lang === 'hu-HU') {
              this.$i18n.locale = 'hu-HU';
              this.$vuetify.lang.current = 'hu';
            } else if (lang === 'en' || lang === 'en-EN') {
              this.$i18n.locale = 'en-EN';
              this.$vuetify.lang.current = 'en';
            } else if (lang === 'de' || lang === 'de-DE') {
              this.$i18n.locale = 'de-DE';
              this.$vuetify.lang.current = 'de';
            } else if (lang === 'sl' || lang === 'sl-SL') {
              this.$i18n.locale = 'sl-SL';
              this.$vuetify.lang.current = 'sl';
            } else {
              this.$i18n.locale = 'en-EN';
              this.$vuetify.lang.current = 'en';
            }
          }
          // console.log('this.$i18n.locale: ', this.$i18n.locale);
        } else {
          // console.log('van nyelvi token. localStorage.language: ', localStorage.getItem('language'));
          const lang = localStorage.getItem('language');
          if (this.onlyHunEnabled) {
            this.$i18n.locale = 'hu-HU';
            this.$vuetify.lang.current = 'hu';
          } else {
            if (lang === 'hu' || lang === 'hu-HU') {
              this.$i18n.locale = 'hu-HU';
              this.$vuetify.lang.current = 'hu';
            } else if (lang === 'en' || lang === 'en-EN') {
              this.$i18n.locale = 'en-EN';
              this.$vuetify.lang.current = 'en';
            } else if (lang === 'de' || lang === 'de-DE') {
              this.$i18n.locale = 'de-DE';
              this.$vuetify.lang.current = 'de';
            } else if (lang === 'sl' || lang === 'sl-SL') {
              this.$i18n.locale = 'sl-SL';
              this.$vuetify.lang.current = 'sl';
            } else {
              this.$i18n.locale = 'en-EN';
              this.$vuetify.lang.current = 'en';
            }
          }
          // this.$i18n.locale = localStorage.getItem('language');
          // console.log('this.$i18n.locale: ', this.$i18n.locale);
        }
      }
    },
    changeLanguage(lang) {
      if (this.languages.includes(lang)) {
        localStorage.setItem('language', lang);
        // localStorage.setItem('language', 'hu-HU');
        // console.log('navigator.language: ', navigator.language);
        // console.log('localStorage.language: ', localStorage.getItem('language'));
        // this.$store.commit("setLocale", lang);
        this.$i18n.locale = lang;
        // this.$i18n.locale = 'hu-HU';
        this.$vuetify.lang.current = lang.substring(0, 2);
        // console.log('this.$i18n.locale: ', this.$i18n.locale);
        // window.location.reload();
      } else {
        // console.log('nincs ilyen nyelv!');
      }
    },
  },
});
