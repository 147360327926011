/* eslint-disable no-shadow */
import { APIPOST, APIGET } from "../utils/apiHelper";
import { saveLoginMethod, saveToken } from 'src/utils/jwtHelper';

// initial state
const state = {
  //
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async TrackingRequest(context, loginForm) {
    const formData = new FormData();
    const tempForm = loginForm;
    const formJSON = JSON.stringify(tempForm);
    formData.append('data', formJSON);
    return await APIPOST('login-request', formData);
  },
  async loginProcess(context, verificationString) {
    const response = await APIGET(`login-process/${verificationString}`);
    if (response.status === 200) {
      saveToken(verificationString);
      saveLoginMethod('r@gju>.1LA#=');
    }
    return response.data;
    // státusz kódokat az oldal view-nál kell ellenőrizni!!!
  },
  async LoginViewer(context, credentials) {
    const response = await APIPOST('login-viewer', credentials, false);
    return response;
  },
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
