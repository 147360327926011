/* eslint-disable no-shadow */
import { APIGET, APIPOST } from 'src/utils/apiHelper';

// initial state
const state = {
  failures: [
    { text: 'Kijelzőcsere', id: 1, icon: 'mdi-cellphone' },
    { text: 'Akkumulátorcsere', id: 2, icon: 'mdi-battery-alert' },
    { text: 'Hátlapcsere', id: 3, icon: 'mdi-camera-rear-variant' },
    { text: 'Beázott telefon', id: 4, icon: 'mdi-water-alert' },
    { text: 'Egyéb hiba', id: 5, icon: 'mdi-alert' },
  ],
  accessories: [
    { text: 'Töltőfej', id: 1, icon: 'mdi-power-plug' },
    { text: 'Adatkábel', id: 2, icon: 'mdi-cable-data' },
    { text: 'Headset', id: 3, icon: 'mdi-headset' },
  ],
  calculatorObject: null,
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async GetFonixProductGroupsEnabled(context, credentials) {
    const response = await APIGET('fonix-product-groups/enabled');
    if (response.status === 200) {
      // context.commit('setProductGroups', response.data.data);
      return response.data.data;
    }
    // return response.status === 200;
  },
  async GetFonixBrandsByProductGroupId(context, credentials) {
    const response = await APIGET(`fonix-product-groups/${credentials}/brands-enabled`);
    if (response.status === 200) {
      // context.commit('setBrands', response.data.data);
      return response.data.data;
    }
    // return response.status === 200;
  },
  async GetFonixProductTypesByBrandId(context, credentials) {
    const response = await APIGET(`fonix-brands/${credentials}/product-types`);
    if (response.status === 200) {
      // context.commit('setBrands', response.data.data);
      return response.data.data;
    }
    // return response.status === 200;
  },
  async GetFonixColorsByProductTypeId(context, credentials) {
    const response = await APIGET(`fonix-product-types/${credentials}/colors`);
    if (response.status === 200) {
      // context.commit('setColors', response.data.data);
      return response.data.data;
    }
    // return response.status === 200;
  },
  async SendCalculateForm(context, credentials) {
    const response = await APIPOST('calculator/repair', credentials);
    // console.log('SendCalculateForm response: ', response);
    return response;
  },
  setCalculatorObjectAction(context, credentials) {
    context.commit('setCalculatorObject', credentials);
  },
};

// mutations
const mutations = {
  setProductGroups(state, payload) {
    state.productGroups = payload;
  },
  setBrands(state, payload) {
    state.brands = payload;
  },
  setProductTypes(state, payload) {
    state.productTypes = payload;
  },
  setColors(state, payload) {
    state.colors = payload;
  },
  setCalculatorObject(state, payload) {
    state.calculatorObject = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
