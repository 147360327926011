<template>
  <div v-frag>
    <v-app-bar app fixed color="white" height="32">
      <v-icon @click="toggleMiniVariant" color="primary" v-if="$vuetify.breakpoint.mdAndUp">
        {{ miniVariant ? 'mdi-menu' : 'mdi-backburger' }}
      </v-icon>
      <v-icon @click="toggleNavigationDrawer" color="primary" v-if="$vuetify.breakpoint.smAndDown">
        {{ navigationDrawer ? 'mdi-backburger' : 'mdi-menu' }}
      </v-icon>
      <div class="body-2 text-uppercase font-weight-bold primary--text mx-4" v-if="$vuetify.breakpoint.mdAndUp">
        Mobilfutár
      </div>
      <!--<v-divider vertical></v-divider>
      <div class="caption mx-4" v-if="$vuetify.breakpoint.mdAndUp">
        Kilépés: 16:22
      </div>-->
      <v-spacer />
      <!--<v-chip color="primary" outlined small class="mr-4" @click="$router.push({name: 'beerkezo-uzenetek'})">
        <v-icon left size="20">
          mdi-email-outline
        </v-icon>
        88
      </v-chip>
      <v-divider vertical></v-divider>-->
      <div class="body-2 primary--text ml-4 mr-2" v-if="$vuetify.breakpoint.mdAndUp">
        <div v-if="$store.state.authModule.user.profile.customerType === 'B2C' || $store.state.authModule.user.profile.customerType === 'EMPLOYEE'
              || $store.state.authModule.user.profile.customerType === 'MOST-B2C'">
          {{ $t('greetings') }} {{ $store.state.authModule.user.profile.lastName }} {{ $store.state.authModule.user.profile.firstName }}!
        </div>
        <div v-else>
          {{ $t('greetings') }} {{ $store.state.authModule.user.profile.companyName }} {{ $store.state.authModule.user.profile.businessForm.name }}!
        </div>
      </div>
      <div v-frag>
        <v-icon v-if="$store.state.authModule.user.profile.customerType === 'B2C' || $store.state.authModule.user.profile.customerType === 'EMPLOYEE'
                 || $store.state.authModule.user.profile.customerType === 'MOST-B2C'"
                color="light-green darken-1"
                :class="$vuetify.breakpoint.smAndDown ? 'ml-4': ''">
          mdi-account-circle
        </v-icon>
        <v-icon v-else color="orange darken-1" :class="$vuetify.breakpoint.smAndDown ? 'ml-4': ''">
          mdi-account-supervisor-circle
        </v-icon>
      </div>
      <!-- ide jön a nyelvválasztó -->
      <template v-if="$showLanguageDropdown">
        <LanguageChangeDropdown></LanguageChangeDropdown>
      </template>
    </v-app-bar>
    <!-- SIDEBAR START -->
    <v-navigation-drawer
      v-model="navigationDrawer"
      :mini-variant="$vuetify.breakpoint.mdAndUp ? miniVariant : false"
      mini-variant-width="72"
      app
      width="280"
      :permanent="$vuetify.breakpoint.mdAndUp"
      color="#37474F"
      class="pa-4">
      <div class="py-4">
        <v-img
          :src="require(`../../assets/logo/mobilfutarLogoNew@211x60.png`)"
          height="60"
          width="212"
          class="mx-auto"
          v-if="!miniVariant"
        />
        <v-img
          :src="require(`../../assets/logo/mobilfutarLogoNew@40x40.png`)"
          height="40"
          width="40"
          class="mx-auto"
          v-else
        />
      </div>
      <v-divider class="white"></v-divider>
      <v-tooltip :right="miniVariant" :top="!miniVariant" v-if="$store.state.authModule.user.profile.customerType !== 'PREALERT'">
        <template v-slot:activator="{ on }">
          <v-btn color="accent" class="my-6" block x-large v-on="on" @click="newOrderPage" v-show="!miniVariant">
            <v-icon left>
              mdi-plus
            </v-icon>
            <span>
              {{ $t('button.newOrder') }}
            </span>
          </v-btn>
          <v-btn icon dark class="my-6 accent" v-on="on" @click="newOrderPage" v-show="miniVariant">
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t('button.newOrder') }}
        </span>
      </v-tooltip>
      <v-list>
        <template v-for="(item, index) in $store.state.innerSideNav.sideNav">
          <div v-if="!item.children || item.children.length === 0" :key="index">
            <!-- DOLGOZÓI ÁG START -->
            <template v-if="$store.state.authModule.user.profile.customerType === 'EMPLOYEE'">
              <v-list-item :key="index" @click="item.click()" class="px-0"
                           v-if="!item.ura || uras.includes(item.ura)">
                <v-list-item-icon>
                  <v-tooltip :right="miniVariant" :top="!miniVariant">
                    <template v-slot:activator="{ on }">
                      <v-icon color="primary" v-text="item.icon" v-on="on"></v-icon>
                    </template>
                    <span>{{ $t(`${item.text}`) }}</span>
                  </v-tooltip>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="white--text font-weight-bold">
                    {{ $t(`${item.text}`) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <!-- DOLGOZÓI ÁG END -->
            <template v-else>
              <v-list-item :key="index" @click="item.click()" class="px-0"
                           v-if="item.roles[0] === 'all' ? true : item.roles.includes($store.state.authModule.user.profile.customerType)">
                <v-list-item-icon>
                  <v-tooltip :right="miniVariant" :top="!miniVariant">
                    <template v-slot:activator="{ on }">
                      <v-icon color="primary" v-text="item.icon" v-on="on"></v-icon>
                    </template>
                    <span>{{ $t(`${item.text}`) }}</span>
                  </v-tooltip>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="white--text font-weight-bold">
                    {{ $t(`${item.text}`) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-divider class="ml-12"></v-divider>
          </div>
          <div v-else-if="item.children && item.children.length > 0" :key="index">
            <v-list-group v-if="item.roles[0] === 'all' ? true : item.roles.includes($store.state.authModule.user.profile.customerType)">
              <template v-slot:activator>
                <v-list-item-title>
                  {{ $t(`${item.text}`) }}
                </v-list-item-title>
              </template>
              <v-list-item v-for="(item, index) in item.children" :key="index"
                           @click="$router.push(item.path)"
                           v-show="!(item.text === 'mainNavBar.jobOffers' && $i18n.locale !== 'hu-HU')">
                <v-list-item-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t(`${item.text}`) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
        </template>
        <!--<v-list-item @click="LogoutFunction" class="px-0">
          <v-list-item-icon>
            <v-tooltip :right="miniVariant" :top="!miniVariant">
              <template v-slot:activator="{ on }">
                <v-icon color="primary" v-on="on">
                  mdi-logout-variant
                </v-icon>
              </template>
              <span>{{ $t(`innerSideNav.exit`) }}</span>
            </v-tooltip>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text font-weight-bold">
              {{ $t(`innerSideNav.exit`) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>-->
      </v-list>
    </v-navigation-drawer>
    <!-- SIDEBAR END -->
  </div>
</template>

<script>
import LanguageChangeDropdown from 'src/components/language/LanguageChangeDropdown.vue';
import { getURA } from 'src/utils/jwtHelper';
import Notifications from 'src/components/shared/Notifications.vue';

export default {
  name: 'InnerHeader',
  props: {
    //
  },
  components: {
    LanguageChangeDropdown, Notifications,
  },
  async beforeMount() {
    if (this.$store.state.authModule.user.profile.customerType === 'EMPLOYEE') {
      const ur = await getURA();
      this.uras = ur.split(',');
      // console.log(this.uras);
    }
  },
  data() {
    return {
      navigationDrawer: true,
      miniVariant: false,
      uras: [],
    };
  },
  methods: {
    toggleNavigationDrawer() {
      this.navigationDrawer = !this.navigationDrawer;
    },
    toggleMiniVariant() {
      this.miniVariant = !this.miniVariant;
    },
    newOrderPage() {
      if (this.$store.state.authModule.user.profile.customerType === 'B2C') {
        this.$router.push({ name: 'igenyles' });
      } else if (this.$store.state.authModule.user.profile.customerType === 'FLEET') {
        this.$router.push({ name: 'flottakezeles' });
      } else if (this.$store.state.authModule.user.profile.customerType === 'EMPLOYEE') {
        this.$router.push({ name: 'dolgozoknak' });
      } else if (this.$store.state.authModule.user.profile.customerType === 'DISTRIBUTOR') {
        this.$router.push({ name: 'flottakezeles' });
      } else if (this.$store.state.authModule.user.profile.customerType === 'STORE_01') {
        this.$router.push({ name: 'flottakezeles' });
      } else if (this.$store.state.authModule.user.profile.customerType === 'RETAILER') {
        this.$router.push({ name: 'flottakezeles' });
      } else if (this.$store.state.authModule.user.profile.customerType === 'OTHER_01') {
        this.$router.push({ name: 'flottakezeles' });
      }
    },
  },
};
</script>

<style scoped>

</style>
