<template>
  <v-navigation-drawer
    v-model="navigationDrawer"
    app
    width="280"
    mobile-break-point="959"
    color="#37474F"
    class="pa-4">
    <div class="py-4">
      <v-img
        :src="require(`../../assets/logo/tmx-futar-logo.png`)"
        height="60"
        width="212"
        class="mx-auto"
        v-if="!miniVariant"
      />
      <v-img
        :src="require(`../../assets/logo/tmx-futar-logo-icon.png`)"
        height="60"
        width="60"
        class="mx-auto"
        v-else
      />
    </div>
    <v-divider class="white"></v-divider>
    <v-tooltip :right="miniVariant" :top="!miniVariant">
      <template v-slot:activator="{ on }">
        <v-btn color="accent" class="my-6" block x-large v-on="on" @click="newOrderPage">
          <v-icon :left="!miniVariant">
            mdi-plus
          </v-icon>
          <span v-if="!miniVariant">
            {{ $t('button.newOrder') }}
          </span>
        </v-btn>
      </template>
      <span>
        {{ $t('button.newOrder') }}
      </span>
    </v-tooltip>
    <v-list>
      <template v-for="(item, index) in $store.state.innerSideNav.sideNav">
        <div v-if="!item.children || item.children.length === 0" :key="index">
          <v-list-item :key="index" @click="item.click()" class="px-0">
            <v-list-item-icon>
              <v-tooltip :right="miniVariant" :top="!miniVariant">
                <template v-slot:activator="{ on }">
                  <v-icon color="primary" v-text="item.icon" v-on="on"></v-icon>
                </template>
                <span>{{ $t(`${item.text}`) }}</span>
              </v-tooltip>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="white--text font-weight-bold">
                {{ $t(`${item.text}`) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="ml-12"></v-divider>
        </div>
        <div v-else-if="item.children && item.children.length > 0" :key="index">
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-title>
                {{ $t(`${item.text}`) }}
              </v-list-item-title>
            </template>
            <v-list-item v-for="(item, index) in item.children" :key="index"
                         @click="$router.push(item.path)"
                         v-show="!(item.text === 'mainNavBar.jobOffers' && $i18n.locale !== 'hu-HU')">
              <v-list-item-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t(`${item.text}`) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>
      </template>
      <!--<v-list-item @click="LogoutFunction" class="px-0">
        <v-list-item-icon>
          <v-tooltip :right="miniVariant" :top="!miniVariant">
            <template v-slot:activator="{ on }">
              <v-icon color="primary" v-on="on">
                mdi-logout-variant
              </v-icon>
            </template>
            <span>{{ $t(`innerSideNav.exit`) }}</span>
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="white--text font-weight-bold">
            {{ $t(`innerSideNav.exit`) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>-->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'SideNavBar',
  props: {
    navigationDrawer: Boolean,
    miniVariant: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    newOrderPage() {
      this.$router.push({ name: 'igenyles' });
    },
    async LogoutFunction() {
      await this.$store.dispatch('Logout');
    },
  },
};
</script>

<style scoped>
</style>
