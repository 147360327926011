const state = {
  warrantyType: [
    {
      id: 1,
      name: 'Garanciális javítás',
    },
    {
      id: 2,
      name: 'Fizetős javítás',
    },
    {
      id: 3,
      name: 'Biztosításos javítás',
    },
  ],
  warrantyTypeEN: [
    {
      id: 1,
      name: 'In warranty service',
    },
    {
      id: 2,
      name: 'Out of warranty service',
    },
    {
      id: 3,
      name: 'Insurance repair',
    },
  ],
  warrantyTypeDE: [
    {
      id: 1,
      name: 'Garantiereparatur',
    },
    {
      id: 2,
      name: 'Nein Garantiereparatur',
    },
    {
      id: 3,
      name: 'Versicherung repartur',
    },
  ],
  simLock: [
    { id: 1, name: 'Magyar Telekom' },
    { id: 2, name: 'Telenor' },
    { id: 3, name: 'Vodafone' },
    { id: 4, name: 'Független' },
    { id: 5, name: 'Egyéb' },
  ],
  simLockEN: [
    { id: 1, name: 'Magyar Telekom' },
    { id: 2, name: 'Telenor' },
    { id: 3, name: 'Vodafone' },
    { id: 4, name: 'Unlocked' },
    { id: 5, name: 'Other' },
  ],
  simLockDE: [
    { id: 1, name: 'Magyar Telekom' },
    { id: 2, name: 'Telenor' },
    { id: 3, name: 'Vodafone' },
    { id: 4, name: 'Unlocked' },
    { id: 5, name: 'Other' },
  ],
  brands: [
    { id: 56, name: 'Acer' },
    { id: 89, name: 'AEG' },
    { id: 73, name: 'Akai' },
    { id: 1, name: 'Alcatel' },
    { id: 80, name: 'Alcor' },
    { id: 109, name: 'Allterco' },
    { id: 49, name: 'Allview' },
    { id: 2, name: 'Apple' },
    { id: 74, name: 'Archos' },
    { id: 3, name: 'Asus' },
    { id: 110, name: 'Bauknecht' },
    { id: 28, name: 'Becker' },
    { id: 43, name: 'Beex' },
    { id: 30, name: 'BlackBerry' },
    { id: 87, name: 'Boway' },
    { id: 111, name: 'Canon' },
    { id: 4, name: 'CAT' },
    { id: 134, name: 'Cat Berlin' },
    { id: 119, name: 'Clevo' },
    { id: 75, name: 'Concorde' },
    { id: 81, name: 'Coolpad' },
    { id: 126, name: 'Crosscall' },
    { id: 138, name: 'CUBOT' },
    { id: 133, name: 'Cyrus' },
    { id: 98, name: 'D-link' },
    { id: 112, name: 'Dell' },
    { id: 129, name: 'Digi' },
    { id: 52, name: 'Doogee' },
    { id: 35, name: 'Doro' },
    { id: 67, name: 'Elephone' },
    { id: 32, name: 'Emporia' },
    { id: 132, name: 'Energizer' },
    { id: 85, name: 'Estar' },
    { id: 34, name: 'Evolveo' },
    { id: 140, name: 'FOSSIL' },
    { id: 88, name: 'Fujitsu' },
    { id: 57, name: 'GIGABYTE' },
    { id: 5, name: 'GoPro' },
    { id: 48, name: 'GSMART' },
    { id: 107, name: 'Hammer' },
    { id: 103, name: 'Homtom' },
    { id: 6, name: 'Honor' },
    { id: 13, name: 'HP' },
    { id: 39, name: 'HTC' },
    { id: 101, name: 'HTM' },
    { id: 7, name: 'Huawei' },
    { id: 25, name: 'Hyundai' },
    { id: 94, name: 'iLike' },
    { id: 51, name: 'Iocean' },
    { id: 62, name: 'Jiayu' },
    { id: 97, name: 'KAZAM' },
    { id: 136, name: 'KINDLE' },
    { id: 116, name: 'Kobo' },
    { id: 102, name: 'KODAK' },
    { id: 139, name: 'Koobe' },
    { id: 122, name: 'Land Rover' },
    { id: 77, name: 'Lazer' },
    { id: 66, name: 'Leagoo' },
    {id: 8, name: 'Lenovo' },
    {id: 9, name: 'Lenovo NPC' },
    {id: 10, name: 'Lenovo think' },
    { id: 11, name: 'LG' },
    { id: 137, name: 'MAP' },
    { id: 113, name: 'Medion' },
    { id: 108, name: 'Meizu' },
    { id: 72, name: 'Microsoft' },
    { id: 93, name: 'MobiWire' },
    { id: 114, name: 'MSI' },
    { id: 117, name: 'MyPhone' },
    { id: 120, name: 'Navitel' },
    { id: 71, name: 'Navon' },
    { id: 60, name: 'Nextbook' },
    { id: 5, name: 'Nokia' },
    { id: 131, name: 'Odys' },
    { id: 135, name: 'OnePlus' },
    { id: 54, name: 'Option' },
    { id: 50, name: 'Overmax' },
    { id: 121, name: 'Packard Bell' },
    { id: 145, name: 'Panasonic' },
    { id: 53, name: 'Pipo' },
    { id: 104, name: 'Pocketbook' },
    { id: 41, name: 'Polaroid' },
    { id: 91, name: 'Posh' },
    { id: 55, name: 'Prestigio' },
    { id: 78, name: 'Qilive' },
    { id: 128, name: 'RAZER' },
    { id: 12, name: 'Samsung' },
    { id: 65, name: 'Samsung CAM' },
    { id: 29, name: 'Samsung NPC' },
    { id: 86, name: 'Selecline' },
    { id: 90, name: 'Sencor' },
    { id: 96, name: 'Sonim' },
    { id: 765, name: 'Sony' },
    { id: 82, name: 'Tecdesk' },
    { id: 92, name: 'Telefunken' },
    { id: 36, name: 'Telme' },
    { id: 130, name: 'Tinno' },
    { id: 115, name: 'Toshiba' },
    { id: 123, name: 'TrekStor' },
    { id: 42, name: 'Ubiquiti' },
    { id: 61, name: 'UMI' },
    { id: 83, name: 'Unitone' },
    { id: 106, name: 'Vernee' },
    { id: 69, name: 'Wayteq' },
    { id: 13, name: 'Xiaomi' },
    { id: 127, name: 'YU' },
    { id: 47, name: 'Yulong' },
    { id: 68, name: 'Zopo' },
    { id: 26, name: 'ZTE' },
  ],
  brandsTelekom: [
    {id: 1, name: 'Alcatel' },
    {id: 2, name: 'Apple' },
    {id: 3, name: 'ASUS' },
    {id: 4, name: 'CAT' },
    {id: 5, name: 'GoPro' },
    {id: 6, name: 'Honor' },
    {id: 7, name: 'Huawei' },
    {id: 8, name: 'Lenovo' },
    {id: 9, name: 'Lenovo NPC' },
    {id: 10, name: 'Lenovo think' },
    {id: 11, name: 'LG' },
    {id: 12, name: 'Samsung' },
    {id: 13, name: 'Xiaomi' },
  ],
  brandsTelenor: [
    {id: 1, name: 'Alcatel' },
    {id: 2, name: 'Apple' },
    {id: 3, name: 'ASUS' },
    {id: 4, name: 'CAT' },
    {id: 5, name: 'GoPro' },
    {id: 6, name: 'Honor' },
    {id: 7, name: 'Huawei' },
    {id: 8, name: 'Lenovo' },
    {id: 9, name: 'Lenovo NPC' },
    {id: 10, name: 'Lenovo think' },
    {id: 11, name: 'LG' },
    {id: 12, name: 'Samsung' },
    {id: 13, name: 'Xiaomi' },
  ],
  brandsTMX: [
    { id: 58, name: 'Asus' },
    { id: 40, name: 'CAT' },
    { id: 18, name: 'Huawei' },
    { id: 45, name: 'Lenovo' },
    { id: 44, name: 'Lenovo NPC' },
    { id: 84, name: 'Lenovo Think' },
    { id: 9, name: 'Samsung' },
    { id: 65, name: 'Samsung CAM' },
    { id: 76, name: 'Xiaomi' },
  ],
  brandsNew: [
    {id: 1, name: 'Alcatel' },
    {id: 2, name: 'Apple' },
    {id: 3, name: 'ASUS' },
    {id: 4, name: 'CAT' },
    {id: 5, name: 'GoPro' },
    {id: 6, name: 'Honor' },
    {id: 7, name: 'Huawei' },
    {id: 8, name: 'Lenovo' },
    {id: 9, name: 'Lenovo NPC' },
    {id: 10, name: 'Lenovo think' },
    {id: 11, name: 'LG' },
    {id: 12, name: 'Samsung' },
    {id: 13, name: 'Xiaomi' },
  ],
  productGroups: [
    { id: 1, name: 'Mobiltelefon' },
    { id: 2, name: 'Tablet' },
    { id: 3, name: 'Laptop' },
  ],
  origRequestSource: [
    { id: 1, name: 'mostmobil.hu' },
    { id: 2, name: 'mobilfutár' },
  ],
  szervizProductGroups: [
    { id: 1, name: 'Mobiltelefon' },
    { id: 2, name: 'Tablet' },
    { id: 3, name: 'Okosóra, aktivitásmérő' },
  ],
  paymentMethods: [
    {
      id: 1,
      lang: 'hu',
      name: 'Utánvét',
    },
    {
      id: 2,
      lang: 'hu',
      name: 'Átutalás',
    },
    {
      id: 3,
      lang: 'hu',
      name: 'Előre utalás',
    },
    {
      id: 4,
      lang: 'hu',
      name: 'Online fizetés',
    },
    {
      id: 5,
      lang: 'hu',
      name: 'Bankkártyás fizetés',
    },
  ],
  paymentMethodsEN: [
    {
      id: 1,
      lang: 'en',
      name: 'Utánvét - EN',
    },
    {
      id: 2,
      lang: 'en',
      name: 'Átutalás - EN',
    },
    {
      id: 3,
      lang: 'en',
      name: 'Előre utalás - EN',
    },
    {
      id: 4,
      lang: 'en',
      name: 'Online fizetés - EN',
    },
    {
      id: 5,
      lang: 'en',
      name: 'Bankkártyás fizetés - EN',
    },
  ],
  paymentMethodsDE: [
    {
      id: 1,
      lang: 'de',
      name: 'Utánvét - DE',
    },
    {
      id: 2,
      lang: 'de',
      name: 'Átutalás - DE',
    },
    {
      id: 3,
      lang: 'de',
      name: 'Előre utalás - DE',
    },
    {
      id: 4,
      lang: 'de',
      name: 'Online fizetés - DE',
    },
    {
      id: 5,
      code: 'de',
      name: 'Bankkártyás fizetés - DE',
    },
  ],
};

const getters = {

};

const actions = {

};

const mutations = {

};

export default {
  state,
  actions,
  mutations,
  getters,
};
