/* eslint-disable no-shadow */

// initial state
const state = {
  billingAddressType: {
    id: 2,
    lang: 'hu',
    name: 'Számlázási cím',
  },
  shipInAddressType: {
    id: 1,
    lang: 'hu',
    name: 'Szállítási cím',
  },
  shipOutAddressType: {
    id: 3,
    lang: 'hu',
    name: 'Kiszállítási cím',
  },
};

// getters
const getters = {
  //
};

// actions
const actions = {
  //
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
