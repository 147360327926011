/* eslint-disable no-shadow */
import { APIGET } from "../utils/apiHelper";
// initial state
const state = {
  //
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async checkImeiByLuhn(context, imei) {
    return await APIGET(`device-management/luhn/${imei}/hu`);
  }
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
