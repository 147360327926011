/* eslint-disable no-shadow */

// initial state
import { APIGET } from 'src/utils/apiHelper.js';

const state = {
  //
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async GetMieleWorksheetList(context, credentials) {
    const response = await APIGET('customer-requests/miele', {}, true);
    return response.data;
  },
  async GetMieleWorksheetById(context, credentials) {
    const response = await APIGET(`customer-requests/${credentials.id}/show`, {}, true);
    return response.data;
  },
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
