/* eslint-disable no-shadow */

// initial state
const state = {
  //
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async checkFileIsValidPdf(context, file) {
    //
  },
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
