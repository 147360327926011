<template>
  <footer>
    <v-container fluid class="blue-stripes-bg" >
      <v-container>
        <v-row>
          <v-col cols="12" sm="4" md="3">
            <v-img :src="`${publicPath}images/brand-logos/tmx-white.png`" width="131px" height="62px" />
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <ul class="footer_ul" style="list-style: none;">
              <li>
                <a :href="`${publicPath}doc/Complaint_Handling_Policy_TMX_23_11_01_${$i18n.locale.substring( 0, 2 )}.pdf`" target="_blank">
                  {{ $t('complaintPolicy') }}
                </a>
              </li>
              <li>
                <a :href="`${publicPath}doc/Adatvedelmi_Tajekoztato_Honlap_Latogatoknak.pdf`" target="_blank">
                  {{ $t('privacyPolicy') }}
                </a>
              </li>
              <li>
                <a :href="`${publicPath}doc/TMX_Terms_and_Conditions_${$i18n.locale.substring( 0, 2 )}.pdf`" target="_blank">
                  {{ $t('termsAndCond') }}
                </a>
              </li>
              <li class="white--text" @click="$router.push('/generalCosts')" style="cursor: pointer;">
                {{ $t('basicPrices') }}
              </li>
              <li>
                <a href="javascript: Cookiebot.renew()">
                  {{ $t('cookies') }}
                </a>
              </li>
              <li class="white--text" @click="$router.push('/impresszum')" style="cursor: pointer;">
                {{ $t('impress') }}
              </li>
            </ul>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <ul class="footer_ul" style="list-style: none;">
              <!--li>
                <a :href="`${publicPath}doc/Hasznalt_keszulekek_adasvetele_ASZF_Magyar_Telekom_Nyrt.pdf`" target="_blank">
                  {{ $t('usedEquipmentForSale') }} - Magyar Telekom Nyrt.
                </a>
              </li-->
              <!--li>
                <a :href="`${publicPath}doc/Hasznalt_keszulekek_adasvetele_ASZF_Telenor_Zrt.pdf`" target="_blank">
                  {{ $t('usedEquipmentForSale') }} - Telenor Zrt.
                </a>
              </li-->
            </ul>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <ul style="list-style: none;" class="white--text">
              <template v-for="(item, index) in $store.state.mainMenu.mainMenu">
                <template v-if="item.children">
                  <li :key="index" @click="item.children[0].click()" style="cursor: pointer;">
                    {{ $t(`${item.text}`) }}
                  </li>
                </template>
                <template v-else>
                  <li :key="index" @click="item.click()" style="cursor: pointer;">
                    {{ $t(`${item.text}`) }}
                  </li>
                </template>
              </template>
            </ul>
          </v-col>
        </v-row>
      </v-container>
      <v-divider dark style="max-width: 920px;" class="mx-auto"></v-divider>
      <v-container class="text-center white--text text-caption pb-0">
        <div>
          {{ $store.getters.getAppSystemInfoCurrentVersion }}
        </div>
        <div>
          Copyright &copy; {{ year }} TMX Mobile Solution Szerviz Kft. Minden jog fenntartva.
        </div>
      </v-container>
    </v-container>
  </footer>
</template>

<script>
import { version } from '../../../package.json';

export default {
  name: 'OuterFooter',
  data() {
    return {
      publicPath: process.env.BASE_URL,
      version: version,
    };
  },
  methods: {
    //
  },
  computed: {
    year() {
      return new Date().getFullYear();
    }
  },
};
</script>

<style scoped>

</style>
