import Vue from 'vue';

export const SCROLL_TO_TOP_BUTTON_LIMIT = 600;
export const SCROLL_TO_TOP_BUTTON_LIMIT2 = 600;

Vue.prototype.$NEW_ADDRESS_FROM_EXISTING = function newAddressFromExisting(address) {
  this.id = null;
  this.country = {
    countryCode: address.country.countryCode,
    label: address.country.label,
  };
  this.city = address.city;
  this.postalCode = address.postalCode;
  this.publicPlaceName = address.publicPlaceName;
  this.publicPlaceType = address.publicPlaceType;
  this.streetNumber = address.streetNumber;
  this.other = address.other;
  this.label = `${address.postalCode} ${address.city}, ${address.publicPlaceName} ${address.publicPlaceType} ${address.streetNumber}`;
  this.billingName = '';
  this.taxNumber = null;
  this.accountNumber = null;
  this.isDefault = address.isDefault ? 1 : 0;
  this.isCompany = address.isCompany ? 1 : 0;
}

Vue.prototype.$HTTP_OK = 200;
Vue.prototype.$HTTP_CREATED = 201;
Vue.prototype.$HTTP_CUSTOM_ERROR = 422;
