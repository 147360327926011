import Vue from 'vue';
import VueI18n from 'vue-i18n';
import locales from './locales';

Vue.use(VueI18n);

// function loadLocaleMessages () {
//   const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.js$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   console.log(messages)
//   return messages
// }

if (!localStorage.getItem('language')) {
  localStorage.setItem('language', navigator.language);
}

export default new VueI18n({
  // locale: localStorage.getItem('language'),
  // locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  locale: 'hu',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'hu',
  messages: locales,
  silentFallbackWarn: false,
  silentTranslationWarn: false,
  missingWarn: false,
});
