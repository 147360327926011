<template>
  <footer>
    <v-container fluid>
      <v-container class="text-center text-caption pb-0">
        <div>
          {{ $store.getters.getAppSystemInfoCurrentVersion }}
        </div>
        <div>
          Copyright &copy; {{ year }} TMX Mobile Solution Szerviz Kft. Minden jog fenntartva.
        </div>
      </v-container>
    </v-container>
  </footer>
</template>

<script>
import { version } from '../../../package.json';

export default {
  name: 'InnerFooter',
  data() {
    return {
      publicPath: process.env.BASE_URL,
      version: version,
    };
  },
  methods: {
    //
  },
  computed: {
    year() {
      return new Date().getFullYear();
    }
  },
};
</script>

<style scoped>

</style>
