import Vue from 'vue';
import { TMX_URL, CRM_URL } from "../utils/config";

export default Vue.mixin({
  data() {
    return {
      TMXurl: TMX_URL,
      CRMurl: CRM_URL,
    };
  },
  methods: {
    async isEmpty(element) {
      return ((element === undefined)
        || (element === 'undefined')
        || (element === null)
        || (element === '')
        || (element === 'null')
        || (element === 'NaN')
        || (typeof element === 'number' && Number.isNaN(element))
      );
    },
    async isNotEmpty(element) {
      const r = await this.isEmpty(element);
      return !r;
    }
  }
});
