/* eslint-disable no-shadow */
import { APIPOST } from "../utils/apiHelper";

// initial state
const state = {
  //
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async sendCustomerData(context, request) {
    const formData = new FormData();
    const temp = request.form;
    const formJSON = JSON.stringify(temp);
    formData.append('data', formJSON);
    formData.append('invoice', request.attachment.invoice);
    formData.append('warranty_card', request.attachment.warrantyCard);
    const response = await APIPOST('store-management/customer-data/hu', formData);
    return response;
  },
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
