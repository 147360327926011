import axios from 'axios';
import authModule from '../store/auth.module';
import { getToken } from './jwtHelper';
import { BASE_URL, LION_URL } from './config';
import i18n from "../i18n";

async function setAPIurl(apiName) {
  let apiurl = BASE_URL;
  switch (apiName) {
    case 'CRM':
      apiurl = BASE_URL;
      break;
    case 'LION':
      apiurl = LION_URL;
      break;
    default:
      apiurl = BASE_URL;
  }
  return apiurl;
}

const instance = axios.create({
  timeout: 200000,
});

async function htmlStatusRoute(status) {
  if (status === 401) {
    authModule.actions.logout();
    window.location = '/login';
    return true;
  }
  return false;
}

async function setHeaders(needAuth = false) {
  const token = await getToken('token');
  const langCode = i18n.locale.substring(0, 2);
  if (token && needAuth) {
    return { 'Token': `${token}`, 'Language': `${langCode}` };
  }
  return { 'Language': `${langCode}` };
}

async function setHeadersQuote(token) {
  const langCode = i18n.locale.substring(0, 2);
  return { 'Token': `${token}`, 'Language': `${langCode}` };
}

async function setHeadersCsv(needAuth = false) {
  const token = await getToken('token');
  const langCode = i18n.locale.substring(0, 2);
  if (token && needAuth) {
    return { 'Token': `${token}`, 'Language': `${langCode}` };
  }
  return { 'Language': `${langCode}` };
}

async function setGetHeaders(needAuth = false) {
  const token = await getToken('token');
  const langCode = i18n.locale.substring(0, 2);
  if (token && needAuth) {
    return { 'Token': `${token}`, 'Language': `${langCode}` };
  }
  return { 'Language': `${langCode}` };
}

async function setFileDownloadHeaders(needAuth = false) {
  const token = await getToken('token');
  const langCode = i18n.locale.substring(0, 2);
  if (token && needAuth) {
    return { 'Token': `${token}`, 'Language': `${langCode}`, 'Content-Type': 'multipart/form-data' };
  }
  return { 'Language': `${langCode}`, 'Content-Type': 'multipart/form-data' };
}

export async function APIGET(url, params = {}, needAuth = false, apiName = 'CRM') {
  try {
    const resp = await instance({
      method: 'get',
      baseURL: await setAPIurl(apiName),
      url: `${await setAPIurl(apiName)}/${url}`,
      headers: await setGetHeaders(needAuth),
      params,
      validateStatus: function (status) {
        return status >= 200;
      },
      // validateStatus: htmlStatusRoute,
    });
    // console.log(`%cAPIGET - ${url} response: `, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    // console.log(resp);
    if (resp) {
      return resp;
    } else {
      return {
        data: {
          status: 500,
          data: [],
          message: "errorMessages.INTERNAL_SERVER_ERROR",
        },
        status: 500,
      };
    }
  } catch (err) {
    return {
      data: {
        status: 500,
        data: [],
        message: "errorMessages.INTERNAL_SERVER_ERROR",
      },
      status: 500,
    };
  }
}

export async function APIPOST(url, body, needAuth = false, apiName = 'CRM') {
  try {
    const resp = await instance({
      method: 'post',
      baseURL: await setAPIurl(apiName),
      url: `${await setAPIurl(apiName)}/${url}`,
      headers: await setHeaders(needAuth),
      data: body,
      validateStatus: function (status) {
        return status >= 200;
      },
    });
    // console.log(`%cAPIPOST - ${url} response:`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    // console.log(resp);
    if (resp) {
      return resp.data;
    } else {
      return {
        status: 500,
        data: [],
        message: "errorMessages.INTERNAL_SERVER_ERROR",
      };
    }
  } catch (err) {
    // console.log(`%cAPIPOST - ${url} catch error: ${err}`, 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
    return {
      status: 500,
      data: [],
      message: "errorMessages.INTERNAL_SERVER_ERROR",
    };
  }
}

export async function APIPATCH(url, body, needAuth = false, apiName = 'CRM') {
  try {
    const resp = await instance({
      method: 'patch',
      baseURL: await setAPIurl(apiName),
      url: `${await setAPIurl(apiName)}/${url}`,
      headers: await setHeaders(needAuth),
      data: body,
      validateStatus: function (status) {
        return status >= 200;
      },
    });
    // // console.log(`%cAPIPATCH - ${url} response:`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    // console.log(resp);
    if (resp) {
      return resp.data;
    } else {
      return {
        status: 500,
        data: [],
        message: "errorMessages.INTERNAL_SERVER_ERROR",
      };
    }
  } catch (err) {
    // console.log(`%cAPIPATCH - ${url} catch error: ${err}`, 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
    return {
      status: 500,
      data: [],
      message: "errorMessages.INTERNAL_SERVER_ERROR",
    };
  }
}

export async function APIPUT(url, body, needAuth = false, apiName = 'CRM') {
  try {
    const resp = await instance({
      method: 'put',
      baseURL: await setAPIurl(apiName),
      url: `${await setAPIurl(apiName)}/${url}`,
      headers: await setHeaders(needAuth),
      data: body,
      validateStatus: function (status) {
        return status >= 200;
      },
    });
    // console.log(`%cAPIPUT - ${url} response:`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    // console.log(resp);
    if (resp) {
      return resp.data;
    } else {
      return {
        status: 500,
        data: [],
        message: "errorMessages.INTERNAL_SERVER_ERROR",
      };
    }
  } catch (err) {
    // console.log(`%cAPIPATCH - ${url} catch error: ${err}`, 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
    return {
      status: 500,
      data: [],
      message: "errorMessages.INTERNAL_SERVER_ERROR",
    };
  }
}

export async function APIQUOTE(url, body, token, apiName = 'CRM') {
  try {
    const resp = await instance({
      method: 'patch',
      baseURL: await setAPIurl(apiName),
      url: `${await setAPIurl(apiName)}/${url}`,
      headers: await setHeadersQuote(token),
      data: body,
      validateStatus: function (status) {
        return status >= 200;
      },
    });
    // console.log(`%cAPIQUOTE - ${url} response:`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    // console.log(resp);
    if (resp) {
      return resp.data;
    } else {
      return {
        status: 500,
        data: [],
        message: "errorMessages.INTERNAL_SERVER_ERROR",
      };
    }
  } catch (err) {
    // console.log(`%cAPIQUOTE - ${url} catch error: ${err}`, 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
    return {
      status: 500,
      data: [],
      message: "errorMessages.INTERNAL_SERVER_ERROR",
    };
  }
}

export async function APIDELETE(url, params = {}, apiName = 'CRM') {
  try {
    const resp = await instance({
      method: 'delete',
      baseURL: await setAPIurl(apiName),
      url: `${await setAPIurl(apiName)}/${url}`,
      headers: await setHeaders(),
      params,
      validateStatus: function (status) {
        return status >= 200;
      },
      // validateStatus: htmlStatusRoute,
    });
    if (resp.data.error) {
      // console.warn(`APIDELETE - ${url}:`, resp.data);
      return resp.data;
    }
    // console.log(`%cAPIDELETE - ${url} response:`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    // console.log(resp);
    return resp.data;
  } catch (err) {
    // console.log(`%cAPIDELETE - ${url} catch error: ${err}`, 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
    return { error: true, message: err };
  }
}

export async function APIFILEDOWNLOAD(url, body, apiName = 'CRM') {
  try {
    const response = await instance({
      method: 'post',
      baseURL: await setAPIurl(apiName),
      url: `${await setAPIurl(apiName)}/${url}`,
      headers: { 'Content-Type': 'multipart/form-data' },
      responseType: 'blob',
      data: body,
      /*validateStatus: function (status) {
        return status >= 200;
      },*/
    });
    // console.log(`%cAPIFILEDOWNLOAD - ${url} response:`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    // console.log(resp);
    return response;
    /*if (resp) {
      return resp;
    } else {
      return {
        status: 500,
        data: [],
        message: "errorMessages.INTERNAL_SERVER_ERROR",
      };
    }*/
  } catch (error) {
    // console.log(`%cAPIFILEDOWNLOAD - ${url} catch error: ${await error.response.data.text()}`, 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
    return JSON.parse(await error.response.data.text());
    /*return {
      status: 500,
      data: [],
      message: "errorMessages.INTERNAL_SERVER_ERROR",
    };*/
  }
}

export async function APIFILEDOWNLOADGET(url, body = {}, apiName = 'CRM') {
  // console.log('api start');
  try {
    const htmlResponse = await instance({
      method: 'get',
      baseURL: await setAPIurl(apiName),
      url: `${await setAPIurl(apiName)}/${url}`,
      headers: { 'Content-Type': 'multipart/form-data' },
      responseType: 'blob',
      data: body,
    });
    // console.log(`APIFILEDOWNLOADGET - ${url} htmlResponse: `, htmlResponse);
    return htmlResponse;
  } catch (error) {
    return {
      data: {
        status: 999,
        data: [],
        message: `CATCH error: ${error}`,
      },
    };
  }
}

export async function APIFILEDOWNLOADPDFGET(url, body = {}, needAuth = false, apiName = 'CRM') {
  try {
    const htmlResponse = await instance({
      method: 'get',
      baseURL: await setAPIurl(apiName),
      url: `${await setAPIurl(apiName)}/${url}`,
      headers: await setFileDownloadHeaders(needAuth),
      responseType: 'blob',
      data: body,
    });
    // console.log(`APIFILEDOWNLOADPATCH - ${url} htmlResponse: `, htmlResponse);
    return htmlResponse;
  } catch (error) {
    return JSON.parse(await error.response.data.text());
    /*return {
      data: {
        status: 999,
        data: [],
        message: `CATCH error: ${error}`,
      },
    };*/
  }
}

export async function APIFILEDOWNLOADPOST(url, body = {}, needAuth = false, apiName = 'CRM') {
  try {
    const htmlResponse = await instance({
      method: 'post',
      baseURL: await setAPIurl(apiName),
      url: `${await setAPIurl(apiName)}/${url}`,
      headers: await setFileDownloadHeaders(needAuth),
      responseType: 'blob',
      data: body,
    });
    // console.log(`APIFILEDOWNLOADPOST - ${url} htmlResponse: `, htmlResponse);
    return htmlResponse;
  } catch (error) {
    return {
      data: {
        status: 999,
        data: [],
        message: `CATCH error: ${error}`,
      },
    };
  }
}

export async function APIPOSTCSV(url, body, needAuth = false, apiName = 'CRM') {
  try {
    const resp = await instance({
      method: 'post',
      url: `${apiURL}/${url}`,
      headers: await setHeadersCsv(needAuth),
      data: body,
      validateStatus: function (status) {
        return status >= 200;
      },
    });
    // console.log(`%cAPIPOSTCSV - ${url} response:`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    // console.log(resp);
    if (resp) {
      return resp.data;
    }
  } catch (err) {
    // console.log(`%cAPIPOSTCSV - ${url} catch error: ${err}`, 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
  }
}
