import Vue from 'vue';

export default Vue.mixin({
  data() {
    return {
      //
    };
  },
  methods: {
    async findCustomerCorrespondingAddress(baseAddress, correspondingAddressTypeId, isDefaultSearch = true) {
      console.log('baseAddress: ', baseAddress);
      let cAddress;
      let foundAddressData;
      const cAddressIndex = await this.$store.dispatch('FindCustomerCorrespondingAddresses',
        {
          address: baseAddress,
          correspondingAddressTypeId: correspondingAddressTypeId,
        }
      );
      console.log('cAddressIndex:', cAddressIndex);
      if (cAddressIndex > -1) {
        cAddress = this.$store.state.authModule.user.addresses[cAddressIndex];
      } else {
        if (isDefaultSearch) {
          foundAddressData = await this.$store.dispatch('GetDefaultAddress',
            {
              addressTypeId: correspondingAddressTypeId
            }
          );
        } else {
          foundAddressData = 'MISSING_ADDRESS_DATA';
        }
        if (foundAddressData === 'MISSING_ADDRESS_DATA') {
          cAddress = new this.$NEW_ADDRESS_FROM_EXISTING(baseAddress);
        } else {
          cAddress = foundAddressData;
        }
      }
      console.log('cAddress: ', cAddress);
      return cAddress;
    },
    stepBackBillingAddressModal() {
      this.customerBillingAddress = {};
      this.billingAddressStepper = 1;
      this.billingAddressForm = false;
    },
    stepBackDeliveryInAddressModal() {
      this.customerShippingAddress = {};
      this.shippingAddressStepper = 1;
      this.deliveryInAddressForm = false;
    },
    stepBackDeliveryOutAddressModal() {
      this.customerShippingOutAddress = {};
      this.shippingAddressOutStepper = 1;
      this.deliveryOutAddressForm = false;
    },
  },
  watch: {
    customerShippingAddress(value) {
      // console.log('customerShippingAddress: ', value);
      if (value.id) {
        if (value.id === '5dC]Y??9f@TmbJFv') {
          // this.shippingAddressStepper = 2;
          this.serviceRequestForm.deliveryInAddress = {
            id: null,
            country: {},
            city: '',
            postalCode: '',
            publicPlaceName: '',
            publicPlaceType: '',
            streetNumber: '',
            other: null,
            label: '',
            billingName: '',
            taxNumber: null,
            accountNumber: null,
            isCompany: 0,
          };
          this.deliveryInAddressString = '';
          this.setServiceRequestFormCountry();
        } else {
          // this.serviceRequestForm.deliveryInAddress.id = value.id;
          this.serviceRequestForm.deliveryInAddress = value;
          this.deliveryInAddressString = value.label;
          this.billingAddressIsEqualdeliveryInAddress = true;
          this.deliveryInAddressForm = true;
        }
      }
    },
    customerBillingAddress(value) {
      // console.log('customerBillingAddress: ', value);
      if (value.id) {
        if (value.id === '5dC]Y??9f@TmbJFv') {
          this.billingAddressStepper = 2;
          this.serviceRequestForm.billingAddress = {
            id: null,
            isCompany: 0,
            country: {},
            city: '',
            postalCode: '',
            publicPlaceName: '',
            publicPlaceType: '',
            streetNumber: '',
            other: null,
            label: '',
            billingName: '',
            taxNumber: null,
            accountNumber: null,
          };
          this.setServiceRequestFormCountry();
        } else {
          this.serviceRequestForm.billingAddress = value;
          this.billingAddressString = value.label;
          this.billingAddressModal = false;
          this.billingAddressForm = true;
        }
      }
    },
    customerShippingOutAddress(value) {
      // console.log('customerShippingAddress: ', value);
      if (value.id) {
        if (value.id === '5dC]Y??9f@TmbJFv') {
          this.shippingAddressOutStepper = 2;
          this.serviceRequestForm.deliveryOutAddress = {
            id: null,
            country: {},
            city: '',
            postalCode: '',
            publicPlaceName: '',
            publicPlaceType: '',
            streetNumber: '',
            other: null,
            label: '',
            billingName: '',
            taxNumber: null,
            accountNumber: null,
            isCompany: 0,
          };
          this.deliveryOuAddressString = '';
          this.setServiceRequestFormCountry();
        } else {
          this.serviceRequestForm.deliveryOutAddress = value;
          this.deliveryOutAddressString = value.label;
          // this.billingAddressIsEqualdeliveryInAddress = true;
          this.deliveryOutAddressModal = false;
          this.deliveryOutAddressForm = true;
        }
      }
    },
  }
});
