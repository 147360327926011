/* eslint-disable no-shadow */
import Vue from 'vue';
import { APIGET } from '@/utils/apiHelper';

// initial state
const state = {
  billingAddressComponentKey: 199,
  componentKey: 123,
  appSystemInfo: null,
};

// getters
const getters = {
  getAppSystemInfoContent(state) {
    if (state.appSystemInfo) {
      return {
        title: state.appSystemInfo.data.title,
        description: state.appSystemInfo.data.description,
      };
    }
    return {
      title: 'Rendszerüzenet nem található',
      description: '',
    };
  },
  getAppSystemInfoCurrentVersion(state) {
    if (state.appSystemInfo) {
      return state.appSystemInfo.data.currentVersion;
    }
    return `v${Vue.prototype.$VERSION_NO}`;
  }
};

// actions
const actions = {
  async GetSystemInfo(context, credentials) {
    const response = await APIGET(`system-info/messages/${credentials.code}`, {}, false,'CRM');
    if (response.status === Vue.prototype.$HTTP_OK) {
      context.commit('setAppSystemInfo', response.data);
    }
  },
};

// mutations
const mutations = {
  forceRerender(state, payload) {
    state.componentKey += 3;
  },
  forceRerenderBillingAddressKey(state, payload) {
    state.billingAddressComponentKey += 5;
  },
  setAppSystemInfo(state, payload) {
    state.appSystemInfo = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
