/* eslint-disable no-shadow */
import { APIPOST, APIGET } from 'src/utils/apiHelper';
import { saveRQI } from 'src/utils/jwtHelper';

// initial state
const state = {
  //
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async createPPOrder(context, credentials) {
    saveRQI(credentials.crmRequestId);
    const response = await APIPOST('payments/create', credentials, true);
    return response;
  },
  async checkPaymentStatus(context, credentials) {
    const response = await APIGET(`payments?token=${credentials.token}&PayerID=${credentials.payerID}`, {}, true);
    // console.log('response: ', response);
    return response.data;
  }
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
