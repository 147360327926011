export default {
  mainNavBar: {
    home: 'Kezdőlap',
    aboutUs: 'Rólunk',
    introduction: 'Bemutatkozás',
    competitions: 'Pályázatok',
    stores: 'Üzletek',
    services: 'Szolgáltatások',
    career: 'Karrier',
    generalInformations: 'Általános információk',
    jobOffers: 'Állásajánlatok',
    worksheets: 'Munkalapjaim',
    partners: 'Partnerek',
    contact: 'Kapcsolat',
    mobilfutar: 'Mobilfutár',
    buyback: 'BuyBack',
    tracking: 'Nyomonkövetés',
    request: 'Igénylés',
    login: 'Bejelentkezés',
    logout: 'Kijelentkezés',
    registration: 'Regisztráció',
    forgotPassword: 'Elfelejtett jelszó',
  },
  innerSideNav: {
    home: 'Kezdőlap',
    userInformation: 'Alapadatok',
    allOrders: 'Összes megrendelés',
    allClaims: 'Leadott igények',
    billingInformation: 'Számlázási adatok',
    shippingInformation: 'Szállítási adatok',
    addressInformation: 'Címek kezelése',
    massUpload: 'Tömeges feltöltés',
    messages: 'Üzenetek',
    userManual: 'Útmutató',
    lgWorksheet: 'LG munkalapok',
    appleWorksheet: 'Apple munkalapok',
    mieleWorksheet: 'Miele munkalapok',
    exit: 'Kilépés',
    owlDemo: 'OWL Demo',
  },
  excel: {
    downloadSamples: 'Minta fájlok letöltése',
    uploadFiles: 'Kitöltött fájlok feltöltése',
    downloadSampleCSV: 'Minta CSV fájl letöltése',
    downloadSampleEXCEL: 'Minta EXCEL fájl letöltése',
    downloadExcelHelper: 'EXCEL segédlet letöltése',
    acceptFiles: 'Megengedett file formátumok: .csv, .xls, .xlsx',
    errorMessages: 'Hibák a feltöltött fájlban',
  },
  serviceCalculator: 'Szervizkalkulátor',
  mostServiceCalculator: 'MOST Szervizkalkulátor',
  orderForm: 'Megrendelő űrlap',
  imprint: 'Impresszum',
  login: 'Bejelentkezés',
  loginConfirm: 'Bejelentkezés megerősítése',
  twoStepLogin: 'Két lépcsős bejelentkezés',
  registration: 'Regisztráció',
  email: 'E-mail cím',
  password: 'Jelszó',
  passwordConfirm: 'Jelszó megerősítés',
  oldPassword: 'Jelenlegi jelszó',
  newPassword: 'Új jelszó',
  newPasswordConfirm: 'Új jelszó mégegyszer',
  forgotPassword: 'Elfelejtett jelszó',
  addNewPassword: 'Új jelszó megadása',
  checkPassword: 'Jelszó ellenőrzése',
  accountInformation: 'Fiókadatok',
  accountLanguage: 'Preferált nyelv',
  accountCurrency: 'Preferált valuta',
  accountPayment: 'Preferált fizetési mód',
  primaryContactInformation: 'Kapcsolattartó adatok',
  firstName: 'Keresztnév',
  lastName: 'Vezetéknév',
  phoneNumber: 'Telefonszám',
  actions: 'Műveletek',
  search: 'Keresés',
  damageNumber: 'Kárszám',
  invoice: 'Számla',
  warrantyTicket: 'Garancia jegy',
  evidence: 'Evidencia',
  brand: 'Gyártó',
  productType: 'Típus',
  deviceType: 'Készülék típusa',
  deviceIdentifier: 'Egyedi azonosító (IMEI, SN)',
  imeiSn: 'IMEI/SN',
  dateOfPurchase: 'Vásárlás időpontja',
  troubleDescription: 'Hibaleírás',
  otherDescription: 'Egyéb megjegyzés',
  infoToDeliver: 'Információ a futárnak',
  receptionAddress: 'Felvételi cím',
  newReceptionAddress: 'Új felvételi cím',
  transmissionAddress: 'Visszaszállítási cím',
  newTransmissionAddress: 'Új visszaszállítási cím',
  invoicingAddress: 'Számlázási cím',
  billingAddresses: 'Számlázási cím(ek)',
  newInvoicingAddress: 'Új számlázási cím',
  shippingAddress: 'Szállítási cím',
  deliveryAndReceiptAddress: 'Átadási/Átvételi cím',
  costumerAddress: 'Ügyfél cím',
  parcelLockers: 'Csomagautomata',
  deliveryMethod: 'Szállítási mód kiválasztása',
  requireAddress: 'Kötelező cím megadása',
  parcelLockersChosen: 'Kiválasztott csomagautomata',
  parcelLockersChose: 'Csomagautomata kiválasztása',
  parcelLockersSubmit: 'Csomagautomata megerősítése',
  parcelLockersCode: 'Csomagautomata kódja',
  foxpostPhoneNumberText: 'Foxpost esetén kizárólag mobil-telefonszámot fogadunk el az alábbi szolgáltatoktól a SMS küldés végett',
  phoneServices: '(20 | 30 | 31 | 70 | 50 | 51)',
  deliveryAddress: 'Átvételi cím',
  deliveryInAddress: 'Átadási cím',
  deliveryOutAddress: 'Átvételi cím',
  shippingAddresses: 'Szállítási cím(ek)',
  newShippingAddress: 'Új szállítási cím',
  shippingAndInvoicingAddress: 'Szállítási és számlázási cím',
  invoicingName: 'Számlázási név',
  shippingName: 'Szállítási név',
  billingName: 'Számlázási név',
  dateOfPickUp: 'Felvétel időpontja',
  dateOfTransmission: 'Visszaszállítás időpontja',
  country: 'Ország',
  city: 'Város',
  postalCode: 'Irányítószám',
  publicPlaceName: 'Közterület neve',
  publicPlaceType: 'Közterület típusa',
  streetNumber: 'Házszám',
  status: 'Állapot',
  actualStatus: 'Státusz',
  contactIsTheSame: 'A Kapcsolattartó én vagyok....',
  transmissionDataIsSameAsReceptionData: 'A Leadási cím megegyezik a Felvételi címmel.',
  invoicingDataIsSameAsReceptionData: 'A Számlázási cím megegyezik a Felvételi címmel.',
  invoicingDataIsSameAsTransmissionData: 'A Számlázási cím megegyezik a Leadási címmel.',
  invoicingAddressIsEqualShippingAddress: 'A Számlázási cím megegyezik az Átadási/Átvételi címmel.',
  deliveryOutAddressIsEqualDeliveryInAddress: 'Az Átvételi cím megegyezik az Átadási címmel.',
  invoicingDataIsOther: 'Egyikkel sem egyezik meg.',
  loadingText: 'Adatok betöltése folyamatban... Kérjük várjon!',
  pickUpAndReturn: 'Mobilfutár',
  welcomeText: 'Üdvözöljük a TMX Mobilfutár oldalán!',
  findOutMoreAboutOurServices: 'Ismerje meg szolgáltatásainkat!',
  warrantyRepairForm: 'Garanciális javítási igény leadás',
  oufOfWarrantyRepairForm: 'Fizetős javítási igény leadás',
  repairForm: 'Igény leadás',
  tracking: 'Követés',
  documents: 'Dokumentumok',
  documentUpload: 'Dokumentumok feltöltése',
  contactName: 'Kapcsolattartó neve',
  serviceRequestId: 'Igény azonosító',
  addressDetails: 'Cím adatok',
  vatNumber: 'Adószám',
  quoteDone: 'Elkészült árajánlat',
  exportReportDone: 'Elkészült jegyzőkönyv',
  mostmobil: 'Mostmobil.hu szervizgarancia',
  de3000: 'DE-3000 shop',
  requestIdOrWorksheetNumber: 'Igényazonosító / Munkalapszám',
  accountRegistration: 'Fiók regisztráció',
  accountRegistrationQuestionOnForm: 'Szeretnél felhasználói fiókot regisztrálni a megrendelt szolgáltatás részleteinek későbbi könnyebb elérése érdekében?',
  working: 'Dolgozunk',
  yuppee: 'Sikeres művelet',
  ooops: 'Hiba',
  deliveryInStore: 'Bolti átadás',
  isNewCustomer: 'Új ügyfél',
  returnWorksheetDocument: 'Visszafordítási jegyzőkönyv',
  acceptCheckbox: {
    generalInformation: 'A fent leírt tájékoztatást elolvastam és elfogadom.',
  },
  hasActiveQuoteBox: {
    title: 'Elfogadod az ajánlatot?',
    desc: 'Árajánlatunk 20 munkanapig érvényes. Kérjük, hogy mindenképpen válaszolj nekünk a megadott határidőn belül, különben megrendelésed töröljük!',
    dowloadSentenceText: 'Az elkészült árajánlatot',
    dowloadSentenceLinkText: 'itt tudod megtekinteni',
  },
  hasActiveExpertReportBox: {
    title: 'Elfogadod a jegyzőkönyvet?',
    desc: 'A jegyzőkönyv 5 munkanapig érvényes. Kérjük, hogy mindenképpen válaszolj nekünk a megadott határidőn belül, különben megrendelésed töröljük!',
    dowloadSentenceText: 'Az elkészült jegyzőkönyvet',
    dowloadSentenceLinkText: 'itt tudod megtekinteni',
  },
  forgotPasswordReminderText: 'A fenti mezőben adja meg az e-mail címet, amivel regisztrált az alkalmazásba...',
  passwordRuleGeneral: 'A jelszónak kötelezően tartalmaznia kell minimum 1db kis-, 1 db nagybetűt és minimum 1 db számot. A jelszó hossza minimum 8 karakter kell hogy legyen!',
  registrationInfo: 'Kérjük a lenti mezőben adj meg egy jelszót. Ezzel tudsz majd bejelentkezni a felhasználói fiókodba. A bejelentkezési e-mail cím az Ügyfél adatoknál megadott e-mail címed lesz.',
  greetings: 'Üdvözlet',
  unreadMessages: 'Olvasatlan üzenetek',
  readMessages: 'Olvasott üzenetek',
  personalSite: 'Személyes oldal',
  flottakezeles: 'Üzleti partnereinknek',
  dolgozoknak: 'Dolgozóknak',
  priceCalculation: 'Árkalkuláció',
  buyBack: 'Buyback',
  program: 'program',
  estimatedServicePrice: 'Becsült szerviz- és futárköltség',
  askForQuote: 'Kérjen ajánlatot!',
  arriveToService: 'Szervizbe érkezés',
  referenceNumber: 'Hivatkozási szám',
  quotePrice: 'Árajánlat összege',
  button: {
    send: 'Küldés',
    newOrder: 'Új megrendelés',
    newAddress: 'Új cím hozzáadása',
    changePassword: 'Jelszó módosítása',
    deleteProfile: 'Profil törlése',
    cancel: 'Mégsem',
    view: 'Megtekint',
    select: 'Kiválaszt',
    next: 'Tovább',
    back: 'Vissza',
    imeiValidate: 'IMEI Ellenőrzés',
    save: 'Mentés',
    edit: 'Szerkesztés',
    download: 'Letöltés',
    upload: 'Feltöltés',
    validate: 'Ellenőrzés',
    calculate: 'Kalkulálok',
    orderTheService: 'Megrendelem a szolgáltatást',
    interestedInOffer: 'Érdekel az ajánlat',
    askForQuote: 'Ajánlatot kérek',
    acceptQuote: 'Árajánlat elfogadása',
    acceptQuotes: 'Árajánlatok elfogadása',
    rejectQuote: 'Árajánlat elutasítása',
    rejectQuotes: 'Árajánlatok elutasítása',
    quoteAcceptText: 'Elfogadom az ajánlatot',
    quoteRejectText: 'Elutasítom az ajánlatot',
    /*expertReportAcceptText: 'Elfogadom a szakvéleményt',
    expertReportRejectText: 'Elutasítom a szakvéleményt',*/
    expertReportAcceptText: 'Kérek ajánlatot',
    expertReportRejectText: 'Nem kérek ajánlatot',
    goToLogin: 'Tovább a Bejelentkezéshez',
    goToDashboard: 'Vissza a Személyes oldalamra',
    backToMyAddresses: 'Vissza a rögzített címekhez',
    continueWithoutReg: 'Folytatás regisztráció nélkül',
    continueWithReg: 'Folytatás regisztrációval',
    downloadInvoice: 'Számla letöltése',
    onlinePayment: 'Online fizetés',
    generate: 'Generálás',
    refresh: 'Frissítés',
    print: 'Nyomtatás',
    createNew: 'Új létrehozása',
    export: 'Exportálás',
    addNew: 'Új hozzáadása',
    sendEmail: 'Küldés emailben',
    ok: 'Rendben',
    backToList: 'Vissza a listához',
    printAppleDocIn: 'Átvételi jegyzőkönyv',
    printAppleDocOut: 'Szervizmunkalap',
  },
  inputMessages: {
    requiredField: 'Kötelező kitölteni',
    maxCharacter: 'Maximum 3000 karakter megadható ebben a mezőben.',
    optionalField: 'Opcionális',
    passwordRule: 'Min. 8 karakter, tartalmaznia kell minimum egy számot, kisbetűt, nagybetűt',
    passwordRuleMin8: 'Min. 8 karakter hosszú kell hogy legyen!',
    passwordNotMatch: 'A megadott két jelszó nem egyezik',
    searchHint: 'Begépeléssel rá tud keresni a táblázatban megadott adatok bármelyikére',
    acceptInformation: 'A megrendelés leadásához el kell fogadni a tájékoztatót és a .....',
    maxFileSizeRule: 'Maximális feltölthető fájlméret 5 MB!',
    maxFileSizeRuleOptional: 'Opcionális! Maximális feltölthető fájlméret 5 MB!',
    maxFileSizeRuleRequired: 'Kötelező kitölteni! Maximális feltölthető fájlméret 5 MB!',
    emailMatch: 'Valós e-mail címet adjon meg!',
    wrongCharacter: 'Hibás karakter az email címben',
    onlyNumbers: 'Csak számokat adjon meg!',
    contactIsTheSameHelp: 'Amennyiben Ön a Kapcsolattartó ennél a címnél, pipálja be a jelölőnégyzetet.',
    requiredPrivacy: 'Az űrlap beküldéséhez el kell fogadnia az Adatvédelmi tájékoztatót!',
    requiredTermsAndCond: 'Az űrlap beküldéséhez el kell fogadnia az Általános Szerződési Feltételeket!',
    toFewCharacters: 'Túl kevés karaktert adott meg!',
    damageNumberNotMatch: 'A kárszám AS vagy VF karakterekkel kezdődik és utána 7 db számjegy következik.',
    startTypingPostalCode: 'Begépeléssel rá tud keresni az irányítószámra',
    startTypingCity: 'Begépeléssel rá tud keresni a városra',
    startTypingStreet: 'Begépeléssel rá tud keresni az utcára',
    noDataText: 'Nincs a keresésnek megfelelő adat',
    snRules: 'Angol abc kis/nagybetűk, számok, - jel és / jel engedélyezett!',
    minLenght15: 'Kötelezően 15 karakter hosszú kell, hogy legyen!',
    maxLenght15: 'Maximum 25 karakter hosszú lehet!',
    purchaseDateMax: 'Nem lehet későbbi a dátum, mint',
    purchaseDateReq: 'Kötelező kitölteni! Pl. 2020-03-07',
    purchaseDateOptional: 'Opcionális! Pl. 2020-03-07',
    insuranceNumberRule: 'Nem tartalmazhat szóközt és speciális karaktereket!',
    purchaseDateMin: 'Nem lehet korábbi a dátum, mint',
    optionalFieldOnlyNumbers: 'Opcionális! Csak számokat adjon meg!',
    requiredFieldNoSpecialChar: 'Kötelező kitölteni! Nem tartalmazhat speciális karaktereket!',
    noSpecialChar: 'Nem tartalmazhat speciális karaktereket!',
    emailNoSpace: 'Az e-mail cím nem tartalmazhat szőközt!',
    requiredFieldRequestId: 'Kötelező kitölteni! GSM munkalapszám vagy igényazonosító',
  },
  order: {
    orderId: 'Azonosító',
    orderDate: 'Rendelés dátuma',
    orderStatus: 'Rendelés állapota',
    orderPlaceNewOrder: 'Új megrendelés leadása',
    orderDetails: 'Megrendelés részletei',
  },
  request: {
    requestDetails: 'Igény részletei',
    requestData: 'Igény adatok',
  },
  serviceRequestHistory: {
    RECEIVED: 'Beérkezett megrendelés',
    DELIVERY_IN_ORDERED: 'Futár kiküldve',
    ARRIVED: 'Készülék a szervizben',
    QUOTE_DONE: 'Elkészült árajánlat',
    QUOTE_ACCEPTED: 'Elfogadott árajánlat',
    QUOTE_REJECTED: 'Elutasított árajánlat',
    PRODUCT_LEFT_THE_SERVICE: 'Kiszállításra vár',
    INVOICE_DONE: 'Elkészült számla',
    DELIVERY_OUT_ORDERED: 'Futár megrendelve',
    SHIPPED: 'Sikeres kézbesítés',
    SERVICE_DONE: 'Készülék elhagyta a szervizt',
  },
  shipping: {
    pickupAddress: 'Felvételi cím',
    shippingAddress: 'Leadási cím',
  },
  services: {
    iw: 'Garanciális javítás',
    vodaiw: 'Jótállásos (garanciális) javítás',
    oow: 'Fizetős javítás',
    sw: 'Szerviz garancia',
    asu: 'Biztosítós javítás',
    asuw: 'Kárrendezés utáni igénybejelentés',
    asuw2: 'Kárrendezés',
    swPickup: 'Fizetős javítások utáni szervizgarancia igénybejelentés',
    garancia: 'Biztosítási szervizgarancia futárszolgáltatás',
    lenovo: 'Lenovo futárszolgáltatás',
    asus: 'Asus futárszolgáltatás',
    xiaomi: 'Xiaomi futárszolgáltatás',
    gopro: 'GoPro futárszolgáltatás',
    flottakezeles: 'Üzleti partnereinknek',
    simpleRepair: 'Garanciális és fizetős szervizszolgáltatások, javítások megrendelése',
    simpleRepairs: 'Tájékoztatjuk, hogy egy igényen egy készüléket tudunk kezelni.',
    insuranceService: 'Biztosítási szervizgarancia',
    mostmobil: 'Mostmobil.hu szervizgarancia',
    telekomService: 'Telekom ügyfeleknek',
    telenorService: 'Yettel ügyfeleknek',
    vodafoneService: 'Vodafone ügyfeleknek',
    flipService: 'Flip ügyfeleknek',
  },
  stepper: {
    generalDetails: 'Általános információk',
    deviceDetails: 'Készülék adatok',
    customerDetails: 'Ügyfél adatok',
    confirmation: 'Véglegesítés',
    done: 'Kész',
  },
  ERROR_MESSAGES: {
    ERROR_IMEI: 'Az Ön által megadott egyedi azonosító nem felel meg a Luhn-algoritmusnak, kérjük ellenőrizze, hogy megfelelően adta-e meg az adatot. Amennyiben Ön gyári számot adott meg, kérjük tekintse tárgytalannak az üzenetet.',
    REQUEST_SEND_1: '<div class="display-1 mb-6"><b>Váratlan hiba történt!</b></div><div class="headline mb-4">A folyamat során váratlan hiba lépett fel!<br>Dolgozunk a hiba elhárításán!</div><div>Köszönjük türelmét!</div>',
    REQUEST_SEND_2: '<div class="display-1 mb-6"><b>Megszakadt a kapcsolat!</b></div><div class="headline mb-4">A folyamat során váratlan hiba lépett fel!<br>Dolgozunk a hiba elhárításán!</div><div>Köszönjük türelmét!</div>',
    ONE_FILE_IS_REQUIRED: 'Jótállási keretekben történő ügyintézéshez, kérjük csatoljon igényéhez egy a vásárlást igazoló számlamásolatot, és/vagy érvényes jótállási jegyet.',
    FILE_NOT_VALID: 'A feltölteni kívánt fájl hibás! A fájl csak .pdf kiterjesztésű lehet és a mérete nagyobb kell legyen, mint 0kB! Kérjük csatoljon egy másik fájlt!',
    INVALID_DATE: 'A Vásárlás dátuma nem megfelelő. Kérjük valód dátumot adjon meg!',
    INSURANCE_NUMBER_VALIDATION: '<div class="display-1 mb-6"><b>Érvénytelen kárszám!</b></div><div>Kérjük keresse bizosítóját a kárszámmal kapcsolatosan!</div>',
    USER_EXISTS: '<div class="display-1 mb-6"><b>Sikertelen beküldés!</b></div><div class="headline mb-4">A megadott e-mail címmel korában regisztrált egy felhasználói fiókot.</div><div>Kérjük jelentkezzen be a fiókjába és az ott található új megrendelés gombra kattintással adja le javítási igényét a gyorsabb, kényelmesebb és nem utolsósorban biztonságosabb ügyintézés érdekében.</div>',
    LGWORKSHEET_LOADINGDATAS: 'Az adatok betöltése nem sikerült! Kérjük próbálja meg újra!',
    LGWORKSHEET_SAVE: 'A munkalap mentése sikertelen! Ellenőrizze a megadott adatokat és próbálja újra!',
    LGWORKSHEET_UPDATE: 'A munkalap adatainak frissítése sikertelen! Ellenőrizze a megadott adatokat és próbálja újra!',
    LGWORKSHEET_SEND: 'A munkalapot nem sikerült elküldeni e-mailben! Kérjük próbálja meg újra!',
    APPLEWORKSHEET_LOADINGDATAS: 'Az adatok betöltése nem sikerült! Kérjük próbálja meg újra!',
    APPLEWORKSHEET_SAVE: 'A munkalap mentése sikertelen! Ellenőrizze a megadott adatokat és próbálja újra!',
    APPLEWORKSHEET_UPDATE: 'A munkalap adatainak frissítése sikertelen! Ellenőrizze a megadott adatokat és próbálja újra!',
    APPLEWORKSHEET_SEND: 'A munkalapot nem sikerült elküldeni e-mailben! Kérjük próbálja meg újra!',
    DOWNLOAD_FILE: 'Nem sikerült letölteni a dokumentummot! Frissítse az oldalt és próbálja meg újra!',
    FORM_FAIL_MESSAGE: '<div class="display-1 mb-6"><b>Kapcsolat megszakadt!</b></div>'
      + '<div class="headline mb-4">Elnézését kérjük a kellemetlenségért! Kérjük később próbálja meg újra, ha ismételten sikertelen az igényleadás, úgy keressen fel ügyfélszolgálatunkat! <a href="info@tmx.hu">info@tmx.hu</a></div>',
    DUPLICATION: '<div class="display-1 mb-6"><b>Sikertelen beküldés!</b></div><div class="body-1 mb-4">Információink '
      + 'szerint a megadott egyedi azonosítóra (IMEI/SN) már van egy aktív javítási igénye, kérjük ellenőrizze a '
      + 'megadott adatokat. Kérdés esetén keresse ügyfélszolgálatunkat: info@tmx.hu</div>',
    INSURANCE_NUMBER: '<div class="display-1 mb-6"><b>Érvénytelen kárszám!</b></div>'
      + '<div class="body-1 mb-4">A megadott kárszám formátuma helytelen, kérjük forduljon az ügyfélszolgálatunkhoz '
      + 'elérhetőségeink.</div><div class="body-1">Email: info@tmx.hu<br></div>',
  },
  SUCCESS_MESSAGES: {
    SUCCESS_IMEI: 'Sikeres egyedi azonosító validáció.',
    REQUEST_SEND: '<div class="display-1 mb-6"><b>Sikeres beküldés!</b></div><div class="headline mb-4">Kérése megérkezett hozzánk! Az űrlapon megadott e-mail címére hamarosan visszaigazoló e-mailt küldünk!</div><div>Köszönjük megkeresését!</div>',
    USER_EXISTS: '<div class="display-1 mb-6"><b>Sikertelen beküldés!</b></div><div class="headline mb-4">A megadott e-mail címmel korában regisztrált egy felhasználói fiókot.</div><div>Kérjük jelentkezzen be a fiókjába és az ott található új megrendelés gombra kattintással adja le javítási igényét a gyorsabb, kényelmesebb és nem utolsósorban biztonságosabb ügyintézés érdekében.</div>',
    INSURANCE_NUMBER_VALIDATION: '<div class="display-1 mb-6"><b>Sikeres ellenőrzés!</b></div>',
    LGWORKSHEET_SAVE: 'A munkalap mentése sikeres!',
    LGWORKSHEET_UPDATE: 'A munkalap adatainak frissítése sikeres!',
    LGWORKSHEET_SEND: 'A munkalapot sikeresen elküldtük e-mailben!',
    APPLEWORKSHEET_SAVE: 'A munkalap mentése sikeres!',
    APPLEWORKSHEET_UPDATE: 'A munkalap adatainak frissítése sikeres!',
    APPLEWORKSHEET_SEND: 'A munkalapot sikeresen elküldtük e-mailben!',
    APPLEWORKSHEET_SAVE_NO_SHOPIN: 'A munkalap mentése sikeres! Az Átvételi jegyzőkönyv letöltése sikertelen, kérlek lépj be az igénylistázó felületre és nyomtasd ki onnan.',
  },
  warrantyRepair: 'Garanciális javítás',
  warrantyRepairs: 'Garanciális javítások',
  payedRepair: 'Fizetős javítás',
  tmxGeneralInformation: '<p>Tisztelt Ügyfelünk!</p>'
    + '<p>Kérjük, figyelmesen olvassa el az adatkezelési szabályzatunkat (( http://www.tmx.hu/adatvedelmi-szabalyzat )). A lenti adatok kitöltésével az adatkezelési szabályzatunkban foglaltakat elfogadottnak tekintjük.</p>'
    + '<p>Ha készüléke javításával, illetve cseréjével kapcsolatban kifogása van, kérjük, figyelmesen olvassa el alábbi tájékoztatónkat a garanciális javítás feltételeivel kapcsolatban.</p>'
    + '<p>Amennyiben igényt tart a garanciális javításra és elfogadja a garanciális javítás feltételeit, kérjük, a lentebb található adatok megadását.Aznap 14:30-ig beérkezett igényeket, a következő munkanapon tudjuk leghamarább teljesíteni.</p>'
    + '<p>Várható javítási idő: a beérkezéstől számítva 7-10 munkanapot vehet igénybe (készülék típustól függően)</p>'
    + '<p><b>Jótállási feltételek és jótállási jogok érvényesíthetőségének feltételei:</b></p>'
    + '<ul class="mb-4"><li>A jótállás időtartama 6 (hat) hónap.</li>'
    + '<li>A jótállási határidő a szolgáltatás elvégzése után a készüléknek a fogyasztó vagy megbízottja részére való átadásával kezdődik.</li>'
    + '<li>Igénybejelentés kizárólag írásban, e-mailben tehető a jótállási jegy számának feltüntetésével.</li></ul>'
    + '<p><b>Egyéb feltételek:</b></p>'
    + '<ul class="mb-4"><li>Jogos igény esetén a javítandó készülék begyűjtéséről a szerviz gondoskodik.</li>'
    + '<li>A SIM és Memória kártyát, kérjük, ne küldje be a készülékkel, mert nem tudunk felelősséget vállalni érte.</li>'
    + '<li><b>A készülék vásárlásánál kapott Jótállási jegyet legyen kedves a készülékkel mellékelni.</b></li>'
    + '<li>A készülék javítása során, a készüléken tárolt adatok elveszhetnek, melyekért felelősséget nem vállalunk.</li>'
    + '<li>A jótállás a fogyasztó törvényből eredő jogait nem érinti, ideértve a termékszavatossági és jótállási jogokat is.</li>'
    + '<li>A jótállás egyéb feltételeit a hatályos „Korm. rendelet az egyes javító-karbantartószolgáltatásokra vonatkozó kötelező jótállásról” elnevezésű kormányrendelet tartalmazza.</li></ul>'
    + '<p><b>Termékszavatossági és jótállási jogok</b></p>'
    + '<p>A TMX Mobile Solution Szerviz Kft. által végzett javító-karbantartó szolgáltatásra vonatkozik, amely a jótállási jegyben feltüntetett „Elvégzett munka, felhasznált főbb alkatrészek” javítására korlátozódik.</p>'
    + '<p>A készülékhez kapcsolódó termékszavatossági és jótállási jogok érvényesíthetőségére nincs hatással, hogy a készüléken a TMX Mobile Solution Szerviz Kft. javító-karbantartó szolgáltatást végzett, vagyis továbbra is érvényesíthető szavatossági igény a forgalmazóval vagy a készülék gyártójával szemben, a gyártó/forgalmazó szavatossági és jótállási kötelezettsége változatlan formában fennáll.</p>'
    + '<p>Fentiekből következően a készüléknek nem a TMX Mobile Solution Szerviz Kft. által végzett javító-karbantartó szolgáltatásával összefüggő egyéb meghibásodása esetén a gyártó vagy a forgalmazó által megadott szavatossági és jótállási feltételeknek megfelelően lehet a készüléket megjavíttatni, a gyártó vagy a forgalmazó által biztosított jótállás feltételei szerint.</p>'
    + '<p><b>Szervizgaranciát kizáró okok:</b></p>'
    + '<ul class="mb-4"><li>új káresemény bekövetkezése (pl: törés, folyadékkal való érintkezés). Ezzel kapcsolatban kérjük, keresse a Biztosítót 06/80 30 0030 telefonszámon.</li></ul>'
    + '<p>A szervizgaranciás javítás díjmentes.</p>'
    + '<p>Abban az esetben, ha a szerviz Hibátlan állásfoglalást ad, azaz a bejelentett készüléken a hiba nem tapasztalható, vagy garancia megvonás történik (pl., külső mechanikai hatás érte a készüléket, (törés) vagy idegen kezűséget állapítanak meg) úgy a postaköltséget az Ügyfélnek kell megfizetnie, ami 4522.- Ft, melyet a készülék átvételekor kell rendezni.</p>'
    + '<p>Apple készülékek esetén a Find My Iphone (FMI) funkció kikapcsolása az ügyfél feladata. Ha ezt a funkciót nem tudja feloldani, az Apple ügyfélszolgálatán segítséget nyújtanak Önnek, melynek száma 0680 /983 824.</p>'
    + '<p>Felhívjuk figyelmét, hogy a FMI kikapcsolási ideje nem tartozik bele a javításban vállalt időre.</p>',
  inputLabels: {
    companyName: 'Cégnév',
    firstName: 'Keresztnév',
    middleName: 'Középső név',
    lastName: 'Vezetéknév',
    email: 'E-mail',
    phone: 'Telefonszám',
    name: 'Név',
    topic: 'Témakör',
    manufacturer: 'Gyártó',
    imei: 'Készülékazonosító (IMEI/SN)',
    subject: 'Tárgy',
    message: 'Üzenet',
    field: 'Terület',
    job: 'Megpályázott pozíció',
    cv: 'Önéletrajz',
    otherDoc: 'Egyéb dokumentum',
    productType: 'Készülék típus',
    warrantyType: 'Garancia típus',
    dateOfPurchase: 'Vásárlás dátuma',
    troubleDescription: 'Hibaleírás',
    simLock: 'Hálózati zár',
    country: 'Ország',
    postalCode: 'Irányítószám',
    city: 'Város',
    publicPlaceName: 'Közterület neve',
    publicPlaceType: 'Közterület jellege',
    publicPlaceNameAndType: 'Közterület neve és jellege',
    streetNumber: 'Házszám',
    other: 'Egyéb megjegyzés',
    comment: 'Megjegyzés',
    invoice: 'Számla',
    warrantyTicket: 'Jótállási jegy',
    damageNumber: 'Kárszám',
    serviceRequest: 'Igényelt szolgáltatás',
    serviceRequests: 'Igényelt szolgáltatások',
    calculatedPrice: 'Becsült szerviz- és futárköltség',
    serviceRequestId: 'Javítási jegy szám',
    rma: 'RMA szám',
    productGroup: 'Termék csoport',
    uniqueIdentifierIn1: 'Készülékazonosító 1',
    uniqueIdentifierIn2: 'Készülékazonosító 2',
    uniqueIdentifierIn3: 'Készülékazonosító 3',
    uniqueIdentifierIn1new: 'Készülékazonosító 1 (IMEI/SN)',
    uniqueIdentifierIn2new: 'Készülékazonosító 2 (IMEI/SN)',
    uniqueIdentifierIn3new: 'Készülékazonosító 3 (IMEI/SN)',
    accountRegistrationTrue: 'Igen, szeretnék felhasználói fiókot regisztrálni.',
    accountRegistrationFalse: 'Nem, nem szeretnék felhasználói fiókot regisztrálni.',
    password: 'Jelszó',
    selectShippingAddress: 'Szállítási cím választása',
    selectShippingOutAddress: 'Átvételi cím választása',
    selectBillingAddress: 'Számlázási cím választása',
    serviceRequestIdentifier: 'Javítás azonosítója',
    origRequestSource: 'Weboldal',
    selectFilesToUpload: 'Állományok kiválasztása',
    deviceColor: 'Készülék színe',
    businessFormName: 'Szervezeti típus',
    paymentMethods: 'Fizetési mód',
    accountNumber: 'Bankszámlaszám',
    taxNumber: 'Adószám',
    imei1: 'IMEI1',
    imei2: 'IMEI2',
    serialNr: 'Széria szám - SN',
    purchaseInvoice: 'Vásárlást igazoló számla',
    purchaseWarrantyTicket: 'Vásárláskor kapott jótállási jegy',
    privatePerson: 'Magánszemély',
    company: 'Cég, Egyéni vállalkozás',
    warrantyType2: 'Jótállás típusa',
    serialNumber: 'Sorozatszám',
    dateOfDelivery: 'Átvétel/leadás dátuma',
    repairCostLimit: 'Javítási költséghatár',
    attachedDocs: 'Csatolt dokumentumok',
    otherDocsPics: 'Egyéb dokumentumok, képek',
    purchasePrice: 'Vásárlási ár',
    justImei: 'IMEI',
    isActive: 'Aktív',
    externalId: 'Külső azonosító',
    productTypeName: 'Készülék típus neve',
    price: 'Ár',
    accessories: 'Átvett tartozékok',
    mieleWorksheetNumber: 'Miele munkalapszám',
    customerType: 'Ügyfél típus',
    crmRequestId: 'Igényazonosító',
    gsmWorksheetId: 'GSM munkalapszám',
  },
  deviceCondition: 'Készülék állapota',
  physicalDamages: 'Fizikai sérülések',
  cosmeticDamages: 'Kozmetikai sérülések',
  additionalDatas: 'További adatok',
  damages: 'Sérülések',
  takePictures: 'Fényképek készítése',
  insuranceRepairForm: 'Biztosítási javítási igény leadás',
  introduction: 'Bemutatkozás',
  ourServices: 'Szolgáltatásaink',
  whyChooseUs: 'Miért válassz minket?',
  ourPartners: 'Partnereink',
  generalRequirements: 'Általános elvárásaink',
  seeJobOffers: 'Tekintse meg aktuális állásajánlatainkat',
  complexServiceForOurPartners: 'Komplex szolgáltatás partnereink részére',
  centralEuropeSuperGrowingService: 'Közép-Európa dinamikusan fejlődő szervize',
  whereCanYouFindUs: 'Merre talál minket?',
  sendUsAMessage: 'Küldjön nekünk üzenetet!',
  ourEmail: 'E-mail címünk',
  ourPhoneNumber: 'Telefonszámunk',
  ourStores: 'Üzleteink',
  serviceProducts: 'Szervizelt termékek',
  impress: 'Impresszum',
  basicPrices: 'Általános díjszabás',
  generalCosts: 'Általános díjszabás',
  nameOfTheCompany: 'A Vállalkozás neve',
  headquarter: 'Székhely',
  headquarterOfTheCompany: 'A Vállalkozás székhelye',
  sitesOfTheCompany: 'A Vállalkozás fióktelepei',
  postalAddressOfTheCompany: 'A Vállalkozás postai címe',
  phoneNumberOfTheCompany: 'A Vállalkozás telefonszáma',
  telefaxNumberOfTheCompany: 'A Vállalkozás telefax száma',
  emailOfTheCompany: 'A Vállalkozás elektronikus levelezési címe',
  VATNumberOfTheCompany: 'A Vállalkozás közösségiadószáma',
  webhostDetails: 'A Weboldal tárhelyszolgáltatójának neve és adatai',
  complaintPolicy: 'Panaszkezelési szabályzat',
  privacyPolicy: 'Adatvédelmi tájékoztató',
  deliveryService: 'Futárszolgálat',
  deliveryServiceText: 'A futárszolgálat lehetőséget választva a javítási igény leadását követően a szerviz az 1. pontban megadott címre küldi a futárt. A készülék begyűjtéséről legkésőbb a begyűjtés napján SMS / email / telefonhívás formájában tájékoztatja a futár.',
  foxpost: 'Foxpost',
  foxpostText: 'A csomagot a kiválasztott Foxpost csomagautomatában tudja feladni részünkre, begyűjtési igényének feldolgozását követően az SMS-ben / emailben kapott nyitókód megadásával.',
  termsAndCond: 'Általános Szerződési Feltételek',
  usedEquipmentForSale: 'Használt készülékek adásvétele - ÁSZF',
  imeiHint: 'Kötelező kitölteni! Lekérdezhető a *#06# kód beírással',
  imeiHintOptional: 'Opcionális! Lekérdezhető a *#06# kód beírással',
  checkSpamFolder: 'Sikeres igény mentése esetén az űrlapon megadott e-mail címre visszajelzést küldünk. Ellenőrizze a SPAM mappát is a postafiókjában!',
  moreInfosAboutTheService: 'Tájékoztatás a szolgáltatásról',
  imeiError: 'Az Ön által megadott egyedi azonosító nem felel meg a Luhn-algoritmusnak, kérjük ellenőrizze, hogy megfelelően adta-e meg az adatot. Amennyiben Ön gyári számot adott meg, kérjük tekintse tárgytalannak az üzenetet.',
  attachDocs: 'Csatolja be a dokumentumokat, ha nem tudja beküldeni a készülékkel.',
  generalServiceInfoText: '<p>Tisztelt Ügyfelünk!</p>'
    + '<p>Kérjük válasszon az alábbi két lehetőség közül, majd adja meg a kért adatokat, hogy futárszolgálatunk be tudja gyűjteni a szervizelendő készülékét.</p>'
    + '<p>Amennyiben Apple készüléket szeretne beküldeni kérjük az FMI (Find My iPhone) funkciót kapcsolja ki mielőtt átadja a futárnak a terméket. Szervizünk addig nem tudja a javítást megkezdni, amíg ez a funkció aktív.</p>'
    + '<p>Tájékoztatjuk, hogy gyártói előírások értelmében, amennyiben mechanikai sérülés okozta a készülék hibáját (pl: vízbe esett, leesett, betört a kijelző, sérült a burkolat, stb), ezt jótállási keret között nem tudjuk javítani, kérjük ilyen esetben lenti két opcióból válassza a Fizetős javítást.</p>'
    + '<p>A jótállási keretein belül történő javítás esetén, kérjük küldje be készüléke mellett az érvényes jótállási jegyet és/vagy a számla másolatát.</p>'
    + '<p>Amennyiben bármi kérdése lenne, kérjük írjon nekünk az info@tmx.hu e-mail címre.</p>'
    + '<p></p>'
    + '<p></p>'
    + '<p>TMX Mobile Solution Szerviz Kft.</p>',
  loremSentence: 'Aenean eget risus urna',
  loadingMessages: {
    INPROGRESS_CONTACT_FORM: 'Beküldés folyamatban. Kérjük várjon.',
    INPROGRESS_RESETPASSWORD: 'A kérés küldése folyamatban. Kérjük várjon.',
    INPROGRESS_SETPASSWORD: 'Az új jelszó beállítása folyamatban. Kérjük várjon.',
    INPROGRESS_CSVUPLOADING: 'A fájl feltöltése folyamatban. Kérjük várjon.',
    INPROGRESS_DOCUPLOADING: 'A dokumentum feltöltése folyamatban. Kérjük várjon.',
    INPROGRESS_FORMLOADING: 'Az űrlap betöltése folyamatban.',
    INPROGRESS_SENDLGWORKSHEET: 'A munkalap küldése e-mailben folyamatban. Kérjük várjon.',
    INPROGRESS_REQUESTLOADING: 'Az igény részleteinek betöltése folyamatban.',
    INPROGRESS_FILEDOWNLOAD: 'A fájl letöltése folyamatban. Kérjük várjon.',
  },
  documentTypes: {
    warrantyCardHash: 'Beküldött jótállási jegy',
    invoiceHash: 'Beküldött számla',
    faultReportHash: 'Fault Report',
    handoverDocumentHash: 'Handover Document',
    gsmQuotationHash: 'GSM Quotation',
    dolphinInvoiceHash: 'Dolphin Invoice',
    gsmRepairWorksheetHash: 'GSM Repair Worksheet',
  },
  noDataText: {
    productGroups: 'A kiválasztott gyártóhoz nincs Termék csoport!',
    general: 'Nincs megjeleníthető adat.',
    noRequestFound: 'Nem található leadott igény',
    noSearchResult1: 'A keresett ',
    noSearchResult2: 'kifejezés nem található ',
  },
  szervizkalk: {
    buybackIntro: '<p>Veritatis quibusdam quisquam assumenda voluptatem voluptate et dolor. Voluptatibus laudantium eos odio ut impedit qui temporibus quia nulla. Nihil modi excepturi iste. Et quaerat id aut.</p>'
      + '<p>Veritatis quibusdam quisquam assumenda voluptatem voluptate et dolor.</p>',
    soldItToUs: 'Adja el nekünk akár ennyiért',
  },
  needToLogin: 'A szolgáltatás igénybevételéhez be kell jelentkeznie!',
  customerTypeCantSeeTheContent: 'Önnek nincs jogosultsága megtekinteni ezt a tartalmat.',
  passwordCheck: {
    min1lowercase: 'Min. egy kisbetű',
    min1uppercase: 'Min. egy nagybetű',
    min8char: 'Min. 8 karakter',
    min1number: 'Min. egy szám',
    min1special: 'Min. egy speciális karakter: !@#$%*()_+-={}?',
    passMatch: 'A két jelszó megegyezik',
  },
  paymentAndAddressDatas: 'Cím és fizetési adatok',
  financialDatas: 'Pénzügyi adatok',
  nob2bRefNo: 'Nincs hivatkozási szám',
  noDocumentToShow: 'Nincsenek dokumentumok',
  reUseRequestDialog: {
    title: 'Szerviz garancia igénylés',
    desc: 'Amennyiben a készülék javításával probléma merült fel és a korábbi javítás után nem telt el hat hónap, úgy a tovább gomb megnyomásával az űrlapon a hiányzó adatok kitöltésével igényelhető a Szerviz által biztosított garanciális ügyintézés.',
  },
  gsmWorksheetId: 'GSM munkalapszám',
  downloadFileErrorDialog: {
    title: 'Hiba',
    noFile: 'A kiválasztott dokumentum nem található. Kérjük próbálja meg később!',
  },
  loginOrReg: 'Bejelentkezés',
  $vuetify: {
    badge: 'Jelvény',
    close: 'Bezárás',
    dataIterator: {
      noResultsText: 'Nincs egyező találat',
      loadingText: 'Betöltés...',
    },
    dataTable: {
      itemsPerPageText: 'Elem oldalanként:',
      ariaLabel: {
        sortDescending: 'Csökkenő sorrendbe rendezve.',
        sortAscending: 'Növekvő sorrendbe rendezve.',
        sortNone: 'Rendezetlen.',
        activateNone: 'Rendezés törlése.',
        activateDescending: 'Aktiváld a csökkenő rendezésért.',
        activateAscending: 'Aktiváld a növekvő rendezésért.',
      },
      sortBy: 'Rendezés',
    },
    dataFooter: {
      itemsPerPageText: 'Elem oldalanként:',
      itemsPerPageAll: 'Mind',
      nextPage: 'Következő oldal',
      prevPage: 'Előző oldal',
      firstPage: 'Első oldal',
      lastPage: 'Utolsó oldal',
      pageText: '{0}-{1} / {2}',
    },
    datePicker: {
      itemsSelected: '{0} kiválasztva',
      nextMonthAriaLabel: 'Következő hónap',
      nextYearAriaLabel: 'Következő év',
      prevMonthAriaLabel: 'Előző hónap',
      prevYearAriaLabel: 'Előző év',
    },
    noDataText: 'Nincs elérhető adat',
    carousel: {
      prev: 'Előző',
      next: 'Következő',
      ariaLabel: {
        delimiter: 'Dia {0}/{1}',
      },
    },
    calendar: {
      moreEvents: '{0} további',
    },
    fileInput: {
      counter: '{0} fájl',
      counterSize: '{0} fájl ({1} összesen)',
    },
    timePicker: {
      am: 'de',
      pm: 'du',
    },
    pagination: {
      ariaLabel: {
        wrapper: 'Oldal navigáció',
        next: 'Következő oldal',
        previous: 'Előző oldal',
        page: 'Menj a(z) {0}. oldalra',
        currentPage: 'Aktuális oldal: {0}',
      },
    },
    rating: {
      ariaLabel: {
        icon: 'Rating {0} of {1}',
      },
    },
  },
  homeWelcomeText: '<p class="display-1 text-center">Üdvözöljük a megújult szolgáltatásunk hamarosan indul!</p>'
    + '<p class="text-center">További információk az info@tmx.hu email címünkön</p>',
  day: 'nap',
  hour: 'óra',
  min: 'perc',
  sec: 'mp',
  kisokosTitle: 'Dolgozói igényleadás útmutató',
  kisokosSubTitle: 'Útmutató',
  szervizgar: {
    vodafoneGeneralInformation: '<p>Tisztelt Ügyfelünk!</p>' +
      '<p>Az adott napon 14:30-ig beérkezett igényeket, a következő munkanapon tudjuk leghamarabb teljesíteni.</p>' +
      '<p>Apple készülékek esetén a Find My Iphone (FMI, vagy iPhone keresése) funkció kikapcsolása az ügyfél feladata. Ha ezt a funkciót nem tudja feloldani, keresse fel az Apple ügyfélszolgálatát, ahol segítséget nyújtanak Önnek.</p>' +
      '<p>Felhívjuk figyelmét, hogy a FMI funkció kikapcsolási ideje nem tartozik bele a javításra vállalt időbe.</p>',
    generalInformation: '<p>Tisztelt Ügyfelünk!</p>' +
      '<p>Biztosítós szervizgarancia szolgáltatásunk egy korábbi biztosítási eseményt követően vehető igénybe.</p>' +
      '<p>Az adott napon 14:30-ig beérkezett igényeket, a következő munkanapon tudjuk leghamarabb teljesíteni.</p>' +
      '<p>Szervizgaranciát kizáró ok egy új káresemény bekövetkezése (pl: törés, folyadék okozta kár, illetéktelen hardveres/szoftveres beavatkozás). Ebben az esetben kérjük, keresse fel biztosítóját.</p>' +
      '<p>Apple készülékek esetén a Find My Iphone (FMI, vagy iPhone keresése) funkció kikapcsolása az ügyfél feladata. Ha ezt a funkciót nem tudja feloldani, keresse fel az Apple ügyfélszolgálatát, ahol segítséget nyújtanak Önnek.</p>' +
      '<p>Felhívjuk figyelmét, hogy a FMI funkció kikapcsolási ideje nem tartozik bele a javításra vállalt időbe.</p>' +
      '<p>Tájékoztatjuk, hogy amennyiben olyan készüléket küld be a szerizünkbe, amely szervizgaranciában nem kezelhető (pl: törés, folyadék okozta kár, illetéktelen hardveres/szoftveres beavatkozás, az előző javítással nem összefüggő hiba).' +
      ' A készülék szállítási költségéről számlát állítunk ki a beküldő nevére, melyet a készülék visszaküldéséekor utánvéttel kell rendeznie. (Az aktuális szállítási költségről az info@tmx.hu email címen érdeklődhet)</p>',
  },
  regIsBetterText: '<p class="text-center font-18">Ön a Fiók regisztráció nélküli megrendelésleadást választotta.</p><p class="text-center font-18">Szeretnénk felhívni figyelmét a regisztrált Fiók előnyeire: Gyorsabb és kényelmesebb ügyintézés, részletes megrendelés lista és előzmények, új megrendelés esetén automatikus űrlap kitöltés.</p>',
  loginWelcomeText: 'A javítás nyomon követeséhez adja meg a kért adatokat.',
  loginWelcomeTextMore: 'Amennyiben esetleg előző fizetős javításával kapcsolatban összefüggő problémája van, '
    + 'a nyomonkövetési adatok megadását követően az igény adatok mellett található Szervizgarancia gomb '
    + 'megnyomásával teheti meg a bejelentését. A gomb megnyomásával a korábbi lejelentés adatai '
    + 'automatikusan kitöltődnek, Önnek csak a hibajelenséget kell megadnia és ellenőrizni a szállítási és cím adatokat.',
  toRegUsers: 'Regisztrált ügyfeleinknek:',
  toReguserText: 'Személyes fiókjukat <a href="/bejelentkezes" style="color: #FFFFFF;"><b>itt</b></a> tekinthetik meg.',
  xiaomiRepairText: '<p class="mx-12">Tájékoztatjuk, hogy a XIAOMI Akció kamera, EcoProduct, Hálózati eszközök, Háztartási kisgépek, Kamera, Légtisztító, Multimédiás eszköz, Okosóra, Tartozék termékcsoportokban bejelentett javítások esetén csak abban az esetben tudjuk készülékét garanciális keretek között kezelni, ha annak alapcsomag tartalmát (tartozékok) beküldi hozzánk a hibás készülékkel együtt.</p>' +
    '<p class="mx-12">Kérjük, hogy amennyiben a fennmaradó termékcsoportok valamelyikét juttattja el szervizünkbe csak abban az esetben küldje be az eszközök tartozékait, ha azok a bejelentett hibával összefüggenek</p>' +
    '<p class="title text-center">A Covid 19 kereskedelmi jótállás feltételeiről bővebben a <a href="https://www.mi.com/global/service/warranty" target="_blank">https://www.mi.com/global/service/warranty</a> weboldalon tájékozódhat.</p>',
  invoiceOnlinePayment: 'Számla kifizetése online',
  pending: 'Függőben',
  payed: 'Fizetve',
  invoiceNumber: 'Számla sorszáma',
  invoiceCreatedAt: 'Számla kelte',
  invoiceGrossPrice: 'Számla összege',
  paymentMethod: 'Fizetési mód',
  paymentLoading: 'A fizetés visszaigazolása folyamatban.',
  dontCloseTheBrowser: 'Kérjük ne zárja be a böngésző ablakot.',
  paymentSuccess: 'Sikeres fizetés!',
  paymentFailed: 'Sikertelen fizetés!',
  paymentCanceled: 'A fizetési folyamat megszakadt.',
  paymentSorry: 'Sajnáljuk, hogy nem tudta online teljesíteni a fizetést.',
  paymentSorryAuth: 'A lenti gomb kattintva vissza tud jutni a Leadott igényeihez.',
  paymentSorryNotAuth: 'A lenti gomb kattintva vissza tud jutni a Kezdőlapra.',
  generateRandomImei: 'Amennyiben nem tudja ezt az azonosítót kérjük kattintson a Generálás gombra.',
  vodafone: {
    homePageSubtitle: 'Javítások megrendelése',
    warrantyRepairForm: 'Jótállásos javítási igény leadás',
  },
  cookies: 'Süti beállítások',
  dearCustomes: 'Tisztelt Ügyfelünk!',
  docTmxTracking: 'documents.tmx.hu',
  lg: {
    wsid: 'Munkalapszám',
    customerName: 'Ügyfél neve',
    installationAddress: 'Telepítési cím',
    installationAndBillingAddress: 'Telepítési és Számlázási cím',
    serviceDetails: 'Szolgáltatás adatai',
    accessories: 'Értékesített tartozékok',
    deviceDetails: 'Eszköz adatai',
    dateOfOrder: 'Megrendelés dátuma',
    dateOfInstallation: 'Kiszállás dátuma',
    service: 'Elvégzett szolgáltatás',
    extraService: 'Kiegészítő szolgáltatás',
    installationCompany: 'Kiszállíást végző vállalkozás',
    extraAccessoryName: 'Tartozék megnevezése',
    extraAccessoryAmount: 'Tartozék mennyisége',
    extraAccessoriesNetValue: 'Számlázott nettó érték',
    extraAccessoriesInvoiceId: 'Számla sorszáma',
    productType: 'Termék típusa',
    productTypeIdentifier: 'Termék egyedi azonosítója',
    placeOfPurchase: 'Vásárlás helye',
    uploadedDocuments: 'Feltöltött dokumentumok',
  },
  documentViewer: {
    loginInProgress: '<div class="body-1">Bejelentkezés folyamatban</div>',
    succAuth: '<div class="title mb-6 font-weight-bold">Sikeres azonosítás!</div><div class="body-1">A bejelentkezéshez szükséges megerősítő e-mail-t elküldtük a megadott e-mail címére.</div>',
    failedAuth: '<div class="title mb-6 font-weight-bold">Sikertelen azonosítás!</div><div class="body-1">Kérjük ellenőrizze a megadott adatokat és próbálja meg újra!</div>',
    inprogressAuth: '<div class="body-1">Azonosítás folyamatban</div>',
    noCloseBrowser: 'Kérjük, ne zárja be a böngésző ablakot!',
    loadingData: 'Adatok betöltése folyamatban. Kérjük várjon!',
    loadingFailed: 'Nem sikerült betölteni az adatokat!<br>Kattinson a Frissítés gombra',
  },
  errorDetails: 'A hiba részletei',
  responseStatus: 'Státuszkód',
  responseMessage: 'Hibaleírás',
  crmRequestIdOrGsmWorksheetIdIsReq: 'Az egyik azonosító megadása kötelező!',
  swPickup: 'Szervizgarancia',
  docIsReqIW: 'Az egyik dokumentum feltöltése kötelező!',
  tooltip: {
    swPickup: 'Kérjük igényét ezen a felületen legyen kedves leadni, ha esetleg előző fizetős javításával'
      + ' kapcsolatban összefüggő problémája van.',
  },
  swPickupInfoText: '<p>Itt tudja leadni szervizgarancia igényét az alábbi adatok megadásával, ha esetleg a '
    + 'cégünknél korábban leadott fizetős javításával összefüggésben problémája van.</p>'
    + '<p>Abban az esetben, ha a korábbi igényét ezen a felületen adta le kérem használja a '
    + '<a href="/nyomonkovetes" target="_self" style="color: #00418A; font-weight: bold;">Nyomonkövetés</a> '
    + 'menüt és kövesse az ott leírtakat a könnyebb igény rögzítés érdekében.</p>' +
    '<p>Tájékoztatjuk, hogy amennyiben olyan készüléket küld be a szerizünkbe, amely szervizgaranciában nem kezelhető (pl: törés, folyadék okozta kár, illetéktelen hardveres/szoftveres beavatkozás, az előző javítással nem összefüggő hiba).' +
    ' A készülék szállítási költségéről számlát állítunk ki a beküldő nevére, melyet a készülék visszaküldéséekor utánvéttel kell rendeznie. (Az aktuális szállítási költségről az info@tmx.hu email címen érdeklődhet)</p>',
};
