<template>
  <div v-frag>
    <v-navigation-drawer v-model="navigationDrawer" app disable-resize-watcher width="300">
      <SideNavBar />
    </v-navigation-drawer>
    <v-app-bar app fixed color="white">
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <v-app-bar-nav-icon @click.stop="navigationDrawer = !navigationDrawer" />
        <v-spacer />
        <div>
          <v-img :src="require(`../assets/logo/TMX_logo.png`)" width="101" height="48" @click="onLogoClick" style="cursor: pointer;"/>
        </div>
        <v-spacer />
        <div class="body-2 primary--text text--darken-2 ml-4 mr-2" style="display: flex; align-items: center;" v-if="$store.state.authModule.isAuthenticated && $store.state.authModule.user">
          Üdvözlet {{ $store.state.authModule.user.profile.firstName }} {{ $store.state.authModule.user.profile.customerType === 'B2C' ? '!' : ` - ${$store.state.authModule.user.profile.companyName} ${$store.state.authModule.user.profile.businessForm.name}!` }}
        </div>
        <v-icon v-if="$store.state.authModule.isAuthenticated && $store.state.authModule.user"
                :color="$store.state.authModule.user.profile.customerType === 'B2C' ? 'light-green darken-1' : 'orange darken-1'"
                @click="$router.push({name: 'iranyitopult'})">
          mdi-account-circle
        </v-icon>
        <Notifications />
        <!-- ide jön a nyelvválasztó -->
        <template v-if="$showLanguageDropdown">
          <LanguageChangeDropdown></LanguageChangeDropdown>
        </template>
      </template>
      <template v-else>
        <v-container>
          <v-row justify="center">
            <MainNavBar />
          </v-row>
        </v-container>
      </template>
    </v-app-bar>
    <v-content>
      <router-view></router-view>
    </v-content>
    <outer-footer app></outer-footer>
    <v-fade-transition>
      <v-btn fab class="primary" dark fixed bottom right @click="scrollToTop" v-show="showToTopButton">
        <v-icon>
          mdi-chevron-up
        </v-icon>
      </v-btn>
    </v-fade-transition>
  </div>
</template>

<script>
import MainNavBar from '../components/Navigation/MainNavBar.vue';
import SideNavBar from '../components/Navigation/SideNavBar.vue';
import LanguageChangeDropdown from '../components/language/LanguageChangeDropdown.vue';
import OuterFooter from "../components/footer/OuterFooter.vue";
import { SCROLL_TO_TOP_BUTTON_LIMIT } from 'src/constants/appConstants';
import * as langHelper from '../utils/langHelper';
import * as sharedMixins from "../mixins/sharedMixins";
import { TMX_URL } from 'src/utils/config';
import Notifications from 'src/components/shared/Notifications.vue';

export default {
  name: 'App',
  components: {
    MainNavBar, SideNavBar, OuterFooter, LanguageChangeDropdown, Notifications,
  },
  beforeMount() {
    this.detectLanguage();
    // console.log('OUTER LAYOUT');
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  data: () => ({
    navigationDrawer: false,
    showToTopButton: false,
  }),
  methods: {
    scrollToTop() {
      this.$vuetify.goTo(0, { duration: 800, offset: 0 });
    },
    handleScroll() {
      // console.log(window.scrollY);
      // this.scrollPos = window.scrollY;
      this.showToTopButton = window.scrollY > SCROLL_TO_TOP_BUTTON_LIMIT;
    },
    onLogoClick() {
      window.open(`${TMX_URL}`, '_self');
    },
  },
};
</script>
