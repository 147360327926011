/* eslint-disable no-shadow */

// initial state
import { APIGET, APIPOST, APIFILEDOWNLOAD, APIFILEDOWNLOADGET, APIQUOTE, APIPOSTCSV, APIGETLOGH } from 'src/utils/apiHelper';
import i18n from "../i18n";
import { getToken, getUid } from 'src/utils/jwtHelper';

const state = {
  requestStatus: [
    {
      status: 'RECEIVED',
      name: 'Beérkezett megrendelés',
    },
    {
      status: 'DELIVERY_IN_ORDERED',
      name: 'Futár kiküldve',
    },
    {
      status: 'ARRIVED',
      name: 'Készülék a szervizben',
    },
    {
      status: 'QUOTE_DONE',
      name: 'Elkészült árajánlat',
    },
    {
      status: 'QUOTE_ACCEPTED',
      name: 'Elfogadott árajánlat',
    },
    {
      status: 'QUOTE_REJECTED',
      name: 'Elutasított árajánlat',
    },
    {
      status: 'PRODUCT_LEFT_THE_SERVICE',
      name: 'Kiszállításra vár',
    },
    {
      status: 'INVOICE_DONE',
      name: 'Elkészült számla',
    },
    {
      status: 'DELIVERY_OUT_ORDERED',
      name: 'Futár megrendelve',
    },
    {
      status: 'SHIPPED',
      name: 'Sikeres kézbesítés',
    },
  ],
  sampleRequests: [
    {
      serviceRequest: {
        id: '33',
        type: 22, // garancia
        product: 'Samsung Galaxy S10+',
        services: [
          'Kijelzőcsere', 'Akkumulátorcsere', 'Hátlapcsere', 'Beázott telefon', 'Egyéb hiba',
        ],
        troubleDescription: 'Itaque sunt praesentium. Reprehenderit qui praesentium. Doloremque repudiandae aut iste architecto consequatur sit. Expedita ut consequatur magni. Autem autem officia necessitatibus et autem deserunt similique.',
        calculatedPrice: 50340,
        deviceIdentifier: '123456789123456',
      },
      customer: {
        title: '',
        firstName: 'Aladár Sándor',
        lastName: 'Kis Nagy',
        email: 'kisnagysanyi@valami.com',
        phone: '20-345-4567',
      },
      shippingAddress: {
        city: 'Budapest',
        postalCode: '1112',
        publicPlaceName: 'Kamaraerdei',
        publicPlaceType: 'út',
        streetNumber: '16',
        other: '',
      },
      history: [
        {
          status: 'RECEIVED',
          name: 'Beérkezett megrendelés',
          date: '2020. 03. 12. 11:00',
          documents: [
            {
              name: 'Megrendelő lap',
              date: '2020. 03. 12. 11:00'
            },
            {
              name: 'Fault riport',
              date: '2020. 03. 12. 11:01'
            },
          ]
        },
        {
          status: 'ARRIVED',
          name: 'Készülék a szervizben',
          date: '2020. 03. 14. 13:35',
        },
        {
          status: 'SERVICE_DONE',
          name: 'Készülék elhagyta a szervizt',
          date: '2020. 03. 22. 8:12',
          documents: [
            {
              name: 'Kivét munkalap',
              date: '2020. 03. 22. 8:10'
            },
          ]
        },
        /*{
          status: 'SERVICE_DONE',
          name: 'Készülék elhagyta a szervizt',
          date: null,
          documents: []
        },*/
      ],
    },
    {
      serviceRequest: {
        id: '99',
        type: 44, // fizetős
        product: 'Huawei Mate 10 Pro',
        services: [
          'Kijelzőcsere', 'Akkumulátorcsere', 'Hátlapcsere',
        ],
        troubleDescription: 'Itaque sunt praesentium. Reprehenderit qui praesentium. Doloremque repudiandae aut iste architecto consequatur sit. Expedita ut consequatur magni. Autem autem officia necessitatibus et autem deserunt similique.',
        calculatedPrice: 23000,
        deviceIdentifier: '987623142313233',
      },
      customer: {
        title: '',
        firstName: 'Emília',
        lastName: 'Palotás',
        email: 'palotas.emilai@valami.hu',
        phone: '30-123-456',
      },
      shippingAddress: {
        city: 'Tiszaszentimre',
        postalCode: '5322',
        publicPlaceName: 'Sport',
        publicPlaceType: 'út',
        streetNumber: '2',
        other: '',
      },
      invoicingAddress: {
        name: 'Magyar Palotás Távközlési Zrt',
        city: 'Tiszaszentimre',
        postalCode: '5322',
        publicPlaceName: 'Ady Endre',
        publicPlaceType: 'út',
        streetNumber: '45',
        other: '',
      },
      history: [
        {
          status: 'RECEIVED',
          name: 'Beérkezett megrendelés',
          date: '2020. 03. 12. 11:00',
          documents: [
            {
              name: 'Megrendelő lap',
              date: '2020. 03. 12. 11:00'
            },
            {
              name: 'Fault riport',
              date: '2020. 03. 12. 11:01'
            },
          ]
        },
        {
          status: 'ARRIVED',
          name: 'Készülék a szervizben',
          date: '2020. 03. 14. 13:35',
        },
        {
          status: 'QUOTE_DONE',
          name: 'Elkészült árajánlat',
          date: '2020. 03. 16. 8:12',
          documents: [
            {
              name: 'Árajánlat',
              date: '2020. 03. 22. 8:10'
            },
          ]
        },
        {
          status: 'QUOTE_ACCEPTED',
          name: 'Elfogadott árajánlat',
          date: null,
        },
        {
          status: 'SERVICE_DONE',
          name: 'Készülék elhagyta a szervizt',
          date: null,
          documents: [
            {
              name: 'Kivét munkalap',
              date: '2020. 03. 22. 8:10'
            },
            {
              name: 'Számla',
              date: '2020. 03. 22. 8:10'
            },
          ]
        },
      ],
    },
  ],
  lang: i18n.locale.substring(0, 2),
  realRequests: [
    {
      "serviceRequest": {
        id: 1850,
        "availableServiceId": 22,
        "language": "hu",
        "type": "B2C",
        "status": "Új igény",
        "deviceIdentifierIn": "null",
        "deviceIdentifierOut": "null",
        "brand": {
          "id": 12,
          "name": "Samsung"
        },
        "productType": {
          "id": "null",
          "factoryName": "null",
          "tradeName": "null"
        },
        "errorDescription": "ledobtam a lépcsőn. betört a kijelző.",
        "simLock": {
          "id": 2,
          "name": "Magyar Telekom"
        },
        "warrantyType": {
          "id": 1,
          "name": "Garanciális javítás"
        },
        "mobilProvider": {
          "id": "null",
          "name": "null"
        },
        "receivedTime": "2020-06-08 14:31:46",
        "payment": {
          "id": "null",
          "name": "null"
        },
        "insuranceNumber": "",
        "rmaNumber": ""
      },
      "customer": {
        "id": 1868,
        "customerType": "B2C",
        "namePrefix": {
          "id": "null",
          "name": "null"
        },
        "firstName": "Bálint",
        "middleName": "null",
        "lastName": "Szilasi",
        "email": "szilasi.balint@tmx.hu",
        "phoneNumber": "88-111-111",
        "country": {
          "id": "HUN",
          "name": "Magyarország"
        },
        "city": "Budapest",
        "postalCode": 1225,
        "publicPlaceName": "Kastélymúzeum",
        "publicPlaceType": "utca",
        "streetNumber": 5,
        "other": "a kutya harap"
      },
      "shippingAddressData": {
        "id": 1907,
        "country": {
          "code": "HUN",
          "name": "Magyarország"
        },
        "city": "Budapest",
        "postalCode": 1225,
        "publicPlaceName": "Kastélymúzeum",
        "publicPlaceType": "utca",
        "streetNumber": 5,
        "other": "a kutya harap",
        "billingName": "null"
      },
      "billingAddressData ": {
        "id": 2300,
        "country": {
          "code": "HUN",
          "name": "Magyarország"
        },
        "city": "Salgótarján",
        "postalCode": "3400",
        "publicPlaceName": "Nagy",
        "publicPlaceType": "utca",
        "streetNumber": "99",
        "other": "null",
        "billingName": "Kis-Zalakaros Zrt."
      },
      "history": [
        {
          "status": "Új igény",
          "date": "2020-06-08 14:32:16",
          "type": null,
          "code": 1
        }
      ],
      "documents": {
        "warrantyCardPath": null,
        "invoicePath": null,
        "faultReportPath": null,
        "handoverDocumentPath": null,
        "gsmQuotationPath": null,
        "dolphinInvoicePath": null,
        "gsmRepairWorksheetPath": null
      },
    },
    {
      "serviceRequest": {
        id: 1851,
        "availableServiceId": 23,
        "language": "hu",
        "type": "B2C",
        "status": "Új igény",
        "deviceIdentifierIn": "null",
        "deviceIdentifierOut": "null",
        "brand": {
          "id": 13,
          "name": "Xiaomi"
        },
        "productType": {
          "id": "null",
          "factoryName": "null",
          "tradeName": "null"
        },
        "errorDescription": "ledobtam a lépcsőn. betört a kijelző.",
        "simLock": {
          "id": 2,
          "name": "Magyar Telekom"
        },
        "warrantyType": {
          "id": 2,
          "name": "Fizetős javítás"
        },
        "mobilProvider": {
          "id": "null",
          "name": "null"
        },
        "receivedTime": "2020-06-08 14:31:46",
        "payment": {
          "id": "null",
          "name": "null"
        },
        "insuranceNumber": "",
        "rmaNumber": "",
        "calculatedPrice": 34000,
      },
      "customer": {
        "id": 1868,
        "customerType": "B2C",
        "namePrefix": {
          "id": "null",
          "name": "null"
        },
        "firstName": "Bálint",
        "middleName": "null",
        "lastName": "Szilasi",
        "email": "szilasi.balint@tmx.hu",
        "phoneNumber": "88-111-111",
        "country": {
          "id": "HUN",
          "name": "Magyarország"
        },
        "city": "Budapest",
        "postalCode": 1225,
        "publicPlaceName": "Kastélymúzeum",
        "publicPlaceType": "utca",
        "streetNumber": 5,
        "other": "a kutya harap"
      },
      "shippingAddressData": {
        "id": 1907,
        "country": {
          "code": "HUN",
          "name": "Magyarország"
        },
        "city": "Budapest",
        "postalCode": 1225,
        "publicPlaceName": "Kastélymúzeum",
        "publicPlaceType": "utca",
        "streetNumber": 5,
        "other": "a kutya harap",
        "billingName": "null"
      },
      "billingAddressData": {
        "id": 2300,
        "country": {
          "code": "HUN",
          "name": "Magyarország"
        },
        "city": "Salgótarján",
        "postalCode": "3400",
        "publicPlaceName": "Nagy",
        "publicPlaceType": "utca",
        "streetNumber": "99",
        "other": "null",
        "billingName": "Kis-Zalakaros Zrt."
      },
      "history": [
        {
          "status": "Új igény",
          "date": "2020-06-08 14:32:16",
          "type": null,
          "code": 1
        },
      ],
      "documents": {
        "warrantyCardPath": "null",
        "invoicePath": "null",
        "faultReportPath": "null",
        "handoverDocumentPath": "null",
        "gsmQuotationPath": "null",
        "dolphinInvoicePath": "null",
        "gsmRepairWorksheetPath": "null"
      },
    },
  ],
  payedRequestStatusList: [
    {
      status: 'Új igény',
      date: null,
      type: null,
      code: 1,
    },
    {
      status: 'Beérkezett',
      date: null,
      type: null,
      code: 20,
    },
    {
      status: 'Árajánlat elkészült',
      date: null,
      type: null,
      code: 30,
    },
    {
      status: 'Elfogadott árajánlat',
      date: null,
      type: null,
      code: 32,
    },
    /* {
      status: 'Elutasított árajánlat',
      date: null,
      type: null,
      code: 34,
    },
    {
      status: 'Nincs válasz az árajánlatra',
      date: null,
      type: null,
      code: 36,
    }, */
    {
      status: 'Megjavítva',
      date: null,
      type: null,
      code: 60,
    },
  ],
  warrantyRequestStatusList: [
    {
      status: 'Új igény - elbíráslás alatt',
      date: null,
      type: null,
      code: 1,
    },
    {
      status: 'Beérkezett',
      date: null,
      type: null,
      code: 20,
    },
    {
      status: 'Megjavítva',
      date: null,
      type: null,
      code: 60,
    },
  ]
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async sendMobilfutarRequest(context, payload) {
    const formData = new FormData();
    const tempForm = payload.form;
    const formJSON = JSON.stringify(tempForm);
    formData.append('data', formJSON);
    formData.append('invoice', payload.attachment.invoice);
    formData.append('warranty_card', payload.attachment.warrantyCard);
    if (payload.appleDocs) {
      formData.append('apple_documents[]', null);
      for (let i = 0; i < payload.appleDocs.length; i++) {
        formData.append('apple_documents[]', payload.appleDocs[i]);
      }
    }
    // console.log('sendMobilfutarRequest formData: ', formData.get('data'));
    let response;
    if (await getToken()) {
      if (await getUid()) {
        response = await APIPOST('customer-requests/pickup-return', formData, true);
        return response;
      } else {
        response = await APIPOST('customer-requests/pickup-return', formData, false);
        return response;
      }
    } else {
      response = await APIPOST('customer-requests/pickup-return', formData, false);
      return response;
    }
  },
  async sendMobilfutarRequestFlotta(context, payload) {
    const formData = new FormData();
    let tempForm = {};
    if (payload.form.serviceRequest.availableServiceId === 32) {
      tempForm.processType = payload.form.processType;
      tempForm.prevCrmRequestId = payload.form.prevCrmRequestId;
      tempForm.prevGsmWorkSheetId = payload.form.prevGsmWorkSheetId;
      tempForm.serviceRequest = payload.form.serviceRequest;
      tempForm.customer = payload.form.customer;
      tempForm.deliveryInAddress = payload.form.deliveryInAddress;
      tempForm.deliveryOutAddress = payload.form.deliveryOutAddress;
      tempForm.gdpr = payload.form.gdpr;
      tempForm.termsAndCond = payload.form.termsAndCond;
      tempForm.generalInfos = payload.form.generalInfos;
      tempForm.accountRegistration = payload.form.accountRegistration;
    } else {
      tempForm = payload.form;
    }
    const formJSON = JSON.stringify(tempForm);
    formData.append('data', formJSON);
    formData.append('invoice', payload.attachment.invoice);
    formData.append('warranty_card', payload.attachment.warrantyCard);
    // console.log('sendMobilfutarRequest formData: ', formData.get('data'));
    const response = await APIPOST('customer-requests/pickup-return', formData);
    return response;
  },
  async sendMobilfutarRequestDolgozoi(context, payload) {
    const formData = new FormData();
    let tempForm = {};
    if (payload.form.serviceRequest.availableServiceId === 32) {
      tempForm.processType = payload.form.processType;
      tempForm.prevCrmRequestId = payload.form.prevCrmRequestId;
      tempForm.prevGsmWorkSheetId = payload.form.prevGsmWorkSheetId;
      tempForm.serviceRequest = payload.form.serviceRequest;
      tempForm.customer = payload.form.customer;
      tempForm.deliveryInAddress = payload.form.deliveryInAddress;
      tempForm.deliveryOutAddress = payload.form.deliveryOutAddress;
      tempForm.gdpr = payload.form.gdpr;
      tempForm.termsAndCond = payload.form.termsAndCond;
      tempForm.generalInfos = payload.form.generalInfos;
      tempForm.accountRegistration = payload.form.accountRegistration;
    } else {
      tempForm = payload.form;
    }
    const formJSON = JSON.stringify(tempForm);
    formData.append('data', formJSON);
    formData.append('invoice', payload.attachment.invoice);
    formData.append('warranty_card', payload.attachment.warrantyCard);
    // console.log('sendMobilfutarRequest formData: ', formData.get('data'));
    const response = await APIPOST('customer-requests/pickup-return', formData);
    return response;
  },
  /***
   *
   * showCustomerRequest
   *
   * @param context
   * @param requestId
   * @returns {Promise<{data: [], message: string, status: number}>}
   */
  async getRequestById(context, requestId) {
    let response;
    if (await getToken()) {
      response = await APIGET(`customer-requests/${requestId}/show`, {}, true);
    } else {
      response = await APIGET(`customer-requests/${requestId}/show`, {}, true);
    }
    return response.data;
  },
  /***
   *
   * showCustomerRequestPrealert
   *
   * @param context
   * @param requestId
   * @returns {Promise<{data: [], message: string, status: number}>}
   */
  async getPreAlertRequestById(context, requestId) {
    const response = await APIGET(`customer-requests/${requestId}/show-prealert`, {}, true);
    return response.data;
  },
  /***
   *
   * showAllCustomerRequestPrealert
   *
   * @param context
   * @returns {Promise<{data: [], message: string, status: number}>}
   */
  async getAllPreAlertRequests(context) {
    const response = await APIGET(`customer-requests/show-prealerts`, {}, true);
    return response.data;
  },
  /***
   *
   * showCustomerRequestsHistory
   *
   * @param context
   * @param requestId
   * @returns {Promise<{data: [], message: string, status: number}>}
   */
  async getRequestHistoryById(context, requestId) {
    let response;
    if (await getToken()) {
      response = await APIGET(`customer-requests/${requestId}/history`, {}, true);
    } else {
      response = await APIGET(`customer-requests/${requestId}/history`, {}, false);
    }
    return response.data;
  },
  /***
   *
   * showCustomerRequestDocuments
   *
   * @param context
   * @param requestId
   * @returns {Promise<{data: [], message: string, status: number}>}
   */
  async getRequestDocumentsById(context, requestId) {
    let response;
    if (await getToken()) {
      response = await APIGET(`customer-requests/${requestId}/documents`,{}, true);
    } else {
      response = await APIGET(`customer-requests/${requestId}/documents`,{}, false);
    }
    return response.data;
  },
  async setRequestHistory(context, payload = { warrantyType, history }) {
    // console.log('START setRequestHistory');
    let requestHistory = payload.history;
    let hasRejectedQuote = requestHistory.findIndex(item => {
      return item.code === 34;
    });
    let hasNoAnswerQuote = requestHistory.findIndex(item => {
      return item.code === 36;
    });
    let templateHistory = payload.warrantyType === 1 ? context.state.warrantyRequestStatusList : context.state.payedRequestStatusList;
    for (let i = 0; i < templateHistory.length; i++) {
      let isContain = requestHistory.findIndex( item => {
        return item.code === templateHistory[i].code;
      });
      if (templateHistory[i].code === 32) {
        if (hasRejectedQuote > 0) {
          //
        } else if (hasNoAnswerQuote > 0) {
          //
        } else if (isContain === -1) {
          requestHistory.push(templateHistory[i]);
        }
      } else {
        if (isContain === -1) {
          requestHistory.push(templateHistory[i]);
        }
      }
    }
    // console.log('END setRequestHistory');
  },
  async downloadDocument(context, payload) {
    const formData = new FormData();
    const tempForm = payload.form;
    const formJSON = JSON.stringify(tempForm);
    formData.append('data', formJSON);
    // console.log('formData: ', formData);
    // console.log('formJSON: ', formJSON);
    const response = await APIFILEDOWNLOAD('documents', formData);
    if (response.status === 200) {
      // console.log(response);
      const responseHeader = new Headers(response.headers);
      const contentType = responseHeader.get('Content-Type');
      const i = contentType.lastIndexOf('/');
      const fileType = contentType.substr(i + 1);
      // console.log(fileType);
      const now = new Date().toISOString().substr(0, 10);
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([response.data], { type: `${contentType}` });
      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      // MENTÉSES verzió
      if (fileType !== 'pdf') {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `file@${now}.${fileType}`);
        document.body.appendChild(link);
        link.click();
      } else {
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = data;
        link.target = '_blank';
        link.click();
        // setTimeout(() => window.URL.revokeObjectURL(data), 2000);
      }
    }
    return response;
    /* if (response.status == 200 ) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf');
      document.body.appendChild(link);
      link.click();
    }
    return response; */
  },
  async downloadViewerDocument(context, credentials) {
    const response = await APIFILEDOWNLOADGET(`documents-viewer/${credentials.documentType}/${credentials.token}`);
    if (response.status === 200) {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([response.data], {type: 'application/pdf'});
      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      // Mentéses verzió
      /* const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf');
      document.body.appendChild(link);
      link.click(); */
      // megnyitásos verzió
      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = data;
      // console.log('link: ', link);
      link.target = '_blank';
      // link.download = 'file.pdf';
      link.click();
      // setTimeout(() => window.URL.revokeObjectURL(data), 2000);
    }
  },
  /***
   *
   * uploadCustomerRequestDocuments
   *
   * @param context
   * @param payload
   * @returns {Promise<number>}
   */
  async uploadDocument(context, payload) {
    const formData = new FormData();
    const tempForm = payload.form;
    const formJSON = JSON.stringify(tempForm);
    // formData.append('data', formJSON);
    formData.append('invoice', payload.attachment.invoice);
    formData.append('warranty_card', payload.attachment.warrantyCard);
    // console.log('sendMobilfutarRequest formData: ', formData.get('data'));
    const response = await APIPOST(`customer-requests/${payload.form.crmRequestId}/document-upload`, formData, true);
    return response.status;
  },
  async UploadCsv(context, payload) {
    const formData = new FormData();
    formData.append('csv_crm_request', payload.csvFile);
    const response = await APIPOSTCSV(`customer-requests/csv-upload`, formData, true);
    return response;
  },
  /***
   *
   * quoteUserAccept
   *
   * @param context
   * @param credentials
   * @returns {Promise<number>}
   * @constructor
   */
  async AcceptQuote(context, credentials) {
    const response = await APIQUOTE(`customer-requests/${credentials.requestId}/quote-user-accept`, { "quoteAcceptedStatus": credentials.accept}, credentials.token);
    return response.status;
  },
  /***
   *
   * expertReportUserAccept
   *
   * @param context
   * @param credentials
   * @returns {Promise<number>}
   * @constructor
   */
  async AcceptExpertReport(context, credentials) {
    const response = await APIQUOTE(`customer-requests/${credentials.requestId}/expert-report-user-accept`,
      {
        "expertReportAcceptedStatus": credentials.accept,
        "returnReport": credentials.returnReport
      },
      credentials.token
    );
    return response.status;
  },
  async getAllProductGroups(context, credentials) {
    const response = await APIGET('product-groups');
    if (response.status === 200) {
      return response.data.data;
    } else {
      return [];
    }
  },
  /***
   *
   * showProductGroupOfBrand
   *
   * @param context
   * @param credentials: int - brand.id
   * @returns {Promise<{data: [], message: string, status: number}>}
   */
  async showProductGroupOfBrand(context, credentials) {
    const response = await APIGET(`brands/${credentials}/product-groups`);
    return response.data;
  },
  async showProductGroupOfBrandByFormType(context, credentials) {
    const response = await APIGET(`brands/${credentials.brandId}/form-types/${credentials.formTypeId}/product-groups`);
    return response.data;
  },
  /***
   *
   * showProductTypeOfBrand
   *
   * @param context
   * @param credentials: { brandId: int, productGroupId: int }
   * @returns {Promise<{data: [], message: string, status: number}|{data: [], message: string, status: number}|undefined>}
   */
  async showProductTypeOfBrand(context, credentials) {
    // return await APIPOST(`brands/product-types`, { brandId: credentials.brandId, productGroupId: credentials.productGroupId});
    const response = await APIGET(`brands/${credentials.brandId}/product-types/${credentials.productGroupId}`);
    return response.data;
  },
  /***
   *
   * showForm
   *
   * @param context
   * @param credentials: String, form azonosítója
   * @returns {Promise<{data: [], message: string, status: number}|{data: [], message: string, status: number}>}
   */
  async showForm(context, credentials) {
    const response = await APIGET(`forms/${credentials}`);
    return response.data;
  },
  /***
   *
   * showFoxPost
   *
   * @param context
   * @param credentials: String, form azonosítója
   * @returns {Promise<{data: [], message: string, status: number}|{data: [], message: string, status: number}>}
   */
  async showFoxPost(context, credentials) {
    const response = await APIGET(`system-param/${credentials}`);
    return response.data;
  },
  /***
   *
   * showPaymentMethodOfCustomerType
   *
   * @param context
   * @param credentials: customerType
   * @returns {Promise<{data: [], message: string, status: number}|{data: [], message: string, status: number}>}
   */
  async showPaymentMethodOfCustomerType(context, credentials) {
    const response = await APIGET(`customer-types/${credentials}/payments`);
    return response.data;
  },
  /**
   *
   * AZONOSÍTÓ GENERÁLÁS
   *
   * @param context
   * @param credentials
   * @returns {Promise<string>}
   */
  async randomImeiGenerator(context, credentials) {
    const now = new Date();
    const year = now.getFullYear();
    const sYear = year.toString(10).substr(2, 2);
    const month = now.getMonth() + 1;
    const day = now.getDate();
    let sMonth = month;
    let sDay = day;
    if (month < 10) {
      sMonth = `0${month}`;
    }
    if (day < 10) {
      sDay = `0${day}`;
    }
    const hours = now.getHours();
    const min = now.getMinutes();
    const sec = now.getSeconds();
    let sHours = hours;
    let sMin = min;
    let sSec = sec;
    if (hours < 10) {
      sHours = `0${hours}`;
    }
    if (min < 10) {
      sMin = `0${min}`;
    }
    if (sec < 10) {
      sSec = `0${sec}`;
    }
    const sum = parseInt(sYear, 10) + month + day + hours + min + sec;
    let sSum = sum.toString(10);
    if (sum < 100) {
      sSum = `0${sum}`;
    } else if (sum > 1000) {
      sSum = sum.toString(10).substr(0, 3);
    }
    const sImei = `${sYear}${sMonth}${sDay}${sHours}${sMin}${sSec}${sSum}`;
    // console.log(`%cIMEI: ${sImei} - hossz: ${sImei.length}`, 'background-color: #a29bfe; color: #ffffff; font-size: 12px;');
    // console.log(`%cösszeg: ${sSum} - hossz: ${sSum.toString().length}`, 'background-color: #ff7675; color: #ffffff; font-size: 12px;');
    return sImei;
  },
  /**
   *
   * Javítási dokumentumok QR kódos igény adatok lekérdezése
   *
   * @param context
   * @param credentials
   * @returns {Promise<*>}
   */
  async getRequestByQr(context, credentials) {
    const response = await APIGET(`gsm-worksheets/${credentials.id}/documents/${credentials.token}`);
    return response.data;
  },
  /**
   *
   * CheckDuplication
   *
   * @param context
   * @param credentials
   * @returns {Promise<*>}
   * @constructor
   */
  async CheckDuplication(context, credentials = { email: '', identifiers: [] }) {
    let bodyToSend = {
      identifier: credentials.identifiers.find((item) => item && item.length > 0),
      email: credentials.email,
    };
    const response = await APIPOST('customer-requests/check-duplication', bodyToSend, false);
    return response.status;
  },
  async GetReportListById(context, credentials) {
    const response = await APIGET(`report-lists/${credentials.reportHeaderId}`, credentials.queryParams, false, 'LION');
    return response.data;
  },
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
