/* eslint-disable no-shadow */

const namespaced = true;

// initial state
const state = {
  //
};

// getters
const getters = {
  //
};

// actions
/**
 *
 * @access to local state: context.state.propertyName
 * @access to local getters: context.getters.getterName
 * @access to local mutations: context.commit('mutationName')
 * @access to another module state: context.rootState.moduleName.propertyName
 * @access to another module getter: context.rootGetters['moduleName/getterName']
 * @access to another module action: context.dispatch('moduleName/actionName', credentials, { root: true })
 * @access to another module mutation: context.commit('moduleName/mutationName', credentials, { root: true })
 *
 */
const actions = {
  async fileDecoder(context, credentials) {
    const responseHeader = new Headers(credentials.response.headers);
    const contentType = responseHeader.get('Content-Type');
    const contentDisposition = responseHeader.get('Content-Disposition');
    const i = contentDisposition.lastIndexOf('.');
    let fileType = contentDisposition.substring(i + 1);
    const now = new Date().toISOString();
    const epoch = Date.parse(now);
    const newBlob = new Blob([credentials.response.data], { type: `${contentType}` });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }
    const url = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    if (credentials.openInBrowser) {
      link.target = '_blank';
      link.click();
    } else {
      link.setAttribute('download', `file_${epoch}.${fileType}`);
      link.click();
      /*if (fileType === 'pdf') {
        link.target = '_blank';
        link.click();
        // setTimeout(() => window.URL.revokeObjectURL(url), 1500);
      } else {
        link.setAttribute('download', `file_${epoch}.${fileType}`);
        link.click();
      }*/
    }
  },
};

// mutations
const mutations = {
  //
};

const modules = {
  //
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules,
};
