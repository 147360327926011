import Vue from 'vue';
import VueTheMask from 'vue-the-mask';
import App from './App.vue';
import router from './router';
import store from './store/index';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import frag from 'vue-frag';
import websiteStyle from './styles/website.css';
import systemParameters from './utils/systemParameters';
import appConstants from './constants/appConstants.js';
import serviceRequestMixin from './mixins/serviceRequestMixin.js';
import addressMixin from 'src/mixins/addressMixin.js';

// Layout loader
import OuterLayout from './layouts/OuterLayout.vue';
import InternalLayout from './layouts/InternalLayout.vue';

// import components

Vue.config.productionTip = false;

Vue.directive('frag', frag);

Vue.component('outer-layout', OuterLayout);
Vue.component('internal-layout', InternalLayout);

Vue.use(VueTheMask);

const defaultInternal = '/iranyitopult';
const defaultOuter = '/igenyles';
const loginPage = '/bejelentkezes';

router.beforeEach(async (to, from, next) => {
  if (to.name === 'kulso-igeny-reszletei' || to.name === 'documentsTmxIgenyReszletei' || to.name === 'szervizgarancia-futarszolgaltatas') {
    next();
  } else {
    let done = await store.dispatch('CheckAuthentication');
    if (store.state.authModule.isAuthenticated) {
      if (to.meta.layout === 'outer') {
        next();
      } else {
        next();
      }
    } else if (to.meta.layout === 'outer') {
      next();
    } else {
      next(loginPage);
    }
  }
});

new Vue({
  async beforeCreate() {
    //
  },
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');
