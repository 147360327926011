const authToken = 'token';
const authUid = 'uid';
const rqi = 'RQI';
const urA = 'URA';
const loginMethod = 'Y1Tr#GdxtA!3c:c';

export const getToken = () => window.localStorage.getItem(authToken);

export const saveToken = token => {
  if (!token) {
    return;
  }
  window.localStorage.setItem(authToken, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(authToken);
};

export const getUid = () => window.localStorage.getItem(authUid);

export const saveUid = uid => {
  if(!uid) {
    // console.log('missing uid');
    return;
  }
  window.localStorage.setItem(authUid, uid);
}

export const destroyUid = () => {
  window.localStorage.removeItem(authUid);
}

export const getRQI = () => window.sessionStorage.getItem(rqi);

export const saveRQI = rqit => {
  window.sessionStorage.setItem(rqi, rqit);
}

export const destroyRQI = () => {
  window.sessionStorage.removeItem(rqi);
}

export const getURA = () => window.sessionStorage.getItem(urA);

export const saveURA = ura => {
  window.sessionStorage.setItem(urA, ura);
}

export const destroyURA = () => {
  window.sessionStorage.removeItem(urA);
}

export const getLoginMethod = () => window.localStorage.getItem(loginMethod);

export const saveLoginMethod = method => {
  if (!method) {
    return;
  }
  window.localStorage.setItem(loginMethod, method);
};

export const destroyLoginMethod = () => {
  window.localStorage.removeItem(loginMethod);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  getUid,
  saveUid,
  destroyUid,
  getRQI,
  saveRQI,
  destroyRQI,
  destroyURA,
  getURA,
  saveURA,
  saveLoginMethod,
  getLoginMethod,
  destroyLoginMethod,
};
